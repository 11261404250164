import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const forgetPassword = (email) => (dispatch) => {
    let URL = BASEURL.split('v1');
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    return axios({
        method: "GET",
        url: `${URL[0]}forgotpassword?email=${email}&source=kblive`,
        headers: {
            Accept: "application/json",
        },
    })
        .then((response) => {
            return dispatch({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: FORGOT_PASSWORD_ERROR,
                payload: error.response,
            });
        });
};


export const putResetPassword = (token, password) => (dispatch) => {
	dispatch({ type: RESET_PASSWORD_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}users/revise/password/new`,
		headers: { Authorization: `Bearer ${token}` },
		data: { password: password },
	})
		.then((response) => {
			return dispatch({
				type: RESET_PASSWORD_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: RESET_PASSWORD_ERROR,
				payload: error.response,
			});
		});
};