import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_ERROR,
  POST_LOGIN_OPEN_SUCCESS,
  POST_BRAND_REGISTER_REQUEST,
  POST_BRAND_REGISTER_SUCCESS,
  POST_BRAND_REGISTER_ERROR,
  POST_CUSTOMER_REGISTER_REQUEST,
  POST_CUSTOMER_REGISTER_SUCCESS,
  POST_CUSTOMER_REGISTER_ERROR,
  AUTHENTICATE
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';
import instance from '../../axiosConfig';
const token = JSON.parse(localStorage.getItem('token'));


export const postLogin = (data) => (dispatch) => {
  return instance({
    method: 'POST',
    url: `auth/brand/signin`,
    headers: {
      Accept: "application/json",
    },
    data: {
      email: data.username,
      password: data.password,
      brand: "kbshop",
    },
  })
    .then((response) => {
      dispatch({
        type: AUTHENTICATE,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};


export const getBrandRegister = (data) => (dispatch) => {
  dispatch({ type: POST_BRAND_REGISTER_REQUEST });
  return axios({
    method: 'POST',
    url: `auth/brand/signup`,
    headers: {
      Accept: "application/json",
    },
    data: {
      account_type: "brand",
      name: data.name,
      website_address: data.websiteaddress,
      email: data.email,
      gender: data.gender,
      country: data.country,
      state: data.state,
      city: data.city,
      password: data.password,
      device: "web",
      referred_by: data.refferedBy,
      zip: data.zip,
      coming_from_app: false,
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_BRAND_REGISTER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_BRAND_REGISTER_ERROR,
        payload: error.response,
      });
    });
};

export const openLogin = (status) => (dispatch) => {
  return dispatch({
    type: POST_LOGIN_OPEN_SUCCESS,
    payload: status,
  });
};

export const getCustomerRegister = (data, insta_handle) => (dispatch) => {
  dispatch({ type: POST_CUSTOMER_REGISTER_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}auth/user/signup`,
    headers: {
      Accept: "application/json",
    },
    data: {
      name: data?.firstName + " " + data.lastName,
      nick_name: data.nickName,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      zip: "1",
      // gender: data.gender,
      // country: data.country,
      // state: data.state,
      // city: data.city,
      password: "kbshop123",
      device: "web",
      coming_from_app: false,
      brand: "kbshop",
      insta_handle: insta_handle,
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_CUSTOMER_REGISTER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_CUSTOMER_REGISTER_ERROR,
        payload: error.response,
      });
    });
};

export const validateUser = (TOKEN) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `auth/brand/validate`,
    headers: { Authorization: `Bearer ${TOKEN  ? TOKEN : token}` }
  })
    .then((response) => {
      dispatch({
        type: AUTHENTICATE,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};