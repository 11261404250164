import axios from "axios";
import { TEMPURL } from "../../config";
import {
  GET_URL_ERROR, GET_URL_REQUEST,
  GET_URL_SUCCESS
} from "../types/types";

export const ShareUrl = (data) => (dispatch) => {
dispatch({ type: GET_URL_REQUEST });
return axios({
  method: "POST",
  url: `${TEMPURL}shorturl`,
  data: data,
})
  .then((response) => {
    return dispatch({ type: GET_URL_SUCCESS, payload: response.data });
  })
  .catch((error) => {
    return dispatch({
      type: GET_URL_ERROR,
      payload: error.response,
    });
  });
};