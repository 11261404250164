import { BASIC_PROFILE_IMAGE } from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

export const basicProfileImage = (formData, id) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .put(`users/revise/profileimage/${id}`, formData, {
        headers: { 
            "Content-Type": "multipart/form-data",
         },
      })
      .then((res) => {
        dispatch({
          type: BASIC_PROFILE_IMAGE,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

