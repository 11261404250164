import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Tabs } from 'antd';
// import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import '../../css/public/public.scss';
import '../../css/public/brand.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PublicHeader from '../../components/public/header/header';
import PublicFooter from '../../components/public/footer/footer';
import publicBannerHome from '../../images/public/public-banner-home.png';
import featureImage from '../../images/public/feature-img.jpg';

import PhoneSpliteLiveNew from '../../images/public/Phone-splite-live-new.png';
import publicBannerBrand from '../../images/public/public-banner-brand.png';
import shop1 from '../../images/public/shopify.png';
import shop2 from '../../images/public/magento.png';
import shop3 from '../../images/public/sales-force.png';
import CustomerVideo from '../../video/customer.mp4';
import { faAngleDoubleDown, faAppleAlt } from '@fortawesome/free-solid-svg-icons';

const { TabPane } = Tabs;

export default function BrandRetailers() {
  return (
    <>
      <PublicHeader />
      <div class="brand-sec-banner home-banner">
        <div class="brn-bnr-box-left">
          <div class="bnr-content">
            <div className="sub-hd">The one-to-many shopping solution</div>
            <h1>
              <span className='big-siz'>Go LIVE <br />
                with ORME</span>
            </h1>
            <p>
              Reach new audiences and boost sales through interactive shopping experiences that customers love. Leave static product pages behind and deliver shopping that inspires, educates and entertains.
            </p>
            {/* <a href="https://apps.apple.com/us/app/orme/id6448742926" target='_' className='mr-3'>Find Out More</a> */}
            <Link to="/register">Get Started Now</Link>
          </div>
        </div>
        <div class="brn-bnr-box-right">
          <img class="public-home-img" src={publicBannerBrand} alt="" />
        </div>
      </div>
      <div class="brnd-sec-3 new">
        <div class="midd-width-ifti">
          <div class="boost-sale-main">
            <div class="boost-sale-header new">
              <h2>Achieve incredible results</h2>
            </div>
            <div class="promote-brand-main new mt-0">
              <div class="promote-brand-box">
                <div class="promote-content">
                  <h2>5:1</h2>
                  <p>Return on <br />
                    Investment</p>
                </div>
              </div>
              <div class="promote-brand-box">
                <div class="promote-content">
                  <h2>10x</h2>
                  <p>More likely <br />
                    to Buy </p>
                </div>
              </div>
              <div class="promote-brand-box">
                <div class="promote-content">
                  <h2>26%</h2>
                  <p>Upselling  <br />
                    Increase</p>
                </div>
              </div>
              <div class="promote-brand-box">
                <div class="promote-content">
                  <h2>5x</h2>
                  <p>Sales <br />
                    Growth</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bioshop-sec-main grey dark">
        <div class="mid-width">
          <div class="bioshop-container no-reverse w-initial">
            <div class="bioshop-box  d-flex justify-content-center">
              <img class="public-why-home-img" src={PhoneSpliteLiveNew} alt="" />
            </div>
            <div class="bioshop-box">
              <div class="bioshop-box-inner">
                <h2 className="mb-20">The future of shopping is here</h2>
                <div className='bio-hd-sub'>Bringing the instore <br />
                  experience online</div>
                <div className='bio-para'>
                  Shoppable video means increased add-to-cart rates and reduced returns. Live chat interaction with viewers lets you answer questions and highlight product features as if you were instore with them. Interactive and highly engaging online shopping reaches new audiences and keeps them coming back for more. ORME makes live shopping possible for all brands and retailers.
                </div>
                <h4 className='bio-hd-end'>What are you waiting for?</h4>
                <div class="bioshop-link">
                  <a href="https://www.ormelive.com/contact-us">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="bioshop-sec-main">
        <div class="mid-width">
          <div class="bioshop-container w-initial">
            <div class="bioshop-box full-width">
              <div class="bioshop-box-inner max-initial max-full">
                <h2 className='text-center mb-50'>Why choose ORME?</h2>

                <div className="row">
                  <div className="col-lg-3 features-box">
                    <h3>We handle <br />
                      the tech</h3>
                    <p className='feature-para w-300'>
                      Go LIVE with zero technical knowledge required - no code to write or third-party apps to install
                    </p>
                  </div>
                  <div className="col-lg-3 features-box">
                    <h3>Smooth buying <br />
                      experience</h3>
                    <p className='feature-para w-300'>
                      In-stream add-to-cart and frictionless checkout leads to hassle-free sales and repeat customers
                    </p>
                  </div>

                  <div className="col-lg-3 features-box">
                    <h3>Intelligent <br />
                      analytics</h3>
                    <p className='feature-para w-300'>
                      Measurable ROI and real-time statistics about orders, sales and viewers let you know exactly what works
                    </p>
                  </div>
                  <div className="col-lg-3 features-box">
                    <h3>Seamless <br />
                      visuals</h3>
                    <p className='feature-para w-300'>
                      High-quality video on desktop and mobile mean a great experience for customers, whatever their platform
                    </p>
                  </div>
                </div>
                
                <a href="https://www.ormelive.com/contact-us" className='roud-link ml-auto mr-auto mt-3 d-table'>Request User Guide</a>

              </div>
            </div>

          </div>
        </div>
      </div>

      {/* <div className='logos-sec-main'>
        <div class="mid-width">
          <div className='logos-container'>
            <h2 class="text-center mb-50 text-center">Who we work with</h2>

            <div className="row">
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5">
                  <div class="brand-boxes-logos">
                    <img src="https://d1w6oxng4jqfhi.cloudfront.net/900000000/profile/27791a9c-a732-4f38-8b27-24114780c530.png" />
                  </div>
                  <div class="brand-user">
                    <div class="brnd-user-title">
                      <span>DL1961</span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5">
                  <div class="brand-boxes-logos">
                    <img src="https://d1w6oxng4jqfhi.cloudfront.net/900000002/profile/c2a692ce-dfec-4ad5-9b41-50a1bb6e104a.png" />
                  </div>
                  <div class="brand-user">
                    <div class="brnd-user-title">
                      <span>HSN</span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5">
                  <div class="brand-boxes-logos">
                    <img src="https://d1w6oxng4jqfhi.cloudfront.net/900000045/profile/2efe8ee7-7c94-4d64-ab3f-39f748f4a382.jpg" />
                  </div>
                  <div class="brand-user">
                    <div class="brnd-user-title">
                      <span>Kohl's</span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5">
                  <div class="brand-boxes-logos">
                    <img src="https://d1w6oxng4jqfhi.cloudfront.net/900000064/profile/89680c2e-8409-4b26-9fd5-139ec32886d2.png" />
                  </div>
                  <div class="brand-user">
                    <div class="brnd-user-title">
                      <span>DKNY</span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5">
                  <div class="brand-boxes-logos">
                    <img src="https://d1w6oxng4jqfhi.cloudfront.net/900000041/profile/a1553399-dd91-484f-9c74-46d24824cb89.png" />
                  </div>
                  <div class="brand-user">
                    <div class="brnd-user-title">
                      <span>Jewelry Television</span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>


            </div>
            <div className="row">
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5"><div class="brand-boxes-logos">
                  <img src="https://d1w6oxng4jqfhi.cloudfront.net/900000000/profile/27791a9c-a732-4f38-8b27-24114780c530.png" />
                </div><div class="brand-user"><div class="brnd-user-title"><span>DL1961</span></div><div></div></div></div>
              </div>
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5"><div class="brand-boxes-logos"><img src="https://d1w6oxng4jqfhi.cloudfront.net/900000002/profile/c2a692ce-dfec-4ad5-9b41-50a1bb6e104a.png" /></div><div class="brand-user"><div class="brnd-user-title"><span>HSN</span></div><div></div></div></div>

              </div>
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5"><div class="brand-boxes-logos"><img src="https://d1w6oxng4jqfhi.cloudfront.net/900000045/profile/2efe8ee7-7c94-4d64-ab3f-39f748f4a382.jpg" /></div><div class="brand-user"><div class="brnd-user-title"><span>Kohl's</span></div><div></div></div></div>
              </div>
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5"><div class="brand-boxes-logos"><img src="https://d1w6oxng4jqfhi.cloudfront.net/900000064/profile/89680c2e-8409-4b26-9fd5-139ec32886d2.png" /></div><div class="brand-user"><div class="brnd-user-title"><span>DKNY</span></div><div></div></div></div>
              </div>
              <div className="col-md-4 col-xl">
                <div class="brand-boxes mb-5"><div class="brand-boxes-logos"><img src="https://d1w6oxng4jqfhi.cloudfront.net/900000041/profile/a1553399-dd91-484f-9c74-46d24824cb89.png" /></div><div class="brand-user"><div class="brnd-user-title"><span>Jewelry Television</span></div><div></div></div></div>
              </div>


            </div>
          </div>

        </div>
      </div> */}

      <div class="bnr-section-2 bg-white d-inline-block">
        <div className='signup-border-content'>
          <h2 className="mb-4 text-center">
            Sign Up with ORME Today!
          </h2>
          <Link
            to="/register" className='roud-link ml-auto mr-auto mt-3 d-table'>Get Started</Link>

        </div>
        <div class="mid-width">
          <div class="one-mark mt-0">


            <video className="customer-video" loop autoPlay muted>
              {/* <source
                src="https://static.konnect.bio/publicwebsite/customer.mp4"
                type="video/mp4"
              ></source> */}
              <source
                src="https://d1hq75pouwojqi.cloudfront.net/01customer.mp4"
                type="video/mp4"
              ></source>
            </video>
            <div className="app-buttons">
              <a href="https://apps.apple.com/us/app/orme/id6448742926" target="_"><i class="fa fa-apple" aria-hidden="true"></i> Download for iOS</a>
              <a href="https://play.google.com/store/apps/details?id=com.orme" target="_"><i class="fa fa-android" aria-hidden="true"></i> Download for Android</a>
            </div>
          </div>
        </div>
      </div>

      <div className="brand-sec-6 bg-white">
        <div className="midd-width-ifti">
          <div className="integrated-sec pt-0">
            <div className="boost-sale-header border-line">
              <h2>Integrated By the Best</h2>
              <h4>
                ORME platform is fully integrated with leading e-commerce
                platforms <br />
                giving access to real-time data.
              </h4>
            </div>
            <div className="plateforms">
              <div className="shop-logo">
                <img src={shop1} alt="" className="sl-1" />
              </div>
              <div className="shop-logo">
                <img src={shop2} alt="" className="sl-2" />
              </div>
              <div className="shop-logo">
                <img src={shop3} alt="" className="sl-3" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <PublicFooter />
    </>
  );
}
