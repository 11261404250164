import React, { useState } from 'react';
import "../../css/public/public.scss";
import "../../css/public/brand.scss";
import "../../css/public/about_us.scss";
import PublicHeader from "../../components/public/header/header";
import PublicFooter from "../../components/public/footer/footer";
import {
  Button,
  Modal,
  Row,
  Collapse,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { Spin, Tabs } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
// import logo from '../../images/konnectbiologo.svg';
import * as subActions from '../../redux/actions/subscribe.action';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { BASEURL } from '../../config';
import { validateUser } from '../../redux/actions/login.action';
import SkeletonComponent from '../../components/SkeletenLoader';
import numeral from 'numeral';

export const history = createBrowserHistory();

const { TabPane } = Tabs;


class PublicPackage extends React.Component {



  state = {
    showBasic: false,
    isExpiredModal: this.props.validate?.payload?.message?.is_trial_expired
      ? Swal.fire({
        title: 'Expired',
        text: 'Your Trial has been expired please make the payment to proceed.',
        icon: 'warning',
        customClass: {
          title: 'trial-title',
        },
      })
      : null,
    showPremium: false,
    showPremiumPlus: false,
    packages: '',
    loading: false,
    promo_code: '',
    promo_error: false,
    promoCodeError: '',
    showSelectPackage: false,
    checkbox: {},
    plan: '',
    showPromo: false,
    showPromoPlus: false,
    help1: true,
    help2: true,
    help3: true,
    packageId: '',
    prices: [],
    selectedtab: 'Monthly',
    paymentLoading: false,
    trailLoading: false,
  };



  componentDidMount() {
    this.setState({ load: true });
    this.props.configSubs().then((res) => {
      this.setState({ prices: res.message });
      this.setState({ load: false });

    });


    
    this.getPackages();
  }

  componentDidUpdate() {
    console.log(this.state.prices?.filter((product) => {
      return product?.product_name === "Brand"
    }).find((brand) => {
      return brand.interval === "year"
    })
    );
  }

  getPackages = async () => {

    await axios
      .get(`package/receive`)
      .then((response) => {
        const packages = response.data.message;
        this.setState({ packages: this.convertArrayToObject(packages) });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  convertArrayToObject = (array) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item['package_name'].replace(/\s+/g, '')]: item,
      };
    }, initialValue);
  };

  handleClose = () => {
    this.setState({ promo_error: false });
    this.setState({ showBasic: false });
    this.setState({ showPremium: false });
    this.setState({ showPremiumPlus: false });
    this.setState({ showSelectPackage: false });
    this.setState({ plan: '', checkbox: {}, promo_code: '' });
    this.setState({ showPromo: false, showPromoPlus: false });
    this.setState({ help1: true, help2: true, help3: true });
    this.setState({ packageId: '' });
  };

  handleCloseIsExpired = () => {
    this.setState({ isExpiredModal: false });
  };

  updatePackage = async (id, packageId) => {

    this.setState({ trailLoading: true });
    await axios
      .put(`users/revise/package/${id}`, {
        package_id: packageId,
      })
      .then((response) => {
        this.setState({ trailLoading: false });
        const parseUserInformation = this.props.validate?.payload?.message;
        parseUserInformation.package = response.data.message;
        const storeUserInformation = JSON.stringify(parseUserInformation);
        // localStorage.setItem('userInfo', storeUserInformation);
        //history.push('/connect');
        this.props.validateUser(this.props.validate?.payload?.message?.token).then((res) => {
          if (res.success) {
            console.log(res);
          }
        })
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data.message);
        this.setState({ trailLoading: false });
      });
  };



 

  handleCheckbox = (e) => {
    const target = e.target;
    const { checkbox } = this.state;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    checkbox[target.name] = value;
    this.setState({
      checkbox,
    });
  };

  getPriceId = (value, name, arr) => {
    const interval = value.slice(0, value.length - 2);
    const priceLists = arr.filter(
      (item) => item.interval === interval && item.product_name === name
    );

    return priceLists;
  };

  // getPriceId = (value, name, arr) => {
  //   const updatedArr = arr.filter(
  //     (item) =>
  //       item.interval === value.slice(0, value.length - 2) &&
  //       item.product_name == name
  //   );
  //   return updatedArr[0].price_id;
  // };



  render() {
    const basic = this.state.packages.Influencer || {};
    const premium = this.state.packages.InfluencerPlus || {};
    const Brand = this.state.packages.Brand || {};
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Platform Fee of 5% of earned commission will be charged.
      </Tooltip>
    );

    return (
      this.state.load ? <SkeletonComponent />
        :
        <>
          <PublicHeader />
          <div className="brand-sec-6 mb-40">
            <div className="midd-width-ifti">
              <div className="integrated-sec">

                <div className="select-plan-main mt-0">

                  <div className="row">
                    <div className="col-lg-6 col-xl-7 d-flex align-items-center">
                      <div className="boost-sale-header col-xl-10 align-items-start mb-30">
                        <h2 className=" text-left">Pricing</h2>
                        <h4 className='col-lg8 text-left'>
                          Package features live Shopping platform with integration to your Ecommerce Platform and Instagram/Facebook.
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-5">


                      <Tabs
                        // activeKey={activeKey}
                        //  onChange={(k) => setActiveKey(k)}
                        centered size="medium" className="package-tabs-main">
                        <TabPane tab="Yearly" key="1">
                          <div className="package_parent mt-0">
                            <div className="custom_pkg_main flex-w-100">
                              <h3>Brand/Retailer Package</h3>
                              <div className="custom_pkg">
                                {/* <div className='package-discpunt'>20% OFF</div> */}
                                <div className="pkg_price_ifti">
                                  <sup>$</sup>
                                  <span className="monthly display-5">{numeral(this.state.prices?.filter((product) => {
                                    return product?.product_name === "Brand"
                                  }).find((brand) => {
                                    return brand.interval === "year"
                                  })?.unit_amount).format("0,0")}</span>
                                  <small className="monthly">/Year</small>
                                </div>
                                <div className="incld-live-shoping">
                                  <span className="includes-lv">Includes:</span>
                                  <ul>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Integration With Ecommerce Platform
                                    </li>

                                    {/* <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Live Shopping Integration
                        </li> */}
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Integration with Instagram/Facebook
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Analytics
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Event Recording/Replay
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Multiple Hosts
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Live Chat with Audience
                                    </li>
                                  </ul>
                                </div>
                                <div className="incld-live-shoping">
                                  <span className="includes-lv">Live Events Charges:</span>
                                  <ul>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Unlimited Live Events/Month
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Unlimited Product Listings
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      $1/1000 Audiences/Minute
                                    </li>
                                  </ul>
                                </div>

                                {!this.props.validate?.payload?.message ?
                                  <Link
                                    to="/register"
                                    variant="dark"
                                    className="select-pkg btn default-btn"
                                  >
                                    Start Plan
                                  </Link>
                                  :
                                  <>
                                    {this.state.promo_code !== '' ? (
                                      <Button
                                        variant="dark"
                                        className="btn_individual"
                                        disabled
                                      >
                                        Start Plan
                                      </Button>
                                    ) : (

                                      <Button
                                        variant="dark"
                                        className="select-pkg btn default-btn"
                                        onClick={() => {
                                          this.setState({ paymentLoading: true });
                                          this.props
                                            .makePayment({
                                              prices: [(
                                                this.state.prices?.filter((product) => {
                                                  return product?.product_name === "Brand"
                                                }).find((brand) => {
                                                  return brand.interval === "year"
                                                })
                                              )],
                                              package_id: Brand.package_id,
                                              recurring_payment_type: "Yearly",
                                            })
                                            .then((res) => {
                                              this.props.validateUser(this.props.validate?.payload?.message?.token).then((res) => {
                                                if (res.success) {
                                                  console.log(res);
                                                }
                                              })
                                              this.setState({ paymentLoading: false });
                                              window.open(res, '_self');
                                            }).catch((err) => {
                                              this.setState({ paymentLoading: false });
                                            });
                                        }}
                                      >
                                        {this.state.paymentLoading ? (
                                          <Spin size="small" />
                                        ) : (
                                          "Start Plan"
                                        )
                                        }
                                      </Button>
                                      // <PaymentButton
                                      //   key="2"
                                      //   userId={userInfo.user_id}
                                      //   packageId={premium.package_id}
                                      //   name={"Select Plan"}
                                      //   variant="dark"
                                      //   paymentMethod={"Influencer"}
                                      //   plan="Monthly"
                                      // />
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tab="Monthly" key="2">
                          <div className="package_parent mt-0">
                            <div className="custom_pkg_main flex-w-100">
                              <h3>Brand/Retailer Package</h3>
                              <div className="custom_pkg">
                                <div className="pkg_price_ifti">
                                  <sup>$</sup>
                                  <span className="monthly display-5">{numeral(this.state.prices?.filter((product) => {
                                    return product?.product_name === "Brand"
                                  }).find((brand) => {
                                    return brand.interval === "month"
                                  })?.unit_amount).format("0,0")}</span>
                                  <small className="monthly">/Month</small>
                                </div>
                                <div className="incld-live-shoping">
                                  <span className="includes-lv">Includes:</span>
                                  <ul>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Integration With Ecommerce Platform
                                    </li>

                                    {/* <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Live Shopping Integration
                        </li> */}
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Integration with Instagram/Facebook
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Analytics
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Event Recording/Replay
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Multiple Hosts
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Live Chat with Audience
                                    </li>
                                  </ul>
                                </div>
                                <div className="incld-live-shoping">
                                  <span className="includes-lv">Live Events Charges:</span>
                                  <ul>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Unlimited Live Events/Month
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      Unlimited Product Listings
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>{' '}
                                      $1/1000 Audiences/Minute
                                    </li>
                                  </ul>
                                </div>

                                {!this.props.validate?.payload?.message ?
                                  <Link
                                    to="/register"
                                    variant="dark"
                                    className="select-pkg btn default-btn"
                                  >
                                    Start Plan
                                  </Link>
                                  :
                                  <>
                                    {this.state.promo_code !== '' ? (
                                      <Button
                                        variant="dark"
                                        className="btn_individual"
                                        disabled
                                      >
                                        Start Plan
                                      </Button>
                                    ) : (

                                      <Button
                                        variant="dark"
                                        className="select-pkg btn default-btn"
                                        onClick={() => {
                                          this.setState({ paymentLoading: true });
                                          this.props
                                            .makePayment({
                                              prices: [(
                                                this.state.prices?.filter((product) => {
                                                  return product?.product_name === "Brand"
                                                }).find((brand) => {
                                                  return brand.interval === "month"
                                                })
                                              )],
                                              package_id: Brand.package_id,
                                              recurring_payment_type: "Monthly",
                                            })
                                            .then((res) => {
                                              this.props.validateUser(this.props.validate?.payload?.message?.token).then((res) => {
                                                if (res.success) {
                                                  console.log(res);
                                                }
                                              })
                                              this.setState({ paymentLoading: false });
                                              window.open(res, '_self');
                                            }).catch((err) => {
                                              this.setState({ paymentLoading: false });
                                            });
                                        }}
                                      >
                                        {this.state.paymentLoading ? (
                                          <Spin size="small" />
                                        ) : (
                                          "Start Plan"
                                        )
                                        }
                                      </Button>
                                      // <PaymentButton
                                      //   key="2"
                                      //   userId={userInfo.user_id}
                                      //   packageId={premium.package_id}
                                      //   name={"Select Plan"}
                                      //   variant="dark"
                                      //   paymentMethod={"Influencer"}
                                      //   plan="Monthly"
                                      // />
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </TabPane>



                      </Tabs>





                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <PublicFooter />

        

        </>
    );
  }
}
const mapStateToProps = state => ({
  validate: state.validate
});
const mapDispatchToProps = {
  validateUser,
  ...subActions
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicPackage);





