// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import { Heading, MeetingProvider } from 'amazon-chime-sdk-component-library-react';

import { StyledLayout } from './Styled';
import DeviceSelection from './Selection/DeviceSelection';
import JoinMeetingDetails from './JoinMeetingDetails';
import { AppStateProvider } from './AppStateProvider';

const DeviceSetup = ({ joinMeeting, meetingChecker, checkEvent, forSetting, meetingData, host, ratio, setRatio, endMeeting }) => (
  <StyledLayout>
    {/* <div className='title-area  text-center'>
    {!forSetting &&<Heading tag="h1" level={3}>
      Device settings
    </Heading>}
    </div> */}
    <DeviceSelection ratio={ratio} setRatio={setRatio} forSetting={forSetting} host={host} />
    {!forSetting && (
      <AppStateProvider>
        <MeetingProvider>
          <JoinMeetingDetails meetingData={meetingData} checkEvent={checkEvent} meetingChecker={meetingChecker} joinMeeting={joinMeeting} host={host} endMeeting={endMeeting} />
        </MeetingProvider>
      </AppStateProvider>
    )}
  </StyledLayout>
);

export default DeviceSetup;