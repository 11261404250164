import {
  ADD_TO_REVIEW_REQUEST,
  ADD_TO_REVIEW_SUCCESS,
  ADD_TO_REVIEW_ERROR,
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const addToReview =
  (type, media_id, user_id, username, followers) => (dispatch) => {
    dispatch({ type: ADD_TO_REVIEW_REQUEST });
    return axios({
      method: "POST",
      url: `reviews/addtoreview`,
      data: {
        type: type,
        media_id: media_id,
        user_id: user_id,
        username: username,
        followers: followers,
      },
    })
      .then((response) => {
        return dispatch({
          type: ADD_TO_REVIEW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ADD_TO_REVIEW_ERROR,
          payload: error.response,
        });
      });
  };
