import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStepsForm } from 'sunflower-antd';

import AsyncProduct from '../create-event/scheduleEvent/asyncProduct';
import {
    InboxOutlined,
    UploadOutlined,
    PlusOutlined,
    CalendarFilled,
    DeleteFilled
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { postEvent } from '../../redux/actions/event.action';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getParentCategories } from '../../redux/actions/category.action';
import { getRefreshProd } from '../../redux/actions/refreshProducts.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getEventsBy_Id,
    UpdateEvents,
    getShowBy_Id,
    UpdateShows
} from '../../redux/actions/event.action';
import {
    faAngleLeft,
    faCalendarPlus,
    faCaretRight,
    faShare,
    faTriangleExclamation,
    faXmark,
    faVideo,
    faUser,
    faUserTie,
    faEye
} from '@fortawesome/free-solid-svg-icons';
import {
    Steps,
    Input,
    Button,
    Form,
    Result,
    Tabs,
    Space,
    DatePicker,
    TimePicker,
    Upload,
    message,
    notification,
    Select,
    Spin,
    Checkbox,
    Image,
    Affix,
    Modal,
    Table,
    Switch,
    Tag,
} from 'antd';
import moment from 'moment';
import useMediaQuery from '../../reUsable/useMediaQuery';
import { set } from 'lodash';
import LoadingSpin from '../../reUsable/loadingSpin';
const type = 'DraggableBodyRow';
var total = 0;

const { Step } = Steps;
const { TabPane } = Tabs;
const { Option } = Select;

export default function InventoryManagement(props) {
    const location = useLocation();
    const matches = useMediaQuery('(min-width: 1024px)');
    const deadline = '2018-12-20T07:37:50.886Z';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [streamModal, setIsModalOpenStream] = useState(false);
    const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
    const [isCheckedOpen, setIsCheckedOpen] = useState(false);
    const [dataDetails, setDataDetail] = useState([]);
    const [referralPercent, setReferralPercent] = useState("0");
    const [discount, setDiscount] = useState("");
    const [influencer_percent, setInfluencer_percent] = useState("0");
    const [promo, setPromo] = useState("");
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [flag, setFlag] = useState(false);
    const [defaultBanner, setDefaultBanner] = useState(false);
    const [videoRatio, setRatio] = useState("16:9");
    const [fileList, setFileList] = useState([]);
    const [contentType, setContentType] = useState("");
    const [promptModal, setPromptModal] = useState(true);
    const [vidMsg, setVidMsg] = useState(false);
    const [multipleHosts, setMultipleHosts] = useState(false);
    const [recURL, setRecURL] = useState("");
    const [infoTitle, setInfoTitle] = useState("");
    const [infoDesc, setInfoDesc] = useState("");
    const [platform, setPlatform] = useState(false);
    const [streamUrl, setStreamUrl] = useState("local");
    const [externalUrl, setExternalUrl] = useState("");
    const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
    const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
    const [orientation, setOrientation] = useState("");
    const [flagg, setFlagg] = useState(false);
    const [fields, setFields] = useState({
        image: "",
    });
    const [imageThumb, setImageThumb] = useState("");
    const [ext, setExt] = useState({});
    const [sort, setSort] = useState('');

    const [gender, setGender] = useState("");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [genLoading, setGenLoading] = useState(true);
    const [actionChange, setActionChange] = useState(false);

    const showModal = (s) => {
        setDataDetail(s.variants);
        setIsModalOpen(true);
    };

    const [top, setTop] = useState(30);

    const { TextArea } = Input;
    const dispatch = useDispatch();
    const history = useNavigate();
    const { parentCategory, refreshProd } = useSelector((state) => {
        return state;
    });

    const [spinner, setSpin] = useState(false);
    const [dateTime, setDate] = useState('');
    const [formState, setFormState] = useState('add');
    const [sku, setSku] = useState([]);
    const [banner, setBanner] = useState([]);
    const [host, setHost] = useState([]);
    const [hostError, setHostError] = useState(false);
    const [host2, setHost2] = useState([]);
    const [hostError2, setHostError2] = useState(false);
    const [host3, setHost3] = useState([]);
    const [hostError3, setHostError3] = useState(false);
    const [host4, setHost4] = useState([]);
    const [hostError4, setHostError4] = useState(false);
    const [skuError, setSkuError] = useState(false);
    const [parent, setParent] = useState('');
    const [imageUpload, setImageUpload] = useState(false);
    const [sampleBanner, setSampleBanner] = useState(false);
    const [note, setEventNote] = useState('');
    const [catgId, setCategoryId] = useState('');
    const [department, setDepartment] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [thankyou, setEventThankyou] = useState('');
    const [data, setData] = useState([]);
    const [page, setPage] = React.useState(1);
    const [previewImage, setPreviewImage] = useState('');
    const [eventTitle, setEventTitle] = useState('');
    const [removeSku, setRemoveSku] = useState('');
    const [replace, setReplace] = useState(false);
    const [replaceIndex, setReplaceIndex] = useState("");
    const [skuArr, setSkuArr] = useState([]);
    const [replaceSku, setReplaceSku] = useState([]);
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleReplace = () => {
        if (replaceSku.length != 0) {
            setReplace(false)
            notification.success({
                message: 'SKU Product Replaced Successfully',
                className: 'toast-success',
            });
            let prevArr = data;
            prevArr.push(replaceSku);
            Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
            };
            prevArr.move(replaceIndex, prevArr.length - 1);
            prevArr.move(prevArr.length - 2, replaceIndex);
            setData(prevArr)
            setSkuArr([])
            setReplaceSku([])
        } else {
            notification.error({
                message: 'No SKU Product Selected',
                className: 'toast-error',
            });
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setReplace(false)
    };
    const { getEventById, updateEvent, getShowById } = useSelector((state) => {
        return state;
    });
    const params = useParams();
    const id = params.id;

    const getSum = (total, num) => {
        return total + Math.round(num);
    }
    const onToggle = (record, recInd) => {
        setActionChange(true)
        if (record?.is_active == true) {

            record.is_active = false
            let obj = record;
            const key = { ...obj };
            key.is_active = false

            setData(current =>
                current.map((obj, index) => {
                    if (index == recInd) {
                        return { ...obj, is_active: false };
                    }
                    return obj;
                }),
            );

        }
        else {

            record.is_active = true
            let obj = record;
            const key = { ...obj };
            key.is_active = true


            setData(current =>
                current.map((obj, index) => {
                    if (index == recInd) {
                        return { ...obj, is_active: true };
                    }
                    return obj;
                }),
            );
        }
    };
    useEffect(() => {
        if (replace && removeSku) {
            setSkuArr([])
        }
        else if (removeSku) {
            setData(prevArray => [...prevArray, sku])
            setData(data?.filter(avail => { return avail.ProductSku !== removeSku?.ProductSku }))
        } else if (replace) {
            if (skuArr.length > 0) {
                notification.error({
                    message: 'One SKU Product Already Added',
                    className: 'toast-error',
                });
            } else {
                setSkuArr([replaceSku])
            }
        }
        else if (sku) {
            setData(prevArray => [...prevArray, sku])
        }
    }, [sku, removeSku])
    console.log("sku", sku);

    useEffect(() => {
        if (replace) {
            if (skuArr.length > 0) {
                notification.error({
                    message: 'One SKU Product Already Added',
                    className: 'toast-error',
                });
            } else {
                setSkuArr([replaceSku])
            }
        }
    }, [replaceSku])
    useEffect(() => {
        if (id) {
            dispatch((location?.state?.inventory === "shows" ? getShowBy_Id(id) : getEventsBy_Id(id))).then(async (event) => {
                if (event.payload.success) {
                    setSpin(false);
                    if (location?.state?.inventory === "shows") {
                        let orderData = event.payload?.message[0]?.orders;
                        let productsData = event.payload?.message[0]?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)

                        setSampleBanner(
                            event?.payload?.message[0]?.default_banner ? true : false
                        );
                        setDefaultBanner(
                            event?.payload?.message[0]?.default_banner ? true : false
                        );
                        setRecURL(event?.payload?.message?.[0]?.recording_url);
                        setPreviewImage(event?.payload?.message[0]?.banner);
                        setEventThankyou(event.payload.message[0]?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.title);
                        setEventNote(event.payload.message[0]?.notes);
                        setproductPromoCodePromo(event.payload.message[0]?.promo);
                        setProductPromoCodeDscs(event.payload.message[0]?.discount);
                        setOrientation(event.payload.message[0]?.orientation);

                        setRatio(event.payload.message[0]?.ratio);

                        setPlatform(
                            event.payload.message[0]?.is_facebook_live ? true : false
                        );
                        if (event.payload.message[0]?.is_facebook_live) {
                            setInfoTitle(event.payload.message[0]?.facebook?.title);
                            setInfoDesc(event.payload.message[0]?.facebook?.description);
                        }

                        setReferralPercent(
                            event.payload.message[0]?.referral_percent === ""
                                ? "0"
                                : event.payload.message[0]?.referral_percent
                        );
                        setPromo(
                            event.payload.message[0]?.promo === ""
                                ? "0"
                                : event.payload.message[0]?.promo
                        );
                        setInfluencer_percent(
                            event.payload.message[0]?.influencer_percent === ""
                                ? "0"
                                : event.payload.message[0]?.influencer_percent
                        );
                        setDiscount(
                            event.payload.message[0]?.discount === ""
                                ? "0"
                                : event.payload.message[0]?.discount
                        );
                        setMultipleHosts(
                            event.payload.message[0]?.multiple_hosts
                                ? event.payload.message[0]?.multiple_hosts
                                : false
                        );
                        //setStreamUrl(event.payload.message[0]?.stream_type);
                        setStreamUrl("local");

                        setDepartment(event.payload?.message[0]?.gen_category?.name)
                        setCategory(event.payload?.message[0]?.category?.category_name)
                        setSubCategory(event.payload?.message[0]?.sub_category?.sub_category_name)

                        setExternalUrl(event.payload.message[0]?.streaming_url);
                        setDate(moment(moment(event.payload.message[0]?.start_date)));
                        setBanner(event?.payload?.message[0]?.banner);

                        setFormState("edit");
                        setLoad(false);
                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item?.category_id == event?.payload?.message?.[0]?.category_id
                        );
                        setParent(parent?.[0]?.category_name);
                    } else {
                        let orderData = event.payload?.message[0]?.event?.orders;
                        let productsData = event.payload?.message[0]?.event?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)
                        setSampleBanner(
                            event.payload?.message[0]?.event?.default_banner ? true : false
                        );

                        setPreviewImage(event.payload?.message[0]?.event?.banner);
                        setEventThankyou(event.payload.message[0]?.event?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.event?.title);
                        setEventNote(event.payload.message[0]?.event?.notes);
                        setDate(moment(moment(event.payload.message[0]?.event?.start_date)));
                        setBanner(event.payload?.message[0]?.event?.banner);

                        let primary_data =
                            (await event.payload?.message[0]?.hosts[1]?.primary) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let primary_host = await primary_data.filter(
                            (num) => num.primary === true
                        );

                        let host2_data =
                            (await event.payload?.message[0]?.hosts[2]?.host1) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_2 = await host2_data.filter((num) => num.host1 === true);

                        let host3_data =
                            (await event.payload?.message[0]?.hosts[3]?.host2) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_3 = await host3_data.filter((num) => num.host2 === true);

                        let host4_data =
                            (await event.payload?.message[0]?.hosts[4]?.host3) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_4 = await host4_data.filter((num) => num.host3 === true);

                        setHost(primary_host);
                        setHost2(host_2);
                        setHost3(host_3);
                        setHost4(host_4);
                        setCategoryId(event.payload?.message[0]?.event?.category_id);
                        setDepartment(event.payload?.message[0]?.event?.gen_category?.name)
                        setCategory(event.payload?.message[0]?.event?.category?.category_name)
                        setSubCategory(event.payload?.message[0]?.event?.sub_category?.sub_category_name)

                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item.category_id == event.payload?.message[0]?.event?.category_id
                        );
                        setParent(parent[0]?.category_name);
                    }




                }
            });
        }
    }, [id]);



    const inventoryStatus = (record) => {
        let status = record?.variants?.reduce((acc, item) => {
            return (
                acc = acc + item.inventory_quantity
            )
        }, 0)
        if (status > 0) {
            return false
        } else {
            return true
        }

    }

    const getSku = (skuData, removedSku) => {

        setRemoveSku(removedSku)


        var matchedSku = null;
        skuData.forEach(element => {
            data.forEach(ele => {
                if (element.ProductSku === ele.ProductSku) {

                    matchedSku = ele.ProductSku;
                }
            })
        })

        setSkuError(false);
        setIsCheckedOpen(false)

        if (skuData.length > 0) {


            if (skuData.length > 0) {
                skuData.map((item) => {
                    if (!(item.ProductSku == matchedSku) && !replace) {
                        setSku(item)
                    } else if (!(item.ProductSku == matchedSku) && replace) {
                        setReplaceSku(item)
                    }

                })
            }

        };
    }
    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef(null);
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: (monitor) => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: (item) => {
                moveRow(item.index, index);
            },
        });
        const [, drag] = useDrag({
            type,
            item: {
                index,
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));
        return (
            <tr
                ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{
                    cursor: 'move',
                    ...style,
                }}
                {...restProps}
            />
        );
    };
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            width: 50,
            render: (text, record, index) => {
                return (
                    <p>
                        {index + 1}
                    </p>
                );
            },
        },
        {
            title: 'SKU',
            dataIndex: 'ProductSku',
            key: 'ProductSku',
            width: 60,
        },
        {
            title: 'IMAGE',
            dataIndex: 'mediaUrl',
            key: 'mediaUrl',
            width: 70,
            render: (text, record) => {
                return (
                    <div className="listing-image">
                        <img src={`${record.mediaUrl}`} />
                    </div>
                );
            },
        },
        {
            title: 'Description',
            dataIndex: 'productDesc',
            key: 'productDesc',
            width: 250,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record.productDesc}
                    </div>
                );
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 80,
            // sortOrder: sort,
            // sorter: (a, b) => a.price - b.price,
            // onHeaderCell: () => ({
            //     onClick: () => setSort(sort === 'ascend' || sort === '' ? 'descend' : 
            //   'ascend'),       
            //   sortDirections: ['ascend', 'descend', 'ascend'],
            //   }),
        },
        // {
        //     title: 'Stock',
        //     dataIndex: 'stock',
        //     key: 'stock',
        //     width: 80,
        //     render: (text, record) => {
        //         return (

        //             <p>0</p>

        //         );
        //     },
        // },

        {
            title: 'Inventory',
            dataIndex: 'event',
            key: 'event',
            width: 100,
            // sortOrder: sort,
            // sorter: (a, b) => {
            //     let aa = a?.variants?.reduce((acc, item) => {
            //         return (
            //             acc = acc + item.inventory_quantity
            //         )
            //     }, 0);
            //     let bb = b?.variants?.reduce((acc, item) => {
            //         return (
            //             acc = acc + item.inventory_quantity
            //         )
            //     }, 0)
            //     return aa - bb;
            // },
            // onHeaderCell: () => ({
            //     onClick: () => setSort(sort === 'ascend' || sort === '' ? 'descend' :
            //         'ascend'),
            //     sortDirections: ['ascend', 'descend', 'ascend'],
            // }),
            render: (text, record) => (
                <>
                    <div className='balance-area'>
                        <div className='balance-count'>
                            {record?.variants?.reduce((acc, item) => {
                                return (
                                    acc = acc + item.inventory_quantity
                                )
                            }, 0)}
                        </div>
                        <a className='balance-btn default-btn outline small-btn' onClick={() => {
                            showModal(record)
                        }}
                        >
                            View
                            {/* <FontAwesomeIcon icon={faEye} /> */}
                        </a>
                    </div>
                </>

            ),
        },
        {
            title: 'Status',
            dataIndex: 'event',
            key: 'event',
            width: 100,
            render: (text, record) => {
                let status = record?.variants?.reduce((acc, item) => {
                    return (
                        acc = acc + item.inventory_quantity
                    )
                }, 0)
                if (status > 70) {
                    return (
                        <div className='status-info'><div className='status-flag green'></div>
                            {/* <p>green</p> */}
                        </div>
                    )
                } else if (status >= 50 && status <= 70) {
                    return (
                        <div className='status-info'><div className='status-flag yellow'></div>
                            {/* <p>yellow</p> */}
                        </div>
                    )
                } else if (status < 50) {
                    return (
                        <div className='status-info'><div className='status-flag red'></div>
                            {/* <p>red</p> */}
                        </div>
                    )
                } else {
                    return null
                }
            }
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     fixed: 'right',
        //     width: 80,
        //     // sorter: true,
        //     render: (text, record, index) => {
        //         let status = record?.variants?.reduce((acc, item) => {
        //             return (
        //                 acc = acc + item.inventory_quantity
        //             )
        //         }, 0)
        //         return (
        //             <>
        //                 <Space size="middle" className="actions-list">
        //                     {/* <DeleteFilled onClick={() => alert("Sure!")} /> */}
        //                     <Switch
        //                         size="small"
        //                         checked={record?.is_active && status > 0}
        //                         onChange={() => onToggle(record, index)}
        //                         disabled={!status > 0 || replace}
        //                     // checkedChildren="Fixed Top"
        //                     // unCheckedChildren="Fixed Top"

        //                     // onChange={() => {
        //                     //   setFixedTop(!fixedTop);
        //                     // }}
        //                     />
        //                 </Space>
        //                 {!status > 0 &&
        //                     <a className={`balance-btn default-btn outline small-btn ml-3 ${replace && "replacing"}`} onClick={() => {
        //                         {
        //                             setReplace(true);
        //                             setReplaceIndex(index);
        //                         }
        //                     }}
        //                     >
        //                         Replace
        //                         {/* <FontAwesomeIcon icon={faEye} /> */}
        //                     </a>
        //                 }
        //             </>
        //         )
        //     },
        // }
    ];

    const columnDetails = [

        {
            title: 'Size',
            key: 'option1',
            dataIndex: ['option1'],
            width: 40,
            render: (text, record, index) => {

                return (
                    <>


                        <div className=''> {record?.option1 ? record?.option1 : '-'} </div>


                    </>
                );

            },
        },

        {
            title: 'Wash',
            key: 'option1',
            dataIndex: ['option1'],
            width: 100,
            render: (text, record, index) => {

                return (
                    <>


                        <div className=''> {record?.option2 ? record?.option2 : '-'} </div>


                    </>
                );

            },
        },

        {
            title: 'Inseam',
            key: 'option1',
            dataIndex: ['option1'],
            width: 70,
            render: (text, record, index) => {

                return (
                    <>




                        <div className=''>{record?.option3 ? record?.option3 : '-'} </div>

                    </>
                );

            },
        },

        {
            title: 'Stock',
            dataIndex: 'inventory_quantity',
            key: 'inventory_quantity',
            width: 60,
        },

    ];
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            setActionChange(true)
            const dragRow = data[dragIndex];
            setIsCheckedOpen(false)
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );

        },
        [data],

    );
    console.log("replaceSku", replaceSku);
    console.log("data", data);
    const trigger = () => {
        setSpin(false);
        setIsCheckedOpen(true)

        let formData = new FormData();

        let newData = data.map(skuItem => {
            return ({
                ...skuItem,
                influencer_percent: data?.[0]?.influencer_percent ? data?.[0]?.influencer_percent : "0",
                referral_percent: data?.[0]?.referral_percent ? data?.[0]?.referral_percent : "0"
            })
        })
        if (location?.state?.inventory === "shows") {
            formData.append(
                "banner",
                typeof banner === "object" ? banner : undefined
            );
            formData.append("show", fields.image);
            formData.append("id", newData[0]?.id);
            formData.append("products", JSON.stringify(newData));
            formData.append("title", eventTitle);
            formData.append("notes", note);
            formData.append("video_ratio", videoRatio);
            // formData.append("promo", promo);
            // formData.append("discount", (discount += "%"));
            formData.append("discount", productPromoCodeDscs);
            formData.append("promo", productPromoCodePromo);
            formData.append(
                "referral_percent",
                referralPercent > 0 ? referralPercent : 0
            );
            formData.append(
                "influencer_percent",
                influencer_percent > 0 ? influencer_percent : 0
            );
            formData.append("thankyou_message", thankyou);

            formData.append("default_banner", sampleBanner);
            formData.append("orientation", orientation);
        } else {

            formData.append('image', banner);
            formData.append('id', newData[0]?.id);
            formData.append('primary_host', JSON.stringify(host));
            formData.append('host1', JSON.stringify(host2));
            formData.append('host2', JSON.stringify(host3));
            formData.append('host3', JSON.stringify(host4));
            formData.append('products', JSON.stringify(newData));
            formData.append('title', eventTitle);
            formData.append('notes', note);
            formData.append('thankyou_message', thankyou);
            formData.append('start_date', dateTime.toISOString());
            formData.append('category_id', catgId);
            formData.append('default_banner', sampleBanner);
        }
        // formData.append('social_platform', platform);
        dispatch((location?.state?.inventory === "shows" ? UpdateShows(formData, id) : UpdateEvents(formData, id))).then((res) => {
            if (res?.payload?.success) {
                notification.success({
                    message: 'Product Updated Successfully',
                    className: 'toast-success',
                });
                setActionChange(false)
                // showModal();
            } else {
                setSpin(false);
                notification.error({
                    message: 'Event Failed',
                    className: 'toast-error',
                });
            }
        });

    }

    const getProd = () => {
        dispatch(getRefreshProd(location?.state?.inventory === "shows" ? { "event": id } : { "event": id })).then(async (res) => {
            if (res.payload.success === true) {
                notification.success({
                    message: res.payload.message,
                    className: 'toast-success',
                });
                dispatch((location?.state?.inventory === "shows" ? getShowBy_Id(id) : getEventsBy_Id(id))).then(async (event) => {
                    if (event.payload.success) {
                        setSpin(false);
                        let orderData = event.payload?.message[0]?.event?.orders;
                        let productsData = event.payload?.message[0]?.event?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)
                        setSampleBanner(
                            event.payload?.message[0]?.event?.default_banner ? true : false
                        );

                        setPreviewImage(event.payload?.message[0]?.event?.banner);
                        setEventThankyou(event.payload.message[0]?.event?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.event?.title);
                        setEventNote(event.payload.message[0]?.event?.notes);
                        setDate(moment(moment(event.payload.message[0]?.event?.start_date)));
                        setBanner(event.payload?.message[0]?.event?.banner);

                        let primary_data =
                            (await event.payload?.message[0]?.hosts[1]?.primary) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let primary_host = await primary_data.filter(
                            (num) => num.primary === true
                        );

                        let host2_data =
                            (await event.payload?.message[0]?.hosts[2]?.host1) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_2 = await host2_data.filter((num) => num.host1 === true);

                        let host3_data =
                            (await event.payload?.message[0]?.hosts[3]?.host2) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_3 = await host3_data.filter((num) => num.host2 === true);

                        let host4_data =
                            (await event.payload?.message[0]?.hosts[4]?.host3) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_4 = await host4_data.filter((num) => num.host3 === true);

                        setHost(primary_host);
                        setHost2(host_2);
                        setHost3(host_3);
                        setHost4(host_4);
                        setCategoryId(event.payload?.message[0]?.event?.category_id);

                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item.category_id == event.payload?.message[0]?.event?.category_id
                        );
                        setParent(parent[0]?.category_name);

                    }
                });
            }
        })
    }

    const formList = [
        <>
            <div className="row d-flex mb-20">
                {/* <div className="col-12 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Sku Management</h5>
          <p>
            Create product list to live event by using SKU numbers as added on
            the ecommerce platform.
          </p>
        </div> */}
                <div className="col-12 col-xl-12  flex-stretch form-styling">
                    <Form.Item>
                        <Form.Item
                            name="productsku"
                            label="Product SKU"
                            className="d-flex flex-column sku-field mb-10 pos-index"
                        >
                            <AsyncProduct
                                getSku={getSku}
                                sku={sku}
                                skuData={data}
                            />
                            {skuError && (
                                <div role="alert" className="ant-form-item-explain-error">
                                    Please Select Product SKU
                                </div>
                            )}
                        </Form.Item>
                    </Form.Item>
                    <DndProvider backend={HTML5Backend}>
                        <Table
                            sorter
                            rowClassName={(record, index) => (inventoryStatus(record) || !record?.is_active) && 'grayed'}
                            pagination={false}
                            columns={columns}
                            dataSource={data?.[0]?.length === 0 ? "" : [...data]}
                            loading={getEventById.loading || getShowById.loading}
                            components={components}
                            onRow={(_, index) => {
                                const attr = {
                                    index,
                                    moveRow,
                                };
                                return attr;
                            }}
                            scroll={
                                data.length > 6
                                    ? { y: 550, x: 1000 }
                                    : { x: 1000 }
                            }
                        // scroll={{
                        //     x: 1000,
                        //     y: 150,
                        //   }}
                        />
                    </DndProvider>

                </div>
            </div>

        </>
    ];

    return (
        getEventById.loading || getShowById.loading ? <LoadingSpin /> :
            <>
                <div className="control-room-top-area mb-2">
                    <div className="control-top-box">
                        <Link
                            to={
                                location?.state?.inventory === "shows"
                                    ? `/shows?type=${location?.state?.title}`
                                    : `/events?type=${location?.state?.title}`
                            }
                            className="prev-next-link ff-nunito"
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                            <span>Go back</span>
                        </Link>
                        {/* </a> */}
                    </div>
                </div>


                <div className='row justify-content-center'>

                    <div className='col-lg-7 col-xl-6'>
                        <div className="event-preview-box-main mb-20">
                            <div className="row">
                                <div className="col-md-3 col-lg-3 d-lg-flex align-items-center">
                                    {/* <div className="event-banner" style={{ backgroundImage: `url(${getEventById?.payload?.message[0]?.banner})` }} > */}
                                    <div className="event-banner w-md-100">
                                        <div className="event-box w-100 h-100 overflow-hidden">
                                            <div className="event-banner-img">
                                                <img
                                                    src={banner}
                                                    alt="banner"
                                                />
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-2 col-lg-2 d-lg-flex  preview-data-border border-left-0 align-items-center">
                        <div className="preview-date-main">
                            <h4 className="date-txt">Date</h4>
                            <h5 className="preview-date">
                                {moment(
                                    dateTime
                                )
                                    .utc()
                                    .format("YYYY-MM-DD")}
                            </h5>
                            <h6 className="preview-time">
                                At{" "}
                                {moment(
                                    dateTime
                                ).format("hh:mm a")}
                            </h6>
                        </div>
                    </div> */}




                                <div className="col-md-5 col-lg-5 d-md-flex preview-data-borde align-items-center pl-xl-0">
                                    <div className="recorded-badge-inventory">Recorded</div>

                                    <div className="preview-date-main">
                                        <h4 className="date-txt">Event</h4>
                                        <h5
                                            className="preview-date"
                                            title={eventTitle}
                                        >
                                            {eventTitle.length > 30
                                                ? eventTitle.slice(
                                                    0,
                                                    30
                                                ) + "..."
                                                : eventTitle}
                                        </h5>
                                    </div>
                                </div>

                                <div className='col-md-4 col-md-4'>
                                    <p className='mb-0 mt-2'>
                                        <span className='fw-bold'>Department: </span>
                                        <span>{department ?  department : "All"}</span>
                                    </p>
                                    <p className='mb-0 mt-2'>
                                        <span className='fw-bold'>Category: </span>
                                        <span>{category ? category : "All"}</span>
                                    </p>
                                    <p className='mb-0 mt-2'>
                                        <span className='fw-bold'>Sub Category: </span>
                                        <span>{subCategory ? subCategory : "All"}</span>
                                    </p>
                                </div>
                                {/* <div className="col-md-3 col-lg-3 preview-data-border d-lg-flex align-items-center">
                        <div className="preview-date-main no-border-bottom">
                            <h4 className="date-txt notes">Details</h4>
                            <h6 className="preview-time notes-txt">
                                {note !== ""
                                    ? note
                                    : "-"}
                            </h6>
                        </div>
                    </div> */}
                            </div>

                        </div>
                    </div>

                </div>
                <div className="create-event-top-area mb-4 d-flex justify-content-center">
                    {/* <div className="top-area-box">
          <Link to="/events?type=Upcoming" className="prev-next-link ff-nunito">
            <FontAwesomeIcon icon={faAngleLeft} />
            <span> Go back</span>
          </Link>
        </div> */}
                    <div className="top-area-box ">
                        <div className="title-area mb-0 pb-0">
                            <h1 className="mb-0 pb-0">Inventory Management</h1>
                        </div>
                    </div>
                    <div className="top-area-box">
                        &nbsp;
                        {/* <a className="prev-next-link ff-nunito" href="#">
            <FontAwesomeIcon icon={faCaretRight} />
            <span> How to create an event</span>
          </a> */}
                    </div>
                </div>



                <div className="inventory-box-main">

                    <Form >{formList}</Form>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className="default-btn disable-grey mr-0 align-items-center justify-content-center"
                            onClick={trigger}
                            disabled={isCheckedOpen && !actionChange}
                            htmlType="submit"
                            loading={refreshProd.loading}
                        >
                            Save
                        </Button>
                        <Button
                            className="default-btn mr-0 align-items-center justify-content-center"
                            onClick={getProd}
                            htmlType="submit"
                            loading={refreshProd.loading}
                        >
                            Refresh
                        </Button>
                    </div>
                </div>



                <Modal
                    maskClosable={false}
                    className="modal-generic modal-600"
                    centered
                    visible={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    closeIcon={<FontAwesomeIcon icon={faXmark} />}
                    footer={false}
                // footer={[
                //   // <Button key="back" onClick={handleCancel}>
                //   //   Cancel
                //   // </Button>,
                //   <Button key="submit" type="primary"
                //     className="d-table m-auto"
                //     // onClick={handleOk}
                //     // onClick={handleCancel}
                //   >
                //     Submit
                //   </Button>,
                // ]}

                >
                    <h2 className="modal-hd1">Details</h2>
                    <div className="co">
                        <Table
                            pagination={false}
                            columns={columnDetails}
                            size="small"
                            className="product-listing-tabl inventory-box-main details-table"
                            //  rowSelection={{ ...rowSelection }}
                            dataSource={dataDetails}
                            // scroll={
                            //     dataDetails.length > 32
                            //         ? { y: 450, x: 500 }
                            //         : false
                            // }
                            scroll={
                                dataDetails.length > 10
                                    ? { y: 400, x: 500 }
                                    : { x: 500 }
                            }
                        />
                    </div>
                </Modal>

                <Modal
                    className="modal-generic modal-1000"
                    centered
                    open={replace}
                    onOk={handleReplace}
                    onCancel={handleCancel}
                    closeIcon={<FontAwesomeIcon icon={faXmark} />}
                    footer={false}
                    maskClosable={false}

                >
                    <h2 className="modal-hd1">Add Sku</h2>
                    <Form >
                        <Form.Item>
                            <Form.Item
                                name="productsku"
                                label="Product SKU"
                                className="d-flex flex-column sku-field mb-10 pos-index"
                            >
                                <AsyncProduct
                                    getSku={getSku}
                                    replaceSku={replaceSku}
                                    skuData={data}
                                    replace={replace}
                                    skuArr={skuArr}
                                />
                                {skuError && (
                                    <div role="alert" className="ant-form-item-explain-error">
                                        Please Select Product SKU
                                    </div>
                                )}
                            </Form.Item>
                        </Form.Item>
                        <DndProvider backend={HTML5Backend}>
                            <Table
                                sorter
                                rowClassName={(record, index) => (inventoryStatus(record) || !record?.is_active) && 'grayed'}
                                pagination={false}
                                columns={columns}
                                dataSource={skuArr?.length > 0 ? skuArr : ""}
                                loading={getEventById.loading || getShowById.loading}
                                components={components}
                                className={'scrollbar-style-parent mb-15'}
                                onRow={(_, index) => {
                                    const attr = {
                                        index,
                                        moveRow,
                                    };
                                    return attr;
                                }}
                                scroll={
                                    data.length > 6
                                        ? { y: 550, x: 900 }
                                        : { x: 900 }
                                }

                            />
                        </DndProvider>
                    </Form>
                    <div className="d-flex justify-content-end">
                        <Button
                            // loading={loading}
                            onClick={handleReplace}
                            // className="default-btn padb-3 d-inline-flex align-items-center justify-content-center color-secondary"
                            className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center 
            `}
                        >
                            <span className="nav-text">Replace</span>
                        </Button>
                        <Button
                            className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center"
                            key="back"
                            onClick={handleCancel}
                        >
                            Exit
                        </Button>
                    </div>

                </Modal>


            </>
    );
}
