import {
  GET_SHOPIFY_DETAIL_REQUEST,
  GET_SHOPIFY_DETAIL_SUCCESS,
  GET_SHOPIFY_DETAIL_ERROR,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  POST_SHOPIFY_REQUEST,
  POST_SHOPIFY_SUCCESS,
  POST_SHOPIFY_ERROR,
} from '../types/types';
import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'));
export const getShopifyDetail = () => (dispatch) => {
  dispatch({ type: GET_SHOPIFY_DETAIL_REQUEST });

  return axios({
    method: 'GET',
    url: `users/receive/shopify`,
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_SHOPIFY_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SHOPIFY_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const getProducts = (TOKEN) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_REQUEST });

  return axios({
    method: 'GET',
    url: `shopify/getproducts`,
    // data: {
    // 	parent_id: id,
    // },
    headers: { Authorization: `Bearer ${TOKEN  ? TOKEN : token}` }
  })
    .then((response) => {
      return dispatch({
        type: GET_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_PRODUCT_ERROR,
        payload: error.response,
      });
    });
};

export const postShopify = (data) => (dispatch) => {
  dispatch({ type: POST_SHOPIFY_REQUEST });
  return axios({
    method: 'POST',
    url: `users/revise/shopify`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_SHOPIFY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_SHOPIFY_ERROR,
        payload: error.response,
      });
    });
};
