import React from 'react';
import { Button } from 'antd';
import { Navigate, useNavigate } from "react-router-dom";
import PublicHeader from '../../components/public/header/header';
import '../../css/payment.scss';
import { useDispatch, useSelector } from 'react-redux';
import { validateUser } from "../../redux/actions/login.action";
export default function Payment() {

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);

  const token = JSON.parse(localStorage.getItem("token"));
  const { validate } = useSelector((state) => { return state })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(searchParams.get("status"));
  console.log("validate", validate);
  const Proceed = () => {
    if (searchParams.get("status") === "success") {
      dispatch(validateUser(token)).then((res) => {
        if (res?.message?.package) {
          console.log("Success Res", res);
        } else {
          navigate("/package")
        }
      })
    } else {
      navigate("/package")
    }
  }

  return (
    token ?
      <>
        <PublicHeader />

        <div className="container vh-100 mt-5">
          <div className='row d-flex justify-content-center'>
            <div className='col-xl-8'>
              <div class="title-area">
                <h1 class="page-title">Payment</h1>
              </div>
              <div className="payment-page payment-box-white ">
                <div className="white-box">
                  <StripeText status={searchParams.get("status")} />
                </div>
                <div className="mt-5">
                  <Button
                    // disabled={this.state.responseSuccess ? false : true}
                    onClick={Proceed}
                    variant="primary"
                    type="submit"
                    className="payment-next default-btn"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
      : <Navigate replace={true} to='/' />
  );
}


const StripeText = ({ status }) => {
  switch (status) {
    case "success":
      return (
        <div>
          <h4 className='text-success'>Thank you!</h4>
          <p>Your payment has been successfully received.</p>
        </div>
      );
    case "return":
      return (
        <div>
          <h4 className='ff-nunito'>You returned from payment</h4>
          <p>Please continue to Packages again.</p>
        </div>
      );
    case "error":
      return (
        <div>
          <h4 className="text-danger">Oh no, your payment has an error</h4>
          <p>Please check your card detail and try again.</p>
        </div>
      );
    case "failed-to-update-user":
      return (
        <div>
          <h4 className="text-danger">Error Occurred</h4>
          <p>System failed to update user.</p>
        </div>
      );
    case "failed-to-save-transaction":
      return (
        <div>
          <h4 className="text-danger">Error Occurred</h4>
          <p>System failed to save transaction.</p>
        </div>
      );
    case "invalid-package":
      return (
        <div>
          <h4 className="text-danger">Invalid-package</h4>
          <p>You have chosen invalid package.</p>
        </div>
      );
    case "invalid-user":
      return (
        <div>
          <h4 className="text-danger">Invalid user</h4>
          <p>You have created an invalid user.</p>
        </div>
      );
    case "payment-failed":
      return (
        <div>
          <h4 className="text-danger">Error Occurred</h4>
          <p>Your payment failed.</p>
        </div>
      );
    case "payment-invalid-request":
      return (
        <div>
          <h4 className="text-danger">Oh, no</h4>
          <p>Your payment request was invalid.</p>
        </div>
      );
    default:
      return <Navigate replace={true} to='/' />
  }
}