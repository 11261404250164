import axios from "axios";
import { BASEURL } from "../../config";
import { GET_CAPTIONS_TAGS, GET_COMMENTS_TAGS, GET_TAGS, GET_TAGS_PAGINATION } from "../types/types";
export const getTags = (data, page, pagination) => async (dispatch) => {
  const res = await axios.post(`graph/tag/getall`, data, {
    params: {
      limit: 50,
      page,
    },
  });
  if (pagination) {
    dispatch({
      type: GET_TAGS_PAGINATION,
      payload: res.data,
    });
  } else {
    dispatch({
      type: GET_TAGS,
      payload: res.data,
    });
  }
};

export const getCommentsTags = (data, page, pagination) => async (dispatch) => {
  const res = await axios.post(`graph/tag/getall`, data, {
    params: {
      limit: 50,
      page,
    },
  });
  if (pagination) {
    dispatch({
      type: GET_TAGS_PAGINATION,
      payload: res.data,
    });
  } else {
    dispatch({
      type: GET_COMMENTS_TAGS,
      payload: res.data,
    });
  }
};

export const getCaptionsTags = (data, page, pagination) => async (dispatch) => {
  const res = await axios.post(`graph/tag/getall`, data, {
    params: {
      limit: 50,
      page,
    },
  });
  if (pagination) {
    dispatch({
      type: GET_TAGS_PAGINATION,
      payload: res.data,
    });
  } else {
    dispatch({
      type: GET_CAPTIONS_TAGS,
      payload: res.data,
    });
  }
};



export const createTags = () => async (dispatch) => {
return axios({
    method: "POST",
    url: `graph/tag/create`,
    // headers: {
    // 	Accept: "application/json",
    // },
})
    .then((response) => {
        return response
    })
    .catch((error) => {
        alert(JSON.stringify(error.response))
    });
};


//   const res = axios.post(`graph/tag/create`,{
//});
//   return res;
// };

export const refreshTags = () => async (dispatch) => {
  const res = axios.post(`graph/tag/refresh`,{},{});
  return res;
};
