import React from 'react';
import { StyledWrapper, StyledAudioGroup, StyledVideoGroup } from './Styled';
import {
  PreviewVideo,
  Label,
  Select,
  useAudioVideo,
  useLocalVideo,
  useMeetingStatus,
} from 'amazon-chime-sdk-component-library-react';
import MicrophoneDevices from './MicrophoneDevices';
import SpeakerDevices from './SpeakerDevices.js';
import CameraDevices from './CameraDevices.js';

const DeviceSelection = ({ ratio, setRatio, forSetting, host }) => {
  const { setIsVideoEnabled } = useLocalVideo();

  function toggle() {
    setIsVideoEnabled(true);
  }

  return (
    // <div style={{width:'60%'}}>
    <div className="w-100">
      {/* <Label style={{ display: 'block', marginBottom: '.5rem' }}>
        Video preview
      </Label> */}

      <div className="row">
        <div className="col-xl-8">
          <div
            className={
              ratio.w == 6
                ? 'ratio-6-preview-main'
                : ratio.w == 8
                  ? 'ratio-8-preview-main'
                  : 'ratio-16-preview-main'
            }
          >
            <div
              className={
                ratio.w == 6
                  ? 'ratio-6-preview'
                  : ratio.w == 8
                    ? 'ratio-8-preview'
                    : 'ratio-16-preview'
              }
            >
              <PreviewVideo />
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <StyledWrapper>
            {/* <StyledAudioGroup> */}
            <MicrophoneDevices />
            <SpeakerDevices />
            {/* </StyledAudioGroup> */}

            {/* <StyledVideoGroup> */}
            <CameraDevices />
            {/* </StyledVideoGroup> */}
            {host && <>
              <Label>Aspect Ratio</Label>
              <Select
                value={JSON.stringify(ratio)}
                options={[
                  {
                    value: JSON.stringify({ w: 16, h: 9 }),
                    label: '16:9',
                  },
                  {
                    value: JSON.stringify({ w: 8, h: 9 }),
                    label: '8:9',
                  },
                  {
                    value: JSON.stringify({ w: 6, h: 9 }),
                    label: '6:9',
                  },
                ]}
                aria-label="basic select input"
                onChange={(e) => {
                  const data = JSON.parse(e.target.value);
                  setRatio(data);
                  if (forSetting) setIsVideoEnabled(false)
                  // toggle();
                }}
              />
            </>
            }
          </StyledWrapper>
        </div>
      </div>

    </div>
  );
};

export default DeviceSelection;
