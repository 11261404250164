import { GET_USERPROFILE } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getUserProfile = (data) => async (dispatch) => {
    const res = await axios.post(`graph/monitorProfile/getUserProfile`, data);
     return dispatch({
        type: GET_USERPROFILE,
        payload: res.data,
      });
    
  };


