import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import AsyncProduct from "../scheduleEvent/asyncProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postEvent } from "../../../redux/actions/event.action";
import {
  faAngleLeft,
  faCalendarPlus,
  faCaretRight,
  faShare,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import {
  Input,
  Form,
  Select,
  DatePicker,
  TimePicker,
  Button,
  Tabs,
  Affix,
  Space,
  Upload,
  notification,
} from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

const { TabPane } = Tabs;

const { Option } = Select;
const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function LiveEvent() {
  const [dateTime, setDate] = useState("");
  const [sku, setSku] = useState([]);
  const [banner, setBanner] = useState([]);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const [spinner, setSpin] = useState(false);
  const { creatEvent } = useSelector((state) => {
    return state;
  });

  useEffect(() => {}, []);

  const onFinish = (val) => {
    let formData = new FormData();
    formData.append("image", banner);
    formData.append("products", JSON.stringify(sku));
    formData.append("title", val.event);
    formData.append("start_date", dateTime);

    dispatch(postEvent(formData)).then((res) => {
      if (res.payload.success) {
        notification.success({
          message: res.payload?.message,
          className: "toast-success",
        });
      } else {
        notification.error({
          message: res.payload?.data?.message,
          className: "toast-error",
        });
      }
      // if (res.payload.success) {

      // } else {
      //   setError("User does not exist");

      // }
    });
  };

  const getSku = (skuData) => {
    setSku(skuData);
  };

  const range = (start, end) => {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }

    return result;
  }; // eslint-disable-next-line arrow-body-style

  const disabledDate = (current) => {
    // Can not select days before today and today
    // return current && current < moment().endOf('day');
  };

  const disabledDateTime = () => ({
    // disabledHours: () => range(0, 24).splice(4, 20),
    // disabledMinutes: () => range(30, 60),
    // disabledSeconds: () => [55, 56],
  });

  const onDateTimeChange = (data, string) => {
    setDate(string);
  };
  const normFile = (e) => {
    setBanner(e.file.originFileObj);

    // if (Array.isArray(e)) {
    //   return e;
    // }

    // return e?.fileList;
  };

  return (
    <>
      <div className="create-event-wrapper  mb-30">
        <div className="create-event-box-left full-width">
          <h1 className="hd1">Create Event</h1>
          <Form
            layout="vertical"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row d-flex mb-60">
              <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Event Information</h5>
                <p>
                  {" "}
                  Make a bold statement with your introduction line ! The event
                  title will appear on your tab, your social share and the
                  homescreen name. So stand out, make your entrance to the world
                  😃 😃{" "}
                </p>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                <Form.Item
                  name="event"
                  labelCol={{ span: 24 }}
                  label="Event title"
                >
                  <Input
                    placeholder="e.g. MKG1402 -LIVE- Winter 2020 Clearance"
                    size="large"
                    showCount
                    maxLength={80}
                    // onChange={onChange}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row d-flex mb-60">
              <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Date and Time</h5>
                <p> Set the date and time of the event.</p>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-main">
                <Space direction="vertical" size={12}>
                  <Form.Item name="datetime">
                    <DatePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      disabledDate={disabledDate}
                      disabledTime={disabledDateTime}
                      showTime={{
                        defaultValue: moment("00:00:00", "HH:mm:ss"),
                      }}
                      onChange={onDateTimeChange}
                    />
                  </Form.Item>
                </Space>
              </div>
            </div>

            <div className="row d-flex mb-60">
              <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Select Product SKU</h5>
                <p>
                  {" "}
                  Give to a specific host access to the event. This will send an
                  invitation to notify the host.
                </p>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                <Form.Item labelCol={{ span: 24 }} label="Product SKU">
                  <AsyncProduct getSku={getSku} />
                  {/* <Select
                    className="select-style dark-field"
                    defaultValue="Select Product SKU"
                    size="large"
                    focus={false}
                    style={{ width: "50%" }}
                    onChange={handleChange}
                    onBlur={false}
                  >
                    <Option value="Value 1">Value 1</Option>
                    <Option value="Value 2">Value 2</Option>
                    <Option value="Value 3">Value 3</Option>
                  </Select> */}
                </Form.Item>
              </div>
            </div>

            <div className="row d-flex mb-60">
              <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Upload Banner Image</h5>
                <p>
                  {" "}
                  Give to a specific host access to the event. This will send an
                  invitation to notify the host.
                </p>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                <Form.Item label="Upload Banner Image">
                  <Form.Item
                    name="banner"
                    // valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    multiple={false}
                  >
                    <Upload.Dragger
                      name="files"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
              </div>
            </div>

            <div className="d-flex justify-content-md-end">
              <Form.Item>
                <Button
                  className="default-btn d-flex align-items-center justify-content-center"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  // icon={<FontAwesomeIcon size="sm" className="event-icon" icon={faCaretRight} />}
                >
                  Create
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
export default LiveEvent;
