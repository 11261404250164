import React from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
// import { toast } from "react-toastify";
import '../../../css/asyncProduct.scss';
import { BASEURL } from '../../../config';
import { notification } from 'antd';

let arrHost = [];
class AsyncHost4 extends React.Component {
  state = {
    host: this.props.host,
    host2: this.props.host2,
    host3: this.props.host3,
    host4: this.props.host4,
    allHost: '',
    form: this.props.form,
    status: false,
  };

  loadOptions = async (input, callback) => {
    await this.smartSearchFilter(input);

    const result = arrHost.filter((item) => {
      if (item?.label == this.props.host?.[0]?.label || item?.label == this.props.host2?.[0]?.label || item?.label == this.props.host3?.[0]?.label || item?.label == this.props.host4?.[0]?.label) {
    } else {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
      };
    }
    });
    callback(result);
  };

  // componentDidMount(){
  //   if(this.state.form == "edit"){
  //      this.setState({status:true})
  //   }
  // }

  smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(
          `roles/getusers`,
          {
            email: value.trim(),
          },

        )
        .then((response) => {
          const loadHost = [];
          const host = response.data.message;
          if (host.length === 0) {
            // notification.error({
            //   message: "No Host Found",
            //   className: "toast-error",
            // });
          }

          this.setState({ allHost: host });
          host.map((item) => {
              return loadHost.push({
                value: item?.id,
                label: item?.email,
              });
          });

          arrHost = loadHost;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handleMultiSelect = (e) => {
    let data = [e];
    if (data.length <= 1) {
      this.props.getHost4(data);
      this.setState({
        host4: data,
      });
      if (this.props.form === "edit") {
        this.props.flag(true);
      }
    } else {
      let lastRemoved = e.slice(0, -1);
      this.props.getHost4(lastRemoved);
      this.setState({
        getHost4: lastRemoved,
      });
      notification.error({
        message: '1 Host are allowed',
        className: 'toast-error',
      });
    }
  };

  handleOnPaste = async (e) => {
    const getData = e.clipboardData.getData('text');
    await this.smartSearchFilter(getData);
  };
  // formatOptionLabel = ({ label, title, price }) => (
  //   <div style={{ display: "flex" }}>
  //     <div>{label}</div>&nbsp;-&nbsp;
  //     <div style={{ marginLeft: "10px" }}>{title}</div>&nbsp;-&nbsp;
  //     <div style={{ marginLeft: "10px" }}>${price}</div>
  //   </div>
  // );

  render() {
    return (
      <React.Fragment>
        <div onPaste={(e) => this.handleOnPaste(e)}>
          <AsyncSelect
            isMulti={false}
            cacheOptions
            isClearable={true}
            isDisabled={this.state.status}
            defaultOptions
            loadOptions={this.loadOptions}
            placeholder="Enter Host Email"
            name={'host'}
            value={this.state.host4}
            onChange={(e, options) => {
              this.handleMultiSelect(e, options);
            }}
            noOptionsMessage={({ inputValue }) =>
              !inputValue ? 'Search Host' : 'No Host Found'
            }
          // formatOptionLabel={this.formatOptionLabel}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default AsyncHost4;
