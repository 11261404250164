import axios from 'axios';
import { BASEURL } from '../../config';
import { BASIC_PASS_CHANGE } from '../types/types';

export const basicPassChange = (currentPass, newPassword, id) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .put(`users/revise/userpassword/${id}`, ({currentPassword: currentPass, newPassword: newPassword}), {
        headers: { 
            "Content-Type": "multipart/form-data",
         },
      })
      .then((res) => {
        dispatch({
          type: BASIC_PASS_CHANGE,
          payload: res?.data,
        });
        resolve(res.data);
      })
      .catch((error) => {
        reject(error?.response?.data);
       
    });
  });
  return promise;
};
