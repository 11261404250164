import { GET_ORDER_DETAILS, SHOPIFY_CUSTOMER_ORDER, GET_PURCHASER, GET_REFERRER } from "../types/types";
import axios from "axios";

export const customerorderHistory =
  (page, limit, startDate, endDate, gender, category, subcategory, source, customer_id, order, purchasedBy, ref_user, group_by) =>
    (dispatch) => {
      return axios({
        method: "POST",
        url: `brand/reports/getsalesreport?page=${page}&limit=${limit}`,
        // headers: { Authorization: `Bearer ${userInfo?.token}` },
        data: {
          from_date: startDate,
          to_date: endDate,
          gen_category: gender ? gender : "",
          category: category ? category : "",
          sub_category: subcategory ? subcategory : "",
          source: source ? source : "",
          user_id: customer_id ? customer_id : "",
          order: order ? order : "",
          purchasedBy: purchasedBy ? purchasedBy : "",
          ref_user: ref_user ? ref_user: "",
          group_by: group_by ? group_by : ""
        },
      })
        .then((response) => {
          dispatch({
            type: SHOPIFY_CUSTOMER_ORDER,
            payload: response.data,
          });
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    };

export const details = (sales_tracker_id, sales_id) => (dispatch) => {
  return axios({
    method: "POST",
    url: `brand/reports/getsalesdetails`,
    data: sales_tracker_id
      ? {
        sales_tracker_id,
      }
      : { sales_id },
  })
    .then((response) => {
      dispatch({
        type: GET_ORDER_DETAILS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPurchaser = (name) => (dispatch) => {
  return axios({
    method: "POST",
    url: `brand/reports/getcustomer`,
    data: {
      name
    }
  })
    .then((response) => {
      dispatch({
        type: GET_PURCHASER,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getReferrer = (name) => (dispatch) => {
  return axios({
    method: "POST",
    url: `brand/reports/getreferer`,
    data: {
      name
    }
  })
    .then((response) => {
      dispatch({
        type: GET_REFERRER,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
