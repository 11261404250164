import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../css/social.scss";
import { Button } from "antd";
import { Modal } from "react-bootstrap";
import GroupIcon from "@mui/icons-material/Group";

import {
  getInstagramUrl,
  getInstagramData,
  updateAccessToken,
  disconnectInstagram,
} from "../../redux/actions/instagram.action";
import { notification } from "antd";
import ConnectToFb from "./ConnectToFb";
import { validateUser } from "../../redux/actions/login.action";
function SocialSetup() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { validate, socialMediaFollowers } = useSelector((state) => { return state })

  const [disconnectModal, setDisconnectModal] = useState(false);
  const [url, setUrl] = useState("");
  const [disconnectLoading, setDisconnectLoading] = useState(false);

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  useEffect(() => {
    if (searchParams.get("code") !== null) {
      dispatch(
        getInstagramData(searchParams.get("code"), validate?.payload?.message?.email)
      ).then((res) => {
        if (res.type == "GET_INSTAGRAM_DATA_SUCCESS") {
          const parseUserInformation = validate?.payload?.message;
          parseUserInformation.username = res?.payload?.data?.username;
          parseUserInformation.access_token = res?.payload?.data?.access_token;
          // const storeUserInformation = JSON.stringify(parseUserInformation);
          // localStorage.setItem("userInfo", storeUserInformation);
          query.searchParams.delete("code");
          window.history.replaceState(null, null, query);
          dispatch(
            updateAccessToken(
              validate?.payload?.message?._id,
              res?.payload?.data?.username,
              res?.payload?.data?.access_token
            )
          ).then((res) => {
            dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
              if (res.success) {
                console.log(res);
              }
            })
            // window.location.reload();
            history({
              pathname: `/account/social`,
            });
          });
        } else {
          console.log(res.payload.data.message, "fail");
          notification.error({
            message: res.payload.data.message,
            className: "toast-error",
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getInstagramUrl()).then((res) => {
      setUrl(res.payload.data);
    });
  }, []);

  const disconnect = () => {
    setDisconnectLoading(true);
    dispatch(disconnectInstagram(validate?.payload?.message?._id)).then((res) => {
      if (res.type == "DISCONNECT_INSTAGRAM_SUCCESS") {
        setDisconnectLoading(false);
        setDisconnectModal(false);
        dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
          if (res.success) {
            console.log(res);
          }
        })
        // userInfo.access_token = "";
        // userInfo.username = "";
        // localStorage.setItem("userInfo", JSON.stringify(userInfo));
        history({
          pathname: `/account/social`,
        });
      } else {
        setDisconnectLoading(false);
        console.log(res.payload.data.message, "fail");
        notification.error({
          message: res.payload.data.message,
          className: "toast-error",
        });
      }
    });
  };
  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + "M";
    if (num >= 1000) return intlFormat(num / 1000) + "k";
    return intlFormat(num);
  }

  return (
    <>
      <div className="container-flui">
        <div className="title-area">
          <h1 className="page-title">Social Setup</h1>
        </div>
        <div className="social-container-main container p-0 m-0">
          <div class="row">
            <div class=" col-md-6 mb-20">
              <div className="social_box_main">
                <div className="social-header-hd-area">
                  <div>
                    <div className="step-hd">Step 1</div>
                  </div>
                  <div>
                    {validate?.payload?.message?.instagram_username !== "" ? (
                      <div className="connection-status-badge-green">
                        Connected
                      </div>
                    ) : (
                      <div className="connection-status-badge-red">
                        Not Connected
                      </div>
                    )}
                  </div>
                </div>

                <div className="social-setup-hd-area">
                  <div className="followers-box-main">
                    <div className="followers-txt">Instagram Connection:</div>
                    <div className="followers-box">
                      <div className="followers-icon">
                        <GroupIcon sx={{ color: "gray" }} />
                      </div>
                      <div className="followers-count">
                        {socialMediaFollowers?.payload?.message?.followers_count
                          ? numberFormat(socialMediaFollowers?.payload?.message?.followers_count)
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dp_fields-setup mb-3">
                  <div className="category_count">Connection Status</div>
                </div>
                <div className="dp_fields-setup">
                  {validate?.payload?.message?.instagram_username !== "" ? (
                    <>
                      <div className="connected-text text-left mb-2">
                        Connected: @{validate?.payload?.message?.instagram_username}
                      </div>

                      <Button
                        className="default-btn medium-btn w-100 m-0 padb-3"
                        // type="primary"
                        // size="medium"
                        onClick={() => setDisconnectModal(true)}
                      >
                        <i className="fa fa-instagram" />
                        &nbsp;&nbsp; Disconnect Instagram
                      </Button>
                    </>
                  ) : (
                    <div className="dp_fields-setup">
                      <div className="connected-text text-left mb-2 text-danger">
                        Not Connected
                      </div>
                      <Button
                        onClick={() => {
                          window.location.replace(url);
                        }}
                        variant="primary"
                        className="default-btn medium-btn w-100"
                      >
                        <i className="fa fa-instagram" />
                        &nbsp;&nbsp; Connect Instagram
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-20">
              <div className="social_box_main">
                <ConnectToFb
                  username={validate?.payload?.message?.instagram_username}
                  userId={validate?.payload?.message?._id}
                  accessToken={validate?.payload?.message?.fb_page_access_token}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        maskClosable={false}
        show={disconnectModal}
        onHide={() => setDisconnectModal(false)}
        className="modal-instagram"
        centered
      >
        <Modal.Header className="modal-header-style2" closeButton>
          <Modal.Title className="mb-0">Disconnect Instagram</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          Are you sure you want to disconnect
          <span className="strong"> @{validate?.payload?.message?.instagram_username}</span> account from
          Konnect.bio?
        </Modal.Body>
        <Modal.Footer>
          <Button
            // className="disconnect-btn btn-danger"
            className="default-btn"
            onClick={() => {
              disconnect();
            }}
            disabled={disconnectLoading}
          >
            Yes
          </Button>
          <Button
            onClick={() => setDisconnectModal(false)}
            className="default-btn outline"
          >
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default SocialSetup;
