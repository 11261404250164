import { Spin } from "antd";

function LoadingSpin() {
    return (
        <div className="loading-wrap">
        <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span>
      </div>
    );
  }
  
  export default LoadingSpin;