import React from "react";
import { Link, useLocation } from 'react-router-dom'; 
// import { createBrowserHistory } from "history";
// export const history = createBrowserHistory({
//   forceRefresh: true,
// });

function ConnectFb() {
  return (
    <div className="container-fluid">
      <div class="coming_iner">
        <h2>Connect To Facebook</h2>
        {/* <p className="text-muted">  
          {userInfo?.package?.package_id === "61c02d43f40bec74fac2c9a0"
            ? "This option is only available for Influencer Plus."
            : "This option is only available for Brand."}
        </p> */}
       <div className="buttons-area">
              <Link
                to="/account/social"
                className="default-btn medium-btn fltr-hpr fw-normal"
              >
                Connect To Facebook
              </Link>
            </div>
      </div>
    </div>
  );
}

export default ConnectFb;
