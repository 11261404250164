import { ExclamationCircleOutlined, LoadingOutlined, EditOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import {
    Button, DatePicker, Dropdown, Form, Input, Menu,
    notification, Select, Spin, Statistic, Empty
} from "antd";
import Table from "react-bootstrap/Table";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStepsForm } from "sunflower-antd";
import numeral from "numeral";
import {
    deleteEvent,
    deleteEventRecord, getBrandPublishedEvents, getBrandRecordedEvents, getEvents, publishEvent, getShowRecordedEvents, getShowPublishedEvents, publishShow, deleteShow
} from "../../redux/actions/event.action";

import { detailsReviewSec, reviewOrders } from "../../redux/actions/influencerReview";

import {
    faAngleLeft,
    faAngleRight, faCalendarDays,
    faClock, faFlask, faPlay, faShareNodes, faUser, faUserTie, faVideo,
    faXmark, faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FacebookOutlined } from "@mui/icons-material";
import moment from "moment";
import ReactPaginate from "react-paginate";

import LoadingSpin from '../../reUsable/loadingSpin';
import {  details } from "../../redux/actions/purchaseHistory.action";



export default ({ review_id, flag }) => {
    const [sortBy, setSortBy] = useState("date");


    const dispatch = useDispatch();
    const history = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const {
         detailsOrder, detailsReview, orderReview
    } = useSelector((state) => {
        return state;
    });
    console.log("orderReview", orderReview);
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");

    const [startDate, setStartDate] = useState(min_date);
    const [endDate, setEndDate] = useState(max_date);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const [currentPageTable, setCurrentPageTable] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalTable, setModalTable] = useState(false);
    const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);
    const [totalEvent, setTotalEvent] = useState();
    const [order, setOrder] = useState([]);
    const limit = 8;

    const limitPg = 15;
    const upperLimit = (currentPageTable + 1) * limitPg;
    const lowerLimit = upperLimit - limitPg + 1;
    const itemsPerPage = 15;

    const firstRender = useRef(true);
    useEffect(() => {
        setCurrentPageTable(0);
        setTotalEvent()
        setLoading(true)
        if (review_id != '')
            setModalTable(true);
        setOrder(review_id);
        dispatch(
            reviewOrders(
                1, limitPg,
                '',
                review_id?._id
            )).then((res) => {
              setTotalEvent(res?.payload?.message?.total_records);
                setLoading(false)
            })
        // )
        // setTotalEvent(detailsOrder?.payload?.message?.total_records);

    }, [review_id]);


    const TableClose = () => {
        setModalTable(false)
        setOrder();
        setCurrentPageTable(0);
        setTotalEvent();
        flag(false);
    }

    const detailView = (orderDetail) => {
        console.log("orderDetail", orderDetail);
        setModal(true)
        setLoading(true)
        dispatch(details(orderDetail?.sales_tracker_id, orderDetail?._id)).then((res) => {
            setLoading(false)
            console.log("res", res);
            setTotalEvent(res?.payload?.message?.total_records);


        });
    };
    function Summary() {
        return (
          <div className="sales-summery mb-3">
            <div className="row">
              <div className="col-lg-6 col-xl-4 mb-2">
                <div className="summarry-box new-style">
                  <h4 className="summary-hd">Summary</h4>
                  <div className="col-12 summary-detail-box">
                    <h4 className="count-title">Orders</h4>
                    <h3 className="count">
                      {
                        orderReview?.payload?.message?.summary[0]
                          ?.order_count
                      }
                    </h3>
                  </div>
                  <div className="col-12 summary-detail-box">
                    <h4 className="count-title">Total Amount</h4>
                    <h3 className="count">
                      {numeral(
                        orderReview?.payload?.message?.summary[0]
                          ?.order_totalprice
                      ).format("$0,0.0'")}
                    </h3>
                  </div>
                  <div className="col-12 summary-detail-box">
                    <h4 className="count-title">Total Cashback</h4>
                    <h3 className="count">
                      {numeral(
                        orderReview?.payload?.message?.summary[0]
                          ?.cashback_amount
                      ).format("$0,0.0'")}
                    </h3>
                  </div>
                  <div className="col-12 summary-detail-box">
                    <h4 className="count-title">Total Commission</h4>
                    <h3 className="count">
                      {numeral(
                        orderReview?.payload?.message?.summary[0]
                          ?.customer_commission
                      ).format("$0,0.0'")}
                    </h3>
                  </div>
                  <div className="col-12 summary-detail-box">
                    <h4 className="count-title">Total ORME Fee</h4>
                    <h3 className="count">
                      {numeral(
                        orderReview?.payload?.message?.summary[0]?.kb_fee
                      ).format("$0,0.0'")}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    

  
    function DefaultTable() {
        let data = orderReview?.payload?.message?.data;
        if (data) {
          return (
            <>
              <div className="table-responsive scrollbar-style">
                <table className="transactions-box table">
                  <thead className="table_heading">
                    <tr>
                      <th className="td-width-60">S.#</th>
                      <th className="td-width-150">Order Date</th>
                      <th className="td-width-150">Order #</th>
                      <th className="td-width-150">Purchased By</th>
                      <th className="td-width-150 text-right">Amount</th>
                      <th className="td-width-150 text-right">Cashback</th>
                      <th className="td-width-150 text-right">Commission</th>
                      <th className="td-width-150 text-right">ORME Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderReview.loading ? (
                      <div className="antd-loading-ift">
                        <Spin size="large"></Spin>
                      </div>
                    ) : orderReview?.payload?.message?.data.length ===
                      0 ? (
                      <tr>
                        <td colspan="6">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    ) : (
                      data?.map((item, i) => {
                        return (
                          <tr>
                            <td>{i + 1 + currentPage * 15}</td>
                            <td>
                              {" "}
                              {moment(item?.created_date).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <a
                                className="open-modal blue-link"
                                onClick={() => {
                                  detailView(item);
                                }}
                              >
                                {item?.order_id}
                              </a>
                            </td>
                            <td>
                              {item?.event === "productshare"
                                ? "Anonymous"
                                : item?.customer?.name}
                            </td>
                            <td className="text-right">
                              {numeral(item?.order_totalprice).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.cashback_amount).format("$0,0.0'")}
                            </td>
                            <td className="text-right">
                              {numeral(item?.customer_commission).format("$0,0.0'")}
                            </td>
                            <td className="text-right">{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                          </tr>
                        );
                      })
                    )}
    
                    {orderReview?.payload?.message?.data.length > 0 && (
                      <tr>
                        <td></td>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {orderReview?.payload?.message?.data?.length +
                              currentPage * 15}
                          </b>
                        </td>
                        <td></td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.order_totalprice ? b.order_totalprice : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.cashback_amount ? b.cashback_amount : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) +
                                  Number(
                                    b.customer_commission
                                      ? b.customer_commission
                                      : 0.0
                                  )
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
                        <td className="text-right">
                          <b>
                            {numeral(
                              data.reduce((a, b) => {
                                return (
                                  Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0)
                                );
                              }, 0)
                            ).format("$0,0.0'")}
                          </b>
                        </td>
    
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              
              <div className="purchase-data referral-data">
                        {orderReview?.payload?.message?.data?.length ? (
                            <ReactPaginate
                                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                                onPageChange={handlePageClickTable}
                                pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                                pageCount={Math.ceil(totalEvent / limitPg)}
                                forcePage={currentPageTable}
                                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                                pageClassName="page-item d-none"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item d-none"
                                breakLinkClassName="page-link"
                                containerClassName={"pagination custom-paginate"}
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        ) : (
                            ""
                        )}
                    </div>
              {orderReview?.payload?.message?.data?.length > 0 && <Summary />}
            </>
          );
        }
      }

    const handlePageClickTable = (e) => {
        const page = e.selected;
        setCurrentPageTable(page);
        dispatch(
            reviewOrders(
                page + 1, limitPg,
                '',
                order?._id
                // ).then((res) => {
                //     setTotalEvent(res?.payload?.message?.total_records);

                // }
            )
        )
        setTotalEvent(detailsOrder?.payload?.message?.total_records);
    };


    const getMul = (a1, a2) => parseFloat(a1) * parseFloat(a2);
    return (
        <>
      <Modal
      dialogClassName="code-activated-popup"
      size="lg"
      scrollable
      show={modal}
      onHide={() => setModal(false)}
      centered
      backdrop="static"
    >
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Header className="modal-header-style2" closeButton>
        <Modal.Title className="mb-0">Order Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <span className="mt-2 mr-2 loader-center position-relative">
            <Spin size="large" />
          </span>
          // <Spin
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //   }}
          // />
        ) : (
          <>
            <div className="order-detail-title-area">
              <div className="d-flex flex-column">
                <div className="order-num">
                  Order #
                  <div className="order-name">
                    {
                      detailsOrder?.payload?.message?.order_detail
                        ?.order_number
                    }
                  </div>
                </div>
                <duv className="order-num order-name-main">
                  Customer Name:
                  <div className="order-name">
                    {detailsOrder?.payload?.message?.order_detail?.customer
                      ?.first_name +
                      " " +
                      detailsOrder?.payload?.message?.order_detail?.customer
                        ?.last_name}
                  </div>
                </duv>
              </div>

              <span className="order-date">
                {moment
                  .utc(
                    detailsOrder?.payload?.message?.order_detail?.created_at
                  )
                  .format("MMMM Do YYYY [from Online Store]")}
              </span>
            </div>
            <Table responsive size="sm" className="transactions-box">
              <thead className="table_heading">
                <tr>
                  <th className="td-width-60">S.#</th>
                  <th className="td-width-120">Order Date</th>
                  <th className="td-width-100">SKU #</th>
                  <th className="td-width-200">Description</th>
                  <th className="td-width-80">Qty</th>
                  <th className="td-width-100 text-right">Price</th>
                  <th className="td-width-100 text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {!detailsOrder?.loading &&
                  detailsOrder?.payload?.message?.order_detail?.line_items?.map(
                    (item, key) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {moment
                              .utc(order?.created_at)
                              .format("DD-MM-YYYY")}
                          </td>
                          <td>{item?.sku}</td>
                          <td>{item?.title}</td>
                          <td>{item?.quantity}</td>
                          <td className="text-right">{numeral(item?.price).format("$0,0.0'")}</td>
                          <td className="text-right">
                            {numeral(
                              getSum(item?.quantity, item?.price)
                            ).format("$0,0.0'")}
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </Table>
            <div className="order-sum">
              <div className="paid-mid-row">
                <div className="left-txt multi-content">
                  <div className="left-hd">Subtotal</div>
                  <div className="left-info">
                    {
                      detailsOrder?.payload?.message?.order_detail?.line_items
                        ?.length
                    }{" "}
                    item
                  </div>
                </div>
                <div className="right-txt">
                  {numeral(
                    detailsOrder?.payload?.message?.order_detail
                      ?.total_line_items_price
                  ).format("$0,0.0'")}
                </div>
              </div>

              <div className="paid-mid-row">
                <div className="left-txt multi-content">
                  <div className="left-hd">Discount</div>
                  {detailsOrder?.payload?.message?.order_detail
                    ?.total_discounts
                    ? numeral(
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_discounts /
                      detailsOrder?.payload?.message?.order_detail
                        ?.total_line_items_price
                    ).format("0%")
                    : ""}
                </div>
                {detailsOrder?.payload?.message?.order_detail
                  ?.total_discounts ? (
                  <>
                    <div className="right-txt">
                      -
                      {numeral(
                        detailsOrder?.payload?.message?.order_detail
                          ?.total_discounts
                      ).format("$0,0.0'")}
                    </div>
                  </>
                ) : (
                  <div className="right-txt">
                    {numeral(0).format("$0,0.0'")}
                  </div>
                )}
              </div>

              <div className="paid-mid-row">
                <div className="left-txt multi-content">
                  <div className="left-hd">Shipping</div>
                </div>
                <div className="right-txt">
                  {numeral(
                    detailsOrder?.payload?.message?.order_detail
                      ?.total_shipping_price_set?.presentment_money?.amount
                  ).format("$0,0.0'")}
                </div>
              </div>

              <div className="paid-mid-row">
                <div className="left-txt multi-content">
                  <div className="left-hd">Tax</div>
                </div>
                <div className="right-txt">
                  {numeral(
                    detailsOrder?.payload?.message?.order_detail?.total_tax
                  ).format("$0,0.0'")}
                </div>
              </div>
              <div className="paid-mid-row">
                <div className="left-txt multi-content">
                  <div className="left-hd fw-bold">Total Amount</div>
                </div>
                <div className="right-txt fw-bold">
                  {numeral(
                    detailsOrder?.payload?.message?.order_detail?.total_price
                  ).format("$0,0.0'")}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => setModal(false)}
          className="default-btn outline padb-3"
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
          Back
        </Button>
      </Modal.Footer>
    </Modal>

            <Modal
                dialogClassName="code-activated-popup"
                size="lg"
                scrollable
                show={modalTable}
                onHide={() => TableClose(false)}
                centered
            >
                <Modal.Header className="modal-header-style2" >
                    <Modal.Title className="mb-0">Order Detail</Modal.Title>
                </Modal.Header>
                {loading ? <>
                    <Spin
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    /></> :
                    <Modal.Body>

                        <DefaultTable />

                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button
                        onClick={() => TableClose(false)}
                        className="default-btn outline padb-3"
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};