import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { Layout } from "antd";

import { createBrowserHistory } from "history";
import logo from "../../images/ormelogowhite.svg";
import { store } from "../../redux/store";
import { AUTHENTICATE } from "../../redux/types/types";

export const history = createBrowserHistory();
const { Header } = Layout;

export default function MainHeader({ title, mobileSidebar, toggle1 }) {
  const [toggle, setToggle] = useState(toggle1);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("Role");
    store.dispatch({type:AUTHENTICATE, payload: {}})
  };
  return (
    <>
      <Header className="site-layout-background header-main">
        <div className="d-flex">
          <a
            className="d-xl-none trigger"
            onClick={() => {
              setToggle(true);
              mobileSidebar(true);
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </a>

          <div className="header-title">{title}</div>
        </div>

        <div className="logo d-xl-none">
          <Link to="/events">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <Button
          className="logout-btn align-items-center justify-content-center"
          type="button"
          size="medium"
          onClick={() => logout()}
        >
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
          Logout
        </Button>
      </Header>
    </>
  );
}
