// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react';
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';
import { StyledP } from './Styled';
import { useNavigate } from "react-router-dom";


const EndMeetingControl = ({leaveMeeting,endMeeting,host,live,setLeaveMet}) => {
  const history = useNavigate();

  const meetingManager = useMeetingManager();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = ()=> setShowModal(!showModal);


  return (
    <>
      <ControlBarButton 
        icon={<Phone />} 
        onClick={()=> { history({
          pathname: `/events`,
        })}}
        // onClick={toggleModal} 
        label="Leave" 
      />
      {showModal && (
        <Modal maskClosable={false} size="md" onClose={toggleModal} rootId="modal-root">
          <ModalHeader title={host?`Leave event`:"End event"} />
          <ModalBody>
            <StyledP>
            {host ? "Are you sure you want to leave event?" : ("End event for all. The event cannot be used once it ends.")}
            </StyledP>
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              // <ModalButton
              //   onClick={endMeetingForAll}
              //   disabled={host}
              //   variant="primary"
              //   label={loading?"loading...":"End meeting for all"}
              //   closesModal
              // />,
              <ModalButton
                onClick={()=>{
                  setLeaveMet(true)
                  leaveMeeting().then(()=>{
                    history({
                      pathname: `/events`,
                    });
                  })
                }}
                variant="primary"
                label={host?"Leave event":"End event"}
                closesModal
              />,
              <ModalButton variant="secondary" label="Cancel" closesModal />
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default EndMeetingControl;
