import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  notification,
  Statistic,
  Select,
  Form,
} from 'antd';
import '../../css/ugc.scss';

import { Spin, DatePicker } from 'antd';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from 'moment';


import defaultImage from '../../images/user.png';
import EventDetail from '../../pages/dashboard/eventDetail';


const { Option } = Select;

const { Countdown } = Statistic;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const { confirm } = Modal;
const isoStr = new Date().toISOString();

export default ({ className, title, eventStatus }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState('end');
  const [loading, setLoading] = useState(false);
  const fromDate = moment().startOf('month').format('YYYY-MM-DD');
  const toDate = moment(new Date()).format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);



  return (
    <>


      <>
        <div>
          {title === 'event' && <EventDetail />}
        </div>
      </>

     
    </>
  );
};
