import { Spin } from "antd";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { BASEURL } from "../../config";
import { validateUser } from "../../redux/actions/login.action";

export default function SelectPages({
  clear,
  pages,
  setSelectedPage,
  selectedPage,
  checkLoading,
  insta,
  next,
}) {

  const dispatch = useDispatch();
  const { validate } = useSelector((state) => { return state })

  function onReset() {
    axios
      .put(
        `users/revise/resetfacebook/${validate?.payload?.message?._id}`,
        {},
      )
      .then(() => {
        clear(null);
        dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
          if (res.success) {
            console.log(res);
          }
        })
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }
  return (
    <>
      <h5 className="card-title">Select Facebook Page</h5>
      <p className="card-text">
        Please select a page that is connected to instagram.
      </p>
      <div className="mb-3">
        <label>Facebook Pages</label>

        <Select
          options={pages.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })}
          placeholder="Select Page"
          onChange={(event) => setSelectedPage(event.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {checkLoading ? (
          <Spin />
        ) : insta || insta == 0 ? null : (
          <p style={{ color: "red" }}>
            Oops, there is no business instagram account found.
          </p>
        )}
      </div>
      <div className="w-100 justify-content-center">
        <button
          onClick={onReset}
          className="btn btn-primary default-btn small-btn"
        >
          Reset
        </button>
        <button
          onClick={next}
          disabled={insta ? false : true}
          className="btn btn-primary default-btn small-btn"
        >
          Next
        </button>
      </div>
    </>
  );
}
