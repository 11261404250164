import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useStepsForm } from "sunflower-antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getShopifyDetail,
  getProducts,
  postShopify,
} from "../../../redux/actions/shopify.action";
import {
  faRotate,
  faXmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import shopifyLogo from "../../../images/shopify-logo.png";
import oracleLogo from "../../../images/oracle-logo.png";
import magentoLogo from "../../../images/magento-logo.png";
import ordercloudLogo from "../../../images/ordercloud-logo.svg";
import axios from "axios";
import Swal from "sweetalert2";
import {
  notification,
  Select,
  Form,
  Input,
  Spin,
  Button,
  Tabs,
  Modal,
} from "antd";
import { BASEURL } from "../../../config";

const { TabPane } = Tabs;

const { Option } = Select;

function EcommerceSetup() {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState("add");
  const [spinner, setSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState("shopify");
  const [handlerStore, setHandlerStore] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMagento, setIsModalOpenMagento] = useState(false);
  const [isModalOpenCloud, setIsModalOpenCloud] = useState(false);

  const showModal = () => {
    setStore("shopify");
    setIsModalOpen(true);
    // setLoading(true);
    dispatch(getShopifyDetail()).then((res) => {
      if (res.type == "GET_SHOPIFY_DETAIL_SUCCESS") {
        setLoading(false);
        form.setFieldsValue({
          shopUrl: res?.payload?.message?.shopify?.shop_name,
          apiKey: res?.payload?.message?.shopify?.api_key,
          token: res?.payload?.message?.shopify?.password,
          storefrontKey:
            res?.payload?.message?.shopify?.storefront_access_token,
        });
        setFormState("edit");
        // handleClose();
      } else {
      }
    });
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setStore("");
    setHandlerStore("");
    setIsModalOpen(false);
    setIsModalOpenCloud(false);
    setIsModalOpenMagento(false);
  };

  const { getShopifyDetails, validate } = useSelector((state) => {
    return state;
  });
  const [formStore] = Form.useForm();

  const filterSourceType = (e) => {
    setStore(e);
    setHandlerStore(e);
    if (e === "shopify") {
      setIsModalOpen(true);
      setIsModalOpenCloud(false);
      setIsModalOpenMagento(false);
    }
    if (e === "magento") {
      setIsModalOpen(false);
      setIsModalOpenCloud(false);
      setIsModalOpenMagento(true);
    }
    if (e === "ordercloud") {
      setIsModalOpen(false);
      setIsModalOpenMagento(false);
      setIsModalOpenCloud(true);
    }
  };

  const syncData = () => {
    Swal.fire({
      title: `Are You Sure You Want To Sync Products?`,
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#000080",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setSpin(true);
        dispatch(getProducts(validate?.payload?.message?.token))
          .then((res) => {
            console.log(res);
            if (res?.type === "GET_PRODUCT_ERROR") {
              notification.error({
                message: res?.payload?.data?.message,
                className: "toast-error",
              });
            } else {
              notification.success({
                message: res?.payload?.message,
                className: "toast-success",
              });
            }
            setSpin(false);
          })
          .catch((err) => {
            notification.error({
              message: "Sync Failed",
              className: "toast-error",
            });
            console.log(err.response, "err");
          });
      }
    });
  };

  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {
      let data = {
        apiKey: values.apiKey,
        password: values.token,
        shopName: values.shopUrl,
        storefront_access_token: values.storefrontKey,
      };

      dispatch(postShopify(data)).then((res) => {
        if (res.type === "POST_SHOPIFY_SUCCESS") {
          notification.success({
            message: "Successfull",
            className: "toast-success",
          });
          setIsModalOpen(false);
          setLoading(true);
          dispatch(getShopifyDetail()).then((res) => {
            if (res.type == "GET_SHOPIFY_DETAIL_SUCCESS") {
              setLoading(false);
              form.setFieldsValue({
                shopUrl: res?.payload?.message?.shopify?.shop_name,
                apiKey: res?.payload?.message?.shopify?.api_key,
                token: res?.payload?.message?.shopify?.password,
                storefrontKey:
                  res?.payload?.message?.shopify?.storefront_access_token,
              });
              setFormState("edit");
              // handleClose();
            } else {
              if (res.type == "GET_SHOPIFY_DETAIL_ERROR") {
                setLoading(false);
              }
            }
          });
        } else {
          notification.error({
            message: "Failed",
            className: "toast-error",
          });
        }
      });
    },
  });

  useEffect(() => {
    // setLoading(true);
    dispatch(getShopifyDetail()).then((res) => {
      if (res.type == "GET_SHOPIFY_DETAIL_SUCCESS") {
        setLoading(false);
        form.setFieldsValue({
          shopUrl: res?.payload?.message?.shopify?.shop_name,
          apiKey: res?.payload?.message?.shopify?.api_key,
          token: res?.payload?.message?.shopify?.password,
          storefrontKey:
            res?.payload?.message?.shopify?.storefront_access_token,
        });
        setFormState("edit");
        // handleClose();
      } else {
        if (res.type == "GET_SHOPIFY_DETAIL_ERROR") {
          setLoading(false);
        }
      }
    });
  }, []);

  const formList = [
    <div className="form-styling ">
      <div className="row  d-flex align-items-center">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
          >
            <Option value="shopify">Shopify</Option>
            {/* <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option> */}
          </Select>
        </div>

        <div className="col-6 d-flex align-items-center justify-content-end">
          {getShopifyDetails?.payload?.connection ? (
            <Link
              to="#"
              className="default-btn small-btn-width d-inline-flex align-items-center justify-content-center"
              onClick={() => syncData()}
            >
              <FontAwesomeIcon icon={faRotate} />
              {/* <Spin spinning={spinner}> */}

              <span className="nav-text">Sync</span>
              {/* </Spin> */}
            </Link>
          ) : null}
          {spinner && (
            <div class="sync_loading">
              <span className="loading_text">Loading</span>
              &#8230;
            </div>
          )}
          {/* {getShopifyDetails?.payload?.message?.shopify ? (
            <>
              {getShopifyDetails?.payload?.connection ? (
                <span className="connection-status-badge green-status">
                  Connected
                </span>
              ) : (
                <span className="connection-status-badge red-status">
                  Not Connected
                </span>
              )}
            </>
          ) : null} */}
        </div>
      </div>
      <Form.Item
        name="shopUrl"
        labelCol={{ span: 24 }}
        label="Enter Shop Url"
        initialValue={formState === "add" ? null : form.getFieldValue().shopUrl}
        rules={[
          {
            required: true,
            message: "Please Enter Shop Url",
          },
        ]}
      >
        <Input
          placeholder="Enter Shop Url"
          //value={"test.myshopify.com"}
          size="large"
        // onChange={onChange}
        />
      </Form.Item>
      <Form.Item
        name="apiKey"
        labelCol={{ span: 24 }}
        label="Enter API Key"
        initialValue={formState === "add" ? null : form.getFieldValue().apiKey}
        rules={[
          {
            required: true,
            message: "Please Enter API Key",
          },
        ]}
      >
        <Input
          autoComplete="new-password"
          placeholder="Enter API Key"
          //value={"fdc316ef688dfdfae29ddfdf89651f584350"}
          size="large"
        // onChange={onChange}
        />
      </Form.Item>
      <Form.Item
        name="token"
        labelCol={{ span: 24 }}
        label="Enter Admin API Access Token"
        initialValue={formState === "add" ? null : form.getFieldValue().token}
        rules={[
          {
            required: true,
            message: "Please Enter Admin API Access Token",
          },
        ]}
      >
        <Input.Password
          autoComplete="new-password"
          placeholder="Enter Admin API Access Token"
          //value={"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"}
          size="large"
        // onChange={onChange}
        />
      </Form.Item>
      <Form.Item
        name="storefrontKey"
        labelCol={{ span: 24 }}
        label="Enter StoreFront Access Token"
        initialValue={
          formState === "add" ? null : form.getFieldValue().storefrontKey
        }
        rules={[
          {
            required: true,
            message: "Please Enter StoreFront Access Token",
          },
        ]}
      >
        <Input.Password
          placeholder="Enter StoreFront Access Token"
          //value={"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"}
          size="large"
        // onChange={onChange}
        />
      </Form.Item>
      <div className="d-flex justify-content-end">
        <Button
          // to="#"
          className="default-btn d-inline-flex align-items-center justify-content-center"
          onClick={submit}
        >
          <span className="nav-text">Save</span>
        </Button>
        <Button
          className="default-btn outline d-inline-flex align-items-center justify-content-center"
          key="back"
          onClick={handleCancel}
        >
          Exit
        </Button>
      </div>
    </div>,
  ];

  const formOracle = [
    <div layout="vertical">
      <div className="create-event-wrapper  mb-30">
        <div className="create-event-box-left full-width">
          <div className="row d-fle">
            <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch d-flex justify-content-between flex-column">
              <h4 className="rounded">Oracle Setup</h4>
              <div className="text-center d-none d-lg-block">
                <img src={oracleLogo} alt="oracleLogo" />
              </div>
              <div></div>
            </div>
            <div
              className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling"
              style={{ height: 493 }}
            ></div>
          </div>
        </div>
      </div>
    </div>,
  ];

  const formMagento = [
    <div>
      <div className="create-event-wrapper  mb-30">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
          >
            <Option value="shopify">Shopify</Option>
            <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option>
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between flex-column mb-50">
          <div className="text-center d-none d-lg-block">
            <img src={magentoLogo} alt="ordercloudLogo" className="min-w-200" />
          </div>
          <div></div>
        </div>
      </div>
    </div>,
  ];

  const formOrdercloud = [
    <div>
      <div className="create-event-wrapper  mb-30">
        <div className="col-6 mb-20">
          <p className="mbpx-5">Source Type</p>
          <Select
            className="select-style dark-field w-100 "
            placeholder="Select Source"
            size="large"
            focus={false}
            onBlur={false}
            value={store === "" ? null : store}
            // onChange={handleGroupBy}
            onChange={filterSourceType}
          >
            <Option value="shopify">Shopify</Option>
            <Option value="magento">Magento</Option>
            <Option value="ordercloud">OrderCloud</Option>
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between flex-column mb-50">
          <div className="text-center d-none d-lg-block">
            <img
              src={ordercloudLogo}
              alt="ordercloudLogo"
              className="min-w-200"
            />
          </div>
          <div></div>
        </div>
      </div>
    </div>,
  ];

  const storeChange = (value) => {
    setStore(value);
  };

  const exit = () => {
    setStore("");
    formStore.resetFields();
  };

  return (
    <>
      <div class="title-area">
        <h1 class="page-title">Ecommerce Setup</h1>
      </div>

      <div className="row">
        <div className="col-xl-6 stream-box-desktop-width">
          <div className="stream-box-white">
            {loading ? (
              <span className="mt-2 mr-2 loader-center position-relative">
                <Spin size="large" />
              </span>
            ) : getShopifyDetails?.payload?.data?.message ===
              "Connection declined!" ? (
              <div className="col-12 mb-20">
                <p className="mbpx-5">Source Type</p>
                <Select
                  className="select-style dark-field w-100 "
                  placeholder="Select Source"
                  size="large"
                  focus={false}
                  onBlur={false}
                  value={handlerStore === "" ? null : handlerStore}
                  // onChange={handleGroupBy}
                  onChange={filterSourceType}
                >
                  <Option value="shopify">Shopify</Option>
                  {/* <Option value="magento">Magento</Option>
                  <Option value="ordercloud">OrderCloud</Option> */}
                </Select>
              </div>
            ) : (
              <div className="row d-flex align-items-center mb-0">
                <div className="col">
                  <h5 className="mb-0 simulcasting-hd">Shopify</h5>
                </div>
                <div className="col-auto col-auto d-flex  justify-content-end pl-0">
                  {loading ? (
                    <span className="mt-2 mr-2 spin-color position-relative">
                      <Spin size="small" />
                    </span>
                  ) : getShopifyDetails?.payload?.message?.shopify ? (
                    <>
                      {getShopifyDetails?.payload?.connection ? (
                        <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2">
                          Connected
                        </span>
                      ) : (
                        <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                          Not Connected
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="default-btn medium-btn green-btn padb-3 ml-0 text-center w-initial pt-2 red-btn2">
                      Not Connected
                    </span>
                  )}

                  <Button
                    className="default-btn medium-btn padb-3 w-initial"
                    // onClick={showModalFb}
                    onClick={showModal}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </Button>
                </div>
              </div>
            )}
            {/* <div className="row d-flex align-items-center mb-20">
              <div className="col">
                <h5 className="mb-0 simulcasting-hd">Oracle</h5>
              </div>
              <div className="col-auto col-auto d-flex  justify-content-end  pl-0">
                <Button
                  className="default-btn medium-btn padb-3 ml-0"
                  // onClick={showModal}
                >
                  Configure
                </Button>
              </div>
            </div> */}
            {/* <div className="row d-flex align-items-center mb-20">
              <div className="col">
                <h5 className="mb-0 simulcasting-hd">Magento</h5>
              </div>
              <div className="col-auto col-auto d-flex  justify-content-end  pl-0">
                <Button
                  className="default-btn medium-btn padb-3 ml-0"
                  // onClick={showModal}
                >
                  Configure
                </Button>
              </div>
            </div>
            <div className="row d-flex align-items-center mb-0">
              <div className="col">
                <h5 className="mb-0 simulcasting-hd">OrderCloud</h5>
              </div>
              <div className="col-auto col-auto d-flex  justify-content-end  pl-0">
                <Button
                  className="default-btn medium-btn padb-3 ml-0"
                  // onClick={showModal}
                >
                  Configure
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <Form form={formStore}>
        <div className="row">
          <div className="col-xl-3 col-md-6 col-12">
            <Form.Item
              labelCol={{ span: 24 }}
              label="Select Store"
              name="store"
            >
              <Select
                className="select-style dark-field store-select"
                placeholder="Select Store"
                size="large"
                focus={false}
                onBlur={false}
                onChange={storeChange}
                // value={store}
                // defaultValue="shopify"
              >
                <Option value="shopify">Shopify</Option>
                <Option value="oracle">Oracle</Option>
                <Option value="magento">Magento</Option>
                <Option value="ordercloud">orderCloud</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end">
            <Form.Item className="d-flex align-items-end">
              {store && (
                // <div className="col-xl-3 col-md-6 col-12">
                <Button
                  className="default-btn medium-btn fltr-hpr m-0"
                  onClick={() => exit()}
                >
                  Exit
                </Button>
                // </div>
              )}
            </Form.Item>
          </div>
        </div>
      </Form>
      {store === 'shopify' && <Form {...formProps}>{formList[current]}</Form>}
      {store === 'oracle' && <Form {...formProps}>{formOracle[current]}</Form>}
      {store === 'ordercloud' && (
        <Form {...formProps}>{formOrdercloud[current]}</Form>
      )}
      {store === 'magento' && (
        <Form {...formProps}>{formMagento[current]}</Form>
      )} */}
      {/* SHOPIFY MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loading ? (
          <Form {...formProps}>{formList[current]}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* MAGENTO MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpenMagento}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loading ? (
          <Form {...formProps}>{formMagento}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>

      {/* OrderCloud MODE */}
      <Modal
        className="modal-generic modal-700"
        centered
        open={isModalOpenCloud}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      // footer={[
      //   <Button
      //     key="submit"
      //     type="primary"
      //     // className="d-table m-auto"
      //     onClick={handleOk}
      //   >
      //     Save
      //   </Button>,
      //   <Button key="back" onClick={handleCancel}>
      //     Exit
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Enter Details</h2>
        {!loading ? (
          <Form {...formProps}>{formOrdercloud}</Form>
        ) : (
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        )}
      </Modal>
    </>
  );
}
export default EcommerceSetup;
