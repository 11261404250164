import axios from "axios";
import {
  GET_GENERAL_CATEGORIES,
  GET_NEW_CATEGORIES,
  GET_NEW_SUB_CATEGORIES,
  SAVE_NEW_CATEGORIES,
  GET_SAVED_GEN_CATEGORIES,
  GET_SAVED_CATEGORIES,
  GET_SAVED_SUB_CATEGORIES,
} from "../types/types";

export const getGeneralCategories = () => (dispatch) => {
  return axios
    .post("category/generalcategories/receive")
    .then((res) => {
      dispatch({
        type: GET_GENERAL_CATEGORIES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getCategories = (ids) => (dispatch) => {
  return axios
    .post("category/categories/receive", { gen_IDs: ids })
    .then((res) => {
      dispatch({
        type: GET_NEW_CATEGORIES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getSubCategories = (gen_ids, cat_ids) => (dispatch) => {
  return axios
    .post("category/subcategories/receive", {
      gen_IDs: gen_ids,
      cat_IDs: cat_ids,
    })
    .then((res) => {
      dispatch({
        type: GET_NEW_SUB_CATEGORIES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const saveCategories = (gen_ids, cat_ids, sub_cat_ids) => (dispatch) => {
  return axios
    .post("category/reserve", {
      gen_IDs: gen_ids,
      cat_IDs: cat_ids,
      sub_cat_IDs: sub_cat_ids,
    })
    .then((res) => {
      dispatch({
        type: SAVE_NEW_CATEGORIES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getSavedGeneralCategories = () => (dispatch) => {
  return axios
    .get("users/receive/gencategories")
    .then((res) => {
      dispatch({
        type: GET_SAVED_GEN_CATEGORIES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getSavedCategories = (genId) => (dispatch) => {
  return axios
    .get("users/receive/categories", {
      params: {
        genId,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_SAVED_CATEGORIES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getSavedSubCategories = (genId, catId) => (dispatch) => {
  return axios
    .get("users/receive/subcategories", {
      params: {
        genId,
        catId: catId === "all" ? "" : catId,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_SAVED_SUB_CATEGORIES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
