import React, { useState } from "react";
import {
  ControlBar,
  AudioInputControl,
  VideoInputControl,
  AudioOutputControl,
  useMeetingManager,
  ControlBarButton,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useLocalVideo,
  Cog,
  LeaveMeeting,
  useAudioVideo,
} from "amazon-chime-sdk-component-library-react";
import { useNavigate } from "react-router-dom";
import { StyledP } from "./EndMeeting/Styled";
import EndMeetingControl from "./EndMeeting/EndMeetingControl";
import { StyledControls } from "./StyleControl";
import { useEffect } from "react";
import DeviceSetup from "./deviceSetup";

const MeetingControls = ({
  leaveMeeting,
  host,
  endMeeting,
  live,
  setLeaveMet,
  ratio,
  setRatio,
  goLive,
  stopLiveEvent,
  onLocalVideo,
}) => {
  const history = useNavigate();
  const { isVideoEnabled, toggleVideo, setIsVideoEnabled } = useLocalVideo();
  const conts = useAudioVideo();

  const [loading, setLoading] = useState(false);

  const meetingManager = useMeetingManager();

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [showSettingModal, setShowSettingModal] = useState(null);
  const [videoState, setVideoState] = useState(false);

  const toggleModal = () => setShowModal(!showModal);
  const toggleModal2 = () => setShowModal2(!showModal2);

  const onSaveSettingModal = () => {
    setIsVideoEnabled(false);
    setShowSettingModal(!showSettingModal);
  };
  const openSettingModal = () => {
    setShowSettingModal(!showSettingModal);
  };

  if (!isVideoEnabled && !videoState) {
    toggleVideo();
  }

  useEffect(() => {
    setTimeout(() => {
      setVideoState(true);
      setTimeout(() => {
        onLocalVideo();
      }, 1000);
    }, 1000);
  }, []);

  useEffect(() => {
    conts?.chooseVideoInputQuality(ratio.w * 100, ratio.h * 100, 15);
  }, [ratio]);

  const settingButtonProps = {
    icon: <Cog />,
    onClick: () => openSettingModal(),
    label: "Setting",
  };

  const endMeetingForAll = async () => {
    try {
      if (endMeeting) {
        setLeaveMet(true);
        setLoading(true);
        endMeeting(() => {
          meetingManager.leave().then(() => {
            setLoading(false);
            history({
              pathname: `/events`,
            });
          });
        }, stopLiveEvent);
      }
    } catch (e) {
      console.log("Could not end meeting", e);
    }
  };
  return (
    <>
      <StyledControls className="controls" active={true}>
        <ControlBar
          className="controls-menu video-controls-main"
          layout="undocked-horizontal"
          showLabels
        >
          <AudioInputControl />
          <VideoInputControl />
          <AudioOutputControl />
          {host && (
            <EndMeetingControl
              setLeaveMet={setLeaveMet}
              live={live}
              host={host}
              endMeeting={endMeeting}
              leaveMeeting={leaveMeeting}
            />
          )}

          {host && <ControlBarButton {...settingButtonProps} />}
          {!host && (
            <>
              {live != "live" ? (
                <div>
                  <button
                    className="btn-sm btn btn-danger hide-mob-text-main golive"
                    onClick={() => goLive()}
                  >
                    <span className="hide-mob-tex">Go Live</span>
                  </button>
                </div>
              ) : null}
              {!host && live != "live" && (
                <div>
                  <button
                    className="btn-sm btn btn-danger ml-2 hide-mob-text-main"
                    onClick={toggleModal2}
                  >
                    <span className="hide-mob-tex">Exit</span>
                  </button>
                </div>
              )}
              {live == "live" && (
                <div>
                  <button
                    className="btn-sm btn btn-danger ml-2 hide-mob-text-main"
                    onClick={toggleModal}
                    disabled={loading}
                  >
                    <span className="hide-mob-text"> {"End Event"}</span>
                    <LeaveMeeting
                      height="1.7em"
                      className="show-mob-btn leave-btn"
                    />
                  </button>
                </div>
              )}
              {showModal && (
                <Modal
                  size="md"
                  maskClosable={false}
                  onClose={toggleModal}
                  rootId="modal-root"
                >
                  <ModalHeader title={host ? `Leave event` : "End event"} />
                  <ModalBody>
                    <StyledP>
                      {host
                        ? "Are you sure you want to leave event?"
                        : "End event for all. The event cannot be used once it ends."}
                    </StyledP>
                  </ModalBody>
                  <ModalButtonGroup
                    primaryButtons={[
                      <ModalButton
                        onClick={() => endMeetingForAll()}
                        variant="primary"
                        label={host ? "Leave event" : "End event"}
                        closesModal
                      />,
                      <ModalButton
                        variant="secondary"
                        label="Cancel"
                        closesModal
                      />,
                    ]}
                  />
                </Modal>
              )}
              {showModal2 && (
                <Modal size="md" onClose={toggleModal2} rootId="modal-root">
                  <ModalHeader title={"Exit Event"} />
                  <ModalBody>
                    <StyledP>Do you want to exit the event?</StyledP>
                  </ModalBody>
                  <ModalButtonGroup
                    primaryButtons={[
                      <ModalButton
                        onClick={() => {
                          endMeeting();
                        }}
                        variant="primary"
                        className="default-btn medium-btn padb-3  fw-normal"
                        label={"Exit event"}
                        closesModal
                      />,
                      <ModalButton
                        variant="secondary"
                        label="Cancel"
                        closesModal
                        className="default-btn medium-btn padb-3 outline  fw-normal"
                      />,
                    ]}
                  />
                </Modal>
              )}
            </>
          )}
        </ControlBar>
      </StyledControls>
      {showSettingModal && (
        <Modal
          maskClosable={false}
          size="lg"
          rootId="modal-root1"
          dismissible={false}
        >
          <ModalHeader title="Device Setting" />
          <ModalBody>
            <DeviceSetup ratio={ratio} setRatio={setRatio} forSetting={true} />
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              <button
                className="default-btn small-btn fw-normal"
                onClick={onSaveSettingModal}
              >
                <span className="hide-mob-text">Save</span>
              </button>,
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default MeetingControls;
