import {
  GET_DASHBOARD
} from '../types/types';
import axios from 'axios';

export const getDashboard = (st_date, end_date) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `brand/reports/dashboard/getdashboard`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      from_date: st_date,
      to_date: end_date,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_DASHBOARD,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};
