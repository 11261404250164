// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import {
  Heading,
  QualitySelection,
  CameraSelection,
} from 'amazon-chime-sdk-component-library-react';

import { title, StyledInputGroup } from './CameraStyle';

const CameraDevices = () => {
  return (
    <div>
      <Heading tag="h2" level={6} css={title}>
        Video Setting
      </Heading>
      <StyledInputGroup>
        <CameraSelection />
      </StyledInputGroup>
      {/* <StyledInputGroup>
        <QualitySelection />
      </StyledInputGroup> */}
    </div>
  );
};

export default CameraDevices;
