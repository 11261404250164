export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR";

export const POST_LOGIN_OPEN_REQUEST = "POST_LOGIN_OPEN_REQUEST";
export const POST_LOGIN_OPEN_SUCCESS = "POST_LOGIN_OPEN_SUCCESS";
export const POST_LOGIN_OPEN_ERROR = "POST_LOGIN_OPEN_ERROR";

export const GET_BROADCAST_CLIENT_REQUEST = "GET_BROADCAST_CLIENT_REQUEST";
export const GET_BROADCAST_CLIENT_SUCCESS = "GET_BROADCAST_CLIENT_SUCCESS";
export const GET_BROADCAST_CLIENT_ERROR = "GET_BROADCAST_CLIENT_ERROR";

export const GET_PARENT_CATEGORIES_REQUEST = "GET_PARENT_CATEGORIES_REQUEST";
export const GET_PARENT_CATEGORIES_SUCCESS = "GET_PARENT_CATEGORIES_SUCCESS";
export const GET_PARENT_CATEGORIES_ERROR = "GET_PARENT_CATEGORIES_ERROR";

export const GET_SUB_CATEGORIES_REQUEST = "GET_SUB_CATEGORIES_REQUEST";
export const GET_SUB_CATEGORIES_SUCCESS = "GET_SUB_CATEGORIES_SUCCESS";
export const GET_SUB_CATEGORIES_ERROR = "GET_SUB_CATEGORIES_ERROR";

export const POST_EVENT_REQUEST = "POST_EVENT_REQUEST";
export const POST_EVENT_SUCCESS = "POST_EVENT_SUCCESS";
export const POST_EVENT_ERROR = "POST_EVENT_ERROR";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const GET_EVENT_ID_REQUEST = "GET_EVENT_ID_REQUEST";
export const GET_EVENT_ID_SUCCESS = "GET_EVENT_ID_SUCCESS";
export const GET_EVENT_ID_ERROR = "GET_EVENT_ID_ERROR";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";

export const POST_GOLIVE_REQUEST = "POST_GOLIVE_REQUEST";
export const POST_GOLIVE_SUCCESS = "POST_GOLIVE_SUCCESS";
export const POST_GOLIVE_ERROR = "POST_GOLIVE_ERROR";

export const POST_STOPLIVE_REQUEST = "POST_STOPLIVE_REQUEST";
export const POST_STOPLIVE_SUCCESS = "POST_STOPLIVE_SUCCESS";
export const POST_STOPLIVE_ERROR = "POST_STOPLIVE_ERROR";

export const SEND_METADATA_REQUEST = "SEND_METADATA_REQUEST";
export const SEND_METADATA_SUCCESS = "SEND_METADATA_SUCCESS";
export const SEND_METADATA_ERROR = "SEND_METADATA_ERROR";

export const GET_METADATA_REQUEST = "GET_METADATA_REQUEST";
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS";
export const GET_METADATA_ERROR = "GET_METADATA_ERROR";

export const UPCOMING_EVENT_REQUEST = "UPCOMING_EVENT_REQUEST";
export const UPCOMING_EVENT_SUCCESS = "UPCOMING_EVENT_SUCCESS";
export const UPCOMING_EVENT_ERROR = "UPCOMING_EVENT_ERROR";

export const SAVE_CONTROL_REQUEST = "SAVE_CONTROL_REQUEST";
export const SAVE_CONTROL_SUCCESS = "SAVE_CONTROL_SUCCESS";
export const SAVE_CONTROL_ERROR = "SAVE_CONTROL_ERROR";

export const GET_CONTROL_REQUEST = "GET_CONTROL_REQUEST";
export const GET_CONTROL_SUCCESS = "GET_CONTROL_SUCCESS";
export const GET_CONTROL_ERROR = "GET_CONTROL_ERROR";

export const GET_VIDEO_REQUEST = "GET_VIDEO_REQUEST";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const GET_VIDEO_ERROR = "GET_VIDEO_ERROR";

export const SAVE_ROLE_REQUEST = "SAVE_ROLE_REQUEST";
export const SAVE_ROLE_SUCCESS = "SAVE_ROLE_SUCCESS";
export const SAVE_ROLE_ERROR = "SAVE_ROLE_ERROR";

export const START_MEETING_REQUEST = "START_MEETING_REQUEST";
export const START_MEETING_SUCCESS = "START_MEETING_SUCCESS";
export const START_MEETING_ERROR = "START_MEETING_ERROR";

export const END_MEETING_REQUEST = "END_MEETING_REQUEST";
export const END_MEETING_SUCCESS = "END_MEETING_SUCCESS";
export const END_MEETING_ERROR = "END_MEETING_ERROR";

export const GET_SHOPIFY_DETAIL_REQUEST = "GET_SHOPIFY_DETAIL_REQUEST";
export const GET_SHOPIFY_DETAIL_SUCCESS = "GET_SHOPIFY_DETAIL_SUCCESS";
export const GET_SHOPIFY_DETAIL_ERROR = "GET_SHOPIFY_DETAIL_ERROR";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const POST_SHOPIFY_REQUEST = "POST_SHOPIFY_REQUEST";
export const POST_SHOPIFY_SUCCESS = "POST_SHOPIFY_SUCCESS";
export const POST_SHOPIFY_ERROR = "POST_SHOPIFY_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const GET_URL_REQUEST = "GET_URL_REQUEST";
export const GET_URL_SUCCESS = "GET_URL_SUCCESS";
export const GET_URL_ERROR = "GET_URL_ERROR";

export const POST_COUNTRY_REQUEST = "POST_COUNTRY_REQUEST";
export const POST_COUNTRY_SUCCESS = "POST_COUNTRY_SUCCESS";
export const POST_COUNTRY_ERROR = "POST_COUNTRY_ERROR";

export const POST_STATE_REQUEST = "POST_STATE_REQUEST";
export const POST_STATE_SUCCESS = "POST_STATE_SUCCESS";
export const POST_STATE_ERROR = "POST_STATE_ERROR";

export const POST_CITY_REQUEST = "POST_CITY_REQUEST";
export const POST_CITY_SUCCESS = "POST_CITY_SUCCESS";
export const POST_CITY_ERROR = "POST_CITY_ERROR";

export const POST_SELECTED_COUNTRY_REQUEST = "POST_SELECTED_COUNTRY_REQUEST";
export const POST_SELECTED_COUNTRY_SUCCESS = "POST_SELECTED_COUNTRY_SUCCESS";
export const POST_SELECTED_COUNTRY_ERROR = "POST_SELECTED_COUNTRY_ERROR";

export const POST_BRAND_REGISTER_REQUEST = "POST_BRAND_REGISTER_REQUEST";
export const POST_BRAND_REGISTER_SUCCESS = "POST_BRAND_REGISTER_SUCCESS,";
export const POST_BRAND_REGISTER_ERROR = "POST_BRAND_REGISTER_ERROR";

export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_ERROR = "GET_REPORT_ERROR";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const GET_CHAT_TOKEN = "GET_CHAT_TOKEN";

export const PUBLISH_EVENT_REQUEST = "PUBLISH_EVENT_REQUEST";
export const PUBLISH_EVENT_SUCCESS = "PUBLISH_EVENT_SUCCESS";
export const PUBLISH_EVENT_ERROR = "PUBLISH_EVENT_ERROR";

export const GET_TAGS = "GET_TAGS";
export const GET_COMMENTS_TAGS = "GET_COMMENTS_TAGS";
export const GET_CAPTIONS_TAGS = "GET_CAPTIONS_TAGS";
export const GET_TAGS_PAGINATION = "GET_TAGS_PAGINATION";

export const GET_HASHTAGS = "GET_HASHTAGS";
export const GET_HASHTAG = "GET_HASHTAG";
export const HASH_PAGINATION = "HASH_PAGINATION";

export const CLEAR_POST = "CLEAR_POST";
export const GET_POST = "GET_POST";
export const UPDATE_POST = "UPDATE_POST";

export const CLEAR_NEW_BIO_POSTS = "CLEAR_NEW_BIO_POSTS";
export const GET_NEW_BIO_POST = "GET_NEW_BIO_POST";

export const GET_LINK = "GET_LINK";

export const GET_MOBILE_DROPDOWN = "GET_MOBILE_DROPDOWN";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_USER_CATEGORIES = "GET_USER_CATEGORIES";
export const GET_USER_CATEGORIES2 = "GET_USER_CATEGORIES2";

export const GET_USER_INFO = "GET_USER_INFO";

export const GET_PROMO_REQUEST = "GET_PROMO_REQUEST";

export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const FILTER_PROFILE_MEDIA = "FILTER_PROFILE_MEDIA";
export const SEARCH_PROFILE = "SEARCH_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const DELETE_PROFILES = "DELETE_PROFILES";

export const GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export const POST_SAVE_CATEGORIES_REQUEST = "POST_SAVE_CATEGORIES_REQUEST";
export const POST_SAVE_CATEGORIES_SUCCESS = "POST_SAVE_CATEGORIES_SUCCESS";
export const POST_SAVE_CATEGORIES_ERROR = "POST_SAVE_CATEGORIES_ERROR";

export const VERIFY_CATEGORIES_REQUEST = "VERIFY_CATEGORIES_REQUEST";
export const VERIFY_CATEGORIES_SUCCESS = "VERIFY_CATEGORIES_SUCCESS";
export const VERIFY_CATEGORIES_ERROR = "VERIFY_CATEGORIES_ERROR";

export const GET_INSTAGRAM_URL_REQUEST = "GET_INSTAGRAM_URL_REQUEST";
export const GET_INSTAGRAM_URL_SUCCESS = "GET_INSTAGRAM_URL_SUCCESS";
export const GET_INSTAGRAM_URL_ERROR = "GET_INSTAGRAM_URL_ERROR";

export const GET_INSTAGRAM_DATA_REQUEST = "GET_INSTAGRAM_DATA_REQUEST";
export const GET_INSTAGRAM_DATA_SUCCESS = "GET_INSTAGRAM_DATA_SUCCESS";
export const GET_INSTAGRAM_DATA_ERROR = "GET_INSTAGRAM_DATA_ERROR";

export const UPDATE_ACCESS_TOKEN_REQUEST = "UPDATE_ACCESS_TOKEN_REQUEST";
export const UPDATE_ACCESS_TOKEN_SUCCESS = "UPDATE_ACCESS_TOKEN_SUCCESS";
export const UPDATE_ACCESS_TOKEN_ERROR = "UPDATE_ACCESS_TOKEN_ERROR";

export const DISCONNECT_INSTAGRAM_REQUEST = "DISCONNECT_INSTAGRAM_REQUEST";
export const DISCONNECT_INSTAGRAM_SUCCESS = "DISCONNECT_INSTAGRAM_SUCCESS";
export const DISCONNECT_INSTAGRAM_ERROR = "DISCONNECT_INSTAGRAM_ERROR";

export const GET_CHAT_REQUEST = "GET_CHAT_REQUEST";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";
export const GET_CHAT_ERROR = "GET_CHAT_ERROR";

export const SAVE_CHAT_REQUEST = "SAVE_CHAT_REQUEST";
export const SAVE_CHAT_SUCCESS = "SAVE_CHAT_SUCCESS";
export const SAVE_CHAT_ERROR = "SAVE_CHAT_ERROR";

export const GET_SOCIAL_STORE_REQUEST = "GET_SOCIAL_STORE_REQUEST";
export const GET_SOCIAL_STORE_SUCCESS = "GET_SOCIAL_STORE_SUCCESS";
export const GET_SOCIAL_STORE_ERROR = "GET_SOCIAL_STORE_ERROR";

export const GET_INSTA_POST = "GET_INSTA_POST";
export const GET_SCHEDULE_POST = "GET_SCHEDULE_POST";

export const GET_MEDIA_SUMMARY = "GET_MEDIA_SUMMARY";

export const GET_REFERRAL_STATS_REQUEST = "GET_REFERRAL_STATS_REQUEST";
export const GET_REFERRAL_STATS_SUCCESS = "GET_REFERRAL_STATS_SUCCESS";
export const GET_REFERRAL_STATS_ERROR = "GET_REFERRAL_STATS_ERROR";

export const REFRESH_PRODUCT_REQUEST = "REFRESH_PRODUCT_REQUEST";
export const REFRESH_PRODUCT_SUCCESS = "REFRESH_PRODUCT_SUCCESS";
export const REFRESH_PRODUCT_ERROR = "REFRESH_PRODUCT_ERROR";

export const POST_STREAM_REQUEST = "POST_STREAM_REQUEST";
export const POST_STREAM_SUCCESS = "POST_STREAM_SUCCESS";
export const POST_STREAM_ERROR = "POST_STREAM_ERROR";

export const GET_STREAM_REQUEST = "GET_STREAM_REQUEST";
export const GET_STREAM_SUCCESS = "GET_STREAM_SUCCESS";
export const GET_STREAM_ERROR = "GET_STREAM_ERROR";

export const DELETE_STREAM_REQUEST = "DELETE_STREAM_REQUEST";
export const DELETE_STREAM_SUCCESS = "DELETE_STREAM_SUCCESS";
export const DELETE_STREAM_ERROR = "DELETE_STREAM_ERROR";

export const EDIT_STREAM_REQUEST = "EDIT_STREAM_REQUEST";
export const EDIT_STREAM_SUCCESS = "EDIT_STREAM_SUCCESS";
export const EDIT_STREAM_ERROR = "EDIT_STREAM_ERROR";

export const GO_LIVE_REQUEST = "GO_LIVE_REQUEST";
export const GO_LIVE_SUCCESS = "GO_LIVE_SUCCESS";
export const GO_LIVE_ERROR = "GO_LIVE_ERROR";

export const GET_AFFILIATE_CONTRACT_DETAIL_REQUEST =
  "GET_AFFILIATE_CONTRACT_DETAIL_REQUEST";
export const GET_AFFILIATE_CONTRACT_DETAIL_SUCCESS =
  "GET_AFFILIATE_CONTRACT_DETAIL_SUCCESS";
export const GET_AFFILIATE_CONTRACT_DETAIL_ERROR =
  "GET_AFFILIATE_CONTRACT_DETAIL_ERROR";




export const BASIC_PROFILE_IMAGE = "BASIC_PROFILE_IMAGE";

export const BASIC_PASS_CHANGE = "BASIC_PASS_CHANGE";

export const PROFILE_BIO = "PROFILE_BIO";

export const GET_AFFILIATE_CARDS = "GET_AFFILIATE_CARDS";
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const GET_BALANCE_REQUEST = "GET_BALANCE_REQUEST";

export const POST_INSTAGRAM_MEDIA_REQUEST = "POST_INSTAGRAM_MEDIA_REQUEST";
export const POST_INSTAGRAM_MEDIA_SUCCESS = "POST_INSTAGRAM_MEDIA_SUCCESS";
export const POST_INSTAGRAM_MEDIA_ERROR = "POST_INSTAGRAM_MEDIA_ERROR";

export const GET_STREAM_SETTING_REQUEST = "GET_STREAM_SETTING_REQUEST";
export const GET_STREAM_SETTING_SUCCESS = "GET_STREAM_SETTING_SUCCESS";
export const GET_STREAM_SETTING_ERROR = "GET_STREAM_SETTING_ERROR";

export const POST_STREAM_SETTING_REQUEST = "POST_STREAM_SETTING_REQUEST";
export const POST_STREAM_SETTING_SUCCESS = "POST_STREAM_SETTING_SUCCESS";
export const POST_STREAM_SETTING_ERROR = "POST_STREAM_SETTING_ERROR";

export const HOST_EVENT_REQUEST = "HOST_EVENT_REQUEST";
export const HOST_EVENT_SUCCESS = "HOST_EVENT_SUCCESS";
export const HOST_EVENT_ERROR = "HOST_EVENT_ERROR";

export const ACCOUNT_DELETE_REQUEST = "ACCOUNT_DELETE_REQUEST";
export const ACCOUNT_DELETE_SUCCESS = "ACCOUNT_DELETE_SUCCESS";
export const ACCOUNT_DELETE_ERROR = "ACCOUNT_DELETE_ERROR";

export const GET_INFLUENCER_VIDEO_POST = "GET_INFLUENCER_VIDEO_POST";

export const LINK_INFLUENCER_PRODUCT_REQUEST =
  "LINK_INFLUENCER_PRODUCT_REQUEST";
export const LINK_INFLUENCER_PRODUCT_SUCCESS =
  "LINK_INFLUENCER_PRODUCT_SUCCESS";
export const LINK_INFLUENCER_PRODUCT_ERROR = "LINK_INFLUENCER_PRODUCT_ERROR";

export const GET_REVIEW_CONTROL_POST = "GET_REVIEW_CONTROL_POST";

export const GET_BRAND_RECORD_EVENTS_REQUEST =
  "GET_BRAND_RECORD_EVENTS_REQUEST";
export const GET_BRAND_RECORD_EVENTS_SUCCESS =
  "GET_BRAND_RECORD_EVENTS_SUCCESS";
export const GET_BRAND_RECORD_EVENTS_ERROR = "GET_BRAND_RECORD_EVENTS_ERROR";

export const GET_BRAND_PUBLISH_EVENTS_REQUEST =
  "GET_BRAND_PUBLISH_EVENTS_REQUEST";
export const GET_BRAND_PUBLISH_EVENTS_SUCCESS =
  "GET_BRAND_PUBLISH_EVENTS_SUCCESS";
export const GET_BRAND_PUBLISH_EVENTS_ERROR = "GET_BRAND_PUBLISH_EVENTS_ERROR";

export const GET_USERPROFILE = "GET_USERPROFILE";
export const POST_CUSTOMER_REGISTER_REQUEST = "POST_CUSTOMER_REGISTER_REQUEST";
export const POST_CUSTOMER_REGISTER_SUCCESS = "POST_CUSTOMER_REGISTER_SUCCESS";
export const POST_CUSTOMER_REGISTER_ERROR = "POST_CUSTOMER_REGISTER_ERROR";

export const CHECK_INSTAGRAM_USER_REQUEST = "CHECK_INSTAGRAM_USER_REQUEST";
export const CHECK_INSTAGRAM_USER_SUCCESS = "CHECK_INSTAGRAM_USER_SUCCESS";
export const CHECK_INSTAGRAM_USER_ERROR = "CHECK_INSTAGRAM_USER_ERROR";

export const SOCIAL_MEDIA_FOLLOWERS = "SOCIAL_MEDIA_FOLLOWERS";

export const LINK_INFLUENCER_PRODUCT_UPDATE_REQUEST =
  "LINK_INFLUENCER_PRODUCT_UPDATE_REQUEST";
export const LINK_INFLUENCER_PRODUCT_UPDATE_SUCCESS =
  "LINK_INFLUENCER_PRODUCT_UPDATE_SUCCESS";
export const LINK_INFLUENCER_PRODUCT_UPDATE_ERROR =
  "LINK_INFLUENCER_PRODUCT_UPDATE_ERROR";

export const ADD_TO_REVIEW_REQUEST = "ADD_TO_REVIEW_REQUEST";
export const ADD_TO_REVIEW_SUCCESS = "ADD_TO_REVIEW_SUCCESS";
export const ADD_TO_REVIEW_ERROR = "ADD_TO_REVIEW_ERROR";

export const GET_ALL_CREATOR_REQUEST = "GET_ALL_CREATOR_REQUEST";
export const GET_ALL_CREATOR_SUCCESS = "GET_ALL_CREATOR_SUCCESS";
export const GET_ALL_CREATOR_ERROR = "GET_ALL_CREATOR_ERROR";

export const CURRENT_VIEWS = "CURRENT_VIEWS";

export const POST_SHOW_REQUEST = "POST_SHOW_REQUEST"
export const POST_SHOW_SUCCESS = "POST_SHOW_SUCCESS"
export const POST_SHOW_ERROR = "POST_SHOW_ERROR"

export const GET_SHOW_RECORD_EVENTS_REQUEST = "GET_SHOW_RECORD_EVENTS_REQUEST"
export const GET_SHOW_RECORD_EVENTS_SUCCESS = "GET_SHOW_RECORD_EVENTS_SUCCESS"
export const GET_SHOW_RECORD_EVENTS_ERROR = "GET_SHOW_RECORD_EVENTS_ERROR"

export const PUBLISH_SHOW_ERROR = "PUBLISH_SHOW_ERROR"
export const PUBLISH_SHOW_REQUEST = "PUBLISH_SHOW_REQUEST"
export const PUBLISH_SHOW_SUCCESS = "PUBLISH_SHOW_SUCCESS"

export const GET_SHOW_ID_REQUEST = "GET_SHOW_ID_REQUEST"
export const GET_SHOW_ID_SUCCESS = "GET_SHOW_ID_SUCCESS"
export const GET_SHOW_ID_ERROR = "GET_SHOW_ID_ERROR"

export const UPDATE_SHOW_SUCCESS = "UPDATE_SHOW_SUCCESS"
export const UPDATE_SHOW_REQUEST = "UPDATE_SHOW_SUCCESS"
export const UPDATE_SHOW_ERROR = "UPDATE_SHOW_SUCCESS"

export const DELETE_SHOW_REQUEST = "DELETE_SHOW_REQUEST"
export const DELETE_SHOW_SUCCESS = "DELETE_SHOW_SUCCESS"
export const DELETE_SHOW_ERROR = "DELETE_SHOW_ERROR"

export const AUTHENTICATE = "AUTHENTICATE"

export const GET_REFERRAL_SUMMARY = "GET_REFERRAL_SUMMARY"

export const REFERRAL_STATS = "REFERRAL_STATS"

export const GET_MARKET_FEE = "GET_MARKET_FEE"

export const SHOPIFY_CUSTOMER_ORDER = "SHOPIFY_CUSTOMER_ORDER"

export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS"

export const GET_DASHBOARD = "GET_DASHBOARD"

export const REACT_S3 = "REACT_S3"

export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD"

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD"

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD"

export const GET_GENERAL_CATEGORIES = "GET_GENERAL_CATEGORIES"

export const GET_NEW_CATEGORIES = "GET_NEW_CATEGORIES"

export const GET_NEW_SUB_CATEGORIES = "GET_NEW_SUB_CATEGORIES"

export const SAVE_NEW_CATEGORIES = "SAVE_NEW_CATEGORIES"

export const GET_SAVED_GEN_CATEGORIES = "GET_SAVED_GEN_CATEGORIES"

export const GET_SAVED_CATEGORIES = "GET_SAVED_CATEGORIES"

export const GET_SAVED_SUB_CATEGORIES = "GET_SAVED_SUB_CATEGORIES"


export const GET_REVIEW_DETAILS_REQUEST ="GET_REVIEW_DETAILS_REQUEST"
export const GET_REVIEW_DETAILS_SUCCESS = "GET_REVIEW_DETAILS_SUCCESS"
export const GET_REVIEW_DETAILS_ERROR = "GET_REVIEW_DETAILS_ERROR"

export const LOCAL_CUSTOMER_ORDER_REQUEST = "LOCAL_CUSTOMER_ORDER_REQUEST"
export const LOCAL_CUSTOMER_ORDER_SUCCESS = "LOCAL_CUSTOMER_ORDER_SUCCESS"
export const LOCAL_CUSTOMER_ORDER_ERROR = "LOCAL_CUSTOMER_ORDER_ERROR"

export const GET_INVENTORY_ACTIVE = "GET_INVENTORY_ACTIVE"

export const GET_INVENTORY_DISABLED = "GET_INVENTORY_DISABLED"

export const GET_INVENTORY_SOLDOUT = "GET_INVENTORY_SOLDOUT"

export const INVENTORY_SELLOUT = "INVENTORY_SELLOUT"

export const INVENTORY_DISABLE = "INVENTORY_DISABLE"

export const INVENTORY_REPLACE = "INVENTORY_REPLACE"

export const INVENTORY_SEARCH_SKU = "INVENTORY_SEARCH_SKU"

export const GET_PURCHASER = "GET_PURCHASER"

export const GET_REFERRER = "GET_REFERRER"

export const GET_INVENTORY_REPLACED = "GET_INVENTORY_REPLACED"

export const POST_CONTACT_REQUEST = "POST_CONTACT_REQUEST";
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";
export const POST_CONTACT_ERROR = "POST_CONTACT_ERROR";