import { Select, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

import UGCGrid from '../../../components/ugcGrid/ugcGrid';
// import GridListing from "../../components/gridListingBar/gridListingBar"
import FB from '../../../components/ConnectFb/connFB';
import { useSelector } from 'react-redux';

const { Option } = Select;
const handleChange = (value) => {
  console.log(`selected ${value}`);
};


const { TabPane } = Tabs;

export default function MonitorMentions() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [eventTitle, setEventTitle] = useState(
    searchParams.get('type') === null ? 'Captions' : searchParams.get('type')
  );
  const [eventStatus, setEventStatus] = useState(123);
  const {validate} = useSelector((state) => {return state})


  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set('type', key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  return (
    <>

      {validate?.payload?.message?.fb_token === '' && validate?.payload?.message?.fb_page_token === '' ?
        <FB />
        :
        <>

          <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center mb-30">
            <div className="grid-listing-left flex-grow-1">
              {/* <h1 className="hd2">All Events</h1> */}

              {/* <Select
            className="select-style"
            defaultValue="Events"
            size="large"
            focus="false"
            onChange={handleChange}
            onBlur={false}
          >
            { <Option value="All">All</Option> }
            <Option value="Events">Events</Option>
            <Option value="Recorded Events">Recorded Events</Option>
          </Select> */}

              <Tabs
                // defaultActiveKey="Upcoming"
                defaultActiveKey={eventTitle}
                // onChange={onChange}
                className="events-tabs-main"
                onTabClick={callbackTabClicked}
              >
                <TabPane tab="Captions" key="Captions"></TabPane>
                <TabPane tab="HashTags" key="HashTags"></TabPane>
                <TabPane tab="Tags" key="Tags"></TabPane>
                <TabPane tab="Comments" key="Comments"></TabPane>

                {/* <TabPane tab="Expired" key="Expired">
            </TabPane> */}
              </Tabs>
            </div>

          </div>

          {/* <div className="title-area">
        <h1>{eventTitle}</h1>
      </div> */}

          <UGCGrid
            className={gridActive}
            title={eventTitle}
            eventStatus={eventStatus}
          />
        </>
      }
    </>
  );
}
