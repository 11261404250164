import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
// import { useNavigate } from 'react-router-dom';
import SelectPages from "./SelectPages";
import { BASEURL } from "../../config";
import { notification } from "antd";
import { createBrowserHistory } from "history";
import GroupIcon from "@mui/icons-material/Group";
import { useDispatch, useSelector } from "react-redux";
import { validateUser } from "../../redux/actions/login.action";

export const history = createBrowserHistory({
  forceRefresh: true,
});

function ConnectToFb({ username, setFbPageLocal, userId, accessToken }) {

  // const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [checkLoading, setCheckLoading] = useState(false);
  const [insta, setInsta] = useState(0);
  const [complete, setComplete] = useState(false);
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const { validate } = useSelector((state) => { return state })
  const axiosHeader = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    const token = validate?.payload?.message?.fb_token
    const fbPage = validate?.payload?.message?.fb_page_token;
    if (token) {
      setFacebookUserAccessToken(token);
      if (fbPage) {
        setSelectedPage(fbPage);
        setComplete(true);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const fbLogin = () => {
    const secret = "73a28a599afc60d1d82b2c7e0c15ef9b";
    const client = "916894299469996";
    const url = "https://get.konnect.bio";
    window.FB.login(
      (response) => {
        if (response.authResponse?.accessToken) {
          fetch(`https://graph.facebook.com/v12.0/oauth/access_token?  
                        grant_type=fb_exchange_token&          
                        client_id=${client}&
                        client_secret=${secret}&
                        fb_exchange_token=${response.authResponse?.accessToken}`)
            .then((res) => res.json())
            .then((json) => {
              setFacebookUserAccessToken(json.access_token);
              // localStorage.setItem('fbToken', json.access_token)
              axios
                .post(
                  `${BASEURL}social/fb/token/user`,
                  {
                    fbToken: json.access_token,
                  },
                  axiosHeader
                )
                .then((res) => {
                  var userInfo = validate?.payload?.message;
                  userInfo.fb_token = json.access_token;
                  dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
                    if (res.success) {
                      console.log(res);
                    }
                  })
                  // localStorage.setItem("userInfo", JSON.stringify(userInfo));
                });
            });
        }
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope:
          "pages_show_list, pages_manage_posts, instagram_content_publish, pages_read_engagement, instagram_basic, instagram_manage_comments, instagram_manage_insights, pages_manage_metadata",
      }
    );
  };

  const getInstagramAccountId = (facebookPageId) => {
    setCheckLoading(true);
    axios
      .get(
        `https://graph.facebook.com/v12.0/${facebookPageId}?fields=instagram_business_account&access_token=${facebookUserAccessToken}`,
        axiosHeader
      )
      .then((res) => {
        setInsta(res.data.instagram_business_account?.id);
        setCheckLoading(false);
      });
  };
  const getFacebookPages = () => {
    axios
      .get(
        `https://graph.facebook.com/v12.0/me/accounts?access_token=${facebookUserAccessToken}`,
        axiosHeader
      )
      .then((res) => {
        setPages(res.data.data);
      });
  };
  const pagesMemo = useMemo(() => {
    if (facebookUserAccessToken) {
      getFacebookPages();
    }
  }, [facebookUserAccessToken]);

  const idMemo = useMemo(() => {
    if (selectedPage) {
      getInstagramAccountId(selectedPage);
    }
  }, [selectedPage]);

  const refreshToken = () => {
    axios
      .get(`${BASEURL}social/fb/refreshfacebooktoken`, axiosHeader)
      .then((res) => {
        notification.success({
          message: res?.data?.message,
          className: "toast-success",
        });
      });
  };

  const logOutOfFB = () => {
    axios
      .put(`${BASEURL}users/revise/resetfacebook/${userId}`, {}, axiosHeader)
      .then((response) => {
        // let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        // userInfo.page_token = "";
        // userInfo.fb_token = "";
        // localStorage.setItem("userInfo", JSON.stringify(userInfo));
        dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
          if (res.success) {
            console.log(res);
          }
        })
        setModal(false);
        history.push("/account/social");
        history.go(0);
      });
  };
  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
}
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + "M";
    if (num >= 1000) return intlFormat(num / 1000) + "k";
    return intlFormat(num);
  }

  return (
    <>
      <div className="social-header-hd-area">
        <div>
          <div className="step-hd">Step 2</div>
        </div>
        <div>
          {facebookUserAccessToken && complete ? (
            <div className="connection-status-badge-green">Connected</div>
          ) : (
            <div className="connection-status-badge-red">Not Connected</div>
          )}
        </div>
      </div>
      <div className="social-setup-hd-area">
        <div className="followers-box-main">
          <div className="followers-txt"> Facebook Connection:</div>
          <div className="followers-box">
            <div className="followers-icon">
              <GroupIcon sx={{ color: "gray" }} />
            </div>
            <div className="followers-count">{numberFormat(45)}</div>
          </div>
        </div>
      </div>

      <div class="dp_fields-setup mb-3">
        <div class="category_count">Connection Status</div>
      </div>
      <div class="dp_fields-setup">
        {loading ? (
          <p>...Loading</p>
        ) : facebookUserAccessToken ? (
          !complete ? (
            <SelectPages
              selectedPage={selectedPage}
              setSelectedPage={(pageId) => {
                setSelectedPage(pageId);
              }}
              pages={pages}
              insta={insta}
              clear={setFacebookUserAccessToken}
              next={() => {
                setComplete(true);
                axios
                  .post(
                    `${BASEURL}social/fb/token/page`,
                    {
                      fbPage: selectedPage,
                      instaId: insta,
                      fb_page_access_token: facebookUserAccessToken,
                    },
                    axiosHeader
                  )
                  .then(() => {
                    // var obj = localStorage.getItem("userInfo");
                    // var userInfo = JSON.parse(obj);
                    dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
                      if (res.success) {
                        console.log(res);
                      }
                    })
                    // userInfo.page_token = selectedPage;
                    // userInfo.instagram_id = insta;
                    // localStorage.setItem("userInfo", JSON.stringify(userInfo));
                  });
                axios
                  .get(
                    `https://graph.facebook.com/${selectedPage}?fields=access_token&access_token=${facebookUserAccessToken}`,
                    axiosHeader
                  )
                  .then((res) => {
                    axios.post(
                      `https://graph.facebook.com/v12.0/${selectedPage}/subscribed_apps?subscribed_fields=feed&access_token=${res.data.access_token}`,
                      axiosHeader
                    );
                  });
                // localStorage.setItem('fbPage', selectedPage)
                setFbPageLocal(selectedPage);
              }}
              checkLoading={checkLoading}
            />
          ) : (
            <div className="dp_fields-setup">
              <div className="connected-text text-left mb-2">
                Connected{" "}
                {pages.filter((it) => it.id === selectedPage)[0]?.name &&
                  ": " + pages.filter((it) => it.id === selectedPage)[0]?.name}
              </div>
              <Button
                variant="primary"
                className="default-btn medium-btn w-100"
                onClick={() => {
                  setModal(true);
                }}
              >
                {" "}
                <i className="fa fa-facebook" />
                &nbsp;&nbsp; Disconnect Facebook
              </Button>
            </div>
          )
        ) : (
          <>
            <div className="connected-text text-left mb-2 text-danger">
              Not Connected
            </div>
            <Button
              disabled={accessToken !== "" ? false : true}
              onClick={fbLogin}
              variant="primary"
              className="default-btn medium-btn w-100"
            >
              <i className="fa fa-facebook" />
              &nbsp;&nbsp; Connect Facebook
            </Button>
          </>
        )}
      </div>
      <Modal
        maskClosable={false}
        show={modal}
        onHide={() => setModal(false)}
        className="change-password"
        centered
      >
        <Modal.Header className="modal-header-style2" closeButton>
          <Modal.Title className="mb-0">
            Disconnect Facebook and Instagram
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          Are you sure you want to disconnect both facebook and instagram
          accounts?
          <span className="strong"></span> This will remove all your content
          from our platform.
          <p>This action is not reversible.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="default-btn"
            // className="btn-danger"
            onClick={() => {
              logOutOfFB();
            }}
          >
            Yes
          </Button>
          <Button
            className="default-btn outline"
            onClick={() => {
              setModal(false);
            }}
          // variant="outline-primary"
          >
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConnectToFb;
