import {
  GET_MARKET_FEE
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

export const getMarketFee = (st_date, end_date, gender, category, subCategory, earn, customer_id) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `brand/reports/getfeesummary`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      from_date: st_date,
      to_date: end_date,
      gen_category_id: gender ? gender : "",
      category_id: category ? category : "",
      sub_category_id: subCategory ? subCategory : "",
      earning_type: earn,
      user_id: customer_id

    },
  })
    .then((response) => {
      dispatch({
        type: GET_MARKET_FEE,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};
