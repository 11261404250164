import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStepsForm } from 'sunflower-antd';

import AsyncProduct from '../create-event/scheduleEvent/asyncProduct';
import {
    InboxOutlined,
    UploadOutlined,
    PlusOutlined,
    CalendarFilled,
    DeleteFilled
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { postEvent } from '../../redux/actions/event.action';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getParentCategories } from '../../redux/actions/category.action';
import { getRefreshProd } from '../../redux/actions/refreshProducts.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getEventsBy_Id,
    UpdateEvents,
    getShowBy_Id,
    UpdateShows
} from '../../redux/actions/event.action';
import {
    faAngleLeft,
    faCalendarPlus,
    faCaretRight,
    faShare,
    faTriangleExclamation,
    faXmark,
    faVideo,
    faUser,
    faUserTie,
    faEye,
    faAngleRight
} from '@fortawesome/free-solid-svg-icons';
import {
    Steps,
    Input,
    Button,
    Form,
    Result,
    Tabs,
    Space,
    DatePicker,
    TimePicker,
    Upload,
    message,
    notification,
    Select,
    Spin,
    Checkbox,
    Image,
    Affix,
    Modal,
    Table,
    Switch,
    Tag,
} from 'antd';
import moment from 'moment';
import useMediaQuery from '../../reUsable/useMediaQuery';
import { set } from 'lodash';
import LoadingSpin from '../../reUsable/loadingSpin';
import { getInventory, searchActiveSkus, sellOutSku } from '../../redux/actions/inventoryActive.action';
import {
    getSavedCategories,
    getSavedGeneralCategories,
    getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
import ReactPaginate from 'react-paginate';
const type = 'DraggableBodyRow';
var total = 0;

const { Step } = Steps;
const { TabPane } = Tabs;
const { Option } = Select;

export default function Active(props) {
    const location = useLocation();
    const matches = useMediaQuery('(min-width: 1024px)');
    const deadline = '2018-12-20T07:37:50.886Z';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [streamModal, setIsModalOpenStream] = useState(false);
    const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
    const [isCheckedOpen, setIsCheckedOpen] = useState(false);
    const [dataDetails, setDataDetail] = useState([]);
    const [referralPercent, setReferralPercent] = useState("0");
    const [discount, setDiscount] = useState("");
    const [influencer_percent, setInfluencer_percent] = useState("0");
    const [promo, setPromo] = useState("");
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [flag, setFlag] = useState(false);
    const [defaultBanner, setDefaultBanner] = useState(false);
    const [videoRatio, setRatio] = useState("16:9");
    const [fileList, setFileList] = useState([]);
    const [contentType, setContentType] = useState("");
    const [promptModal, setPromptModal] = useState(true);
    const [vidMsg, setVidMsg] = useState(false);
    const [multipleHosts, setMultipleHosts] = useState(false);
    const [recURL, setRecURL] = useState("");
    const [infoTitle, setInfoTitle] = useState("");
    const [infoDesc, setInfoDesc] = useState("");
    const [platform, setPlatform] = useState(false);
    const [streamUrl, setStreamUrl] = useState("local");
    const [externalUrl, setExternalUrl] = useState("");
    const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
    const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
    const [orientation, setOrientation] = useState("");
    const [flagg, setFlagg] = useState(false);
    const [fields, setFields] = useState({
        image: "",
    });
    const [imageThumb, setImageThumb] = useState("");
    const [ext, setExt] = useState({});
    const [sort, setSort] = useState('');
    const [form] = Form.useForm();
    const dateFormat = "YYYY-MM-DD";
    const { RangePicker } = DatePicker;
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");
    const [startDate, setStartDate] = useState(min_date);
    const [endDate, setEndDate] = useState(max_date);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [gender, setGender] = useState("All");
    const [category, setCategory] = useState("All");
    const [subCategory, setSubCategory] = useState("All");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [genLoading, setGenLoading] = useState(true);
    const [actionChange, setActionChange] = useState(false);
    const [skuNewArr, setSkuNewArr] = useState([]);
    const [newData, setNewData] = useState([]);
    const [viewIndex, setViewIndex] = useState();
    const [skuLoad, setSkuLoad] = useState(false);
    const showModal = (s) => {
        setDataDetail(s.variants);
        setIsModalOpen(true);
    };

    const [top, setTop] = useState(30);

    const { TextArea } = Input;
    const dispatch = useDispatch();
    const history = useNavigate();

    const { parentCategory, refreshProd, replacedInventory, savedGeneralCategories,
        savedCategory,
        savedSubCategory, } = useSelector((state) => {
            return state;
        });
    console.log("replacedInventory", replacedInventory);
    const [spinner, setSpin] = useState(true);
    const [dateTime, setDate] = useState('');
    const [formState, setFormState] = useState('add');
    const [sku, setSku] = useState([]);
    const [banner, setBanner] = useState([]);
    const [host, setHost] = useState([]);
    const [hostError, setHostError] = useState(false);
    const [host2, setHost2] = useState([]);
    const [hostError2, setHostError2] = useState(false);
    const [host3, setHost3] = useState([]);
    const [hostError3, setHostError3] = useState(false);
    const [host4, setHost4] = useState([]);
    const [hostError4, setHostError4] = useState(false);
    const [skuError, setSkuError] = useState(false);
    const [parent, setParent] = useState('');
    const [imageUpload, setImageUpload] = useState(false);
    const [sampleBanner, setSampleBanner] = useState(false);
    const [note, setEventNote] = useState('');
    const [catgId, setCategoryId] = useState('');
    const [thankyou, setEventThankyou] = useState('');
    const [data, setData] = useState([]);
    const [page, setPage] = React.useState(1);
    const [previewImage, setPreviewImage] = useState('');
    const [eventTitle, setEventTitle] = useState('');
    const [removeSku, setRemoveSku] = useState('');
    const [replace, setReplace] = useState(false);
    const [replaceIndex, setReplaceIndex] = useState("");
    const [skuArr, setSkuArr] = useState([]);
    const [replaceSku, setReplaceSku] = useState([]);
    const [filterSKU, setFilterSKU] = useState();
    const [searchSKUs, setSearchSKUs] = useState([]);
    const [generalLoad, setGeneralLoad] = useState(false);
    const [catLoad, setCatLoad] = useState(false);
    const [subCatLoad, setSubCatLoad] = useState(false);
    const [invSort, setInvSort] = useState("asc");
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleReplace = () => {
        if (replaceSku.length != 0) {
            setReplace(false)
            notification.success({
                message: 'SKU Product Replaced Successfully',
                className: 'toast-success',
            });
            let prevArr = data;
            prevArr.push(replaceSku);
            Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
            };
            prevArr.move(replaceIndex, prevArr.length - 1);
            prevArr.move(prevArr.length - 2, replaceIndex);
            setData(prevArr)
            setSkuArr([])
            setReplaceSku([])
        } else {
            notification.error({
                message: 'No SKU Product Selected',
                className: 'toast-error',
            });
        }
    };

    useEffect(() => {
        let abc = [];
        let newData = replacedInventory?.message?.[0]?.data?.map((item) => {
            return (
                item?.productOpt?.map((varnt, ind) => {
                    return ({
                        title: varnt?.name,
                        key: 'option' + (ind + 1),
                        dataIndex: ['option' + (ind + 1)],
                        width: 40,
                        render: (text, record, index) => {
                            return (
                                <div className=''>
                                    {ind === 0 && (record?.option1 ? record?.option1 : '-')}
                                    {ind === 1 && (record?.option2 ? record?.option2 : '-')}
                                    {ind === 2 && (record?.option3 ? record?.option3 : '-')}
                                    {ind === 3 && (record?.option4 ? record?.option4 : '-')}
                                    {ind === 4 && (record?.option5 ? record?.option5 : '-')}
                                    {ind === 5 && (record?.option6 ? record?.option6 : '-')}
                                </div>
                            );
                        },
                    })
                })
            )
        })

        newData?.forEach((stocks, index) => {
            newData[index]?.push({
                title: 'Stock',
                dataIndex: 'inventory_quantity',
                key: 'inventory_quantity',
                width: 60
            })
        })
        setSkuNewArr(newData)

        let latestData = replacedInventory?.message?.[0]?.data?.filter((items) => {
            let inv = items?.variants?.reduce((acc, item) => {
                return (
                    acc = acc + item.inventory_quantity
                )
            }, 0)
            if (inv < 1) {
                dispatch(sellOutSku(items?._id)).then((res) => {
                    console.log("res", res);
                })
                return items
            } else {
                return inv
            }
        })
        console.log("latestData", latestData);
        setNewData(latestData)
    }, [replacedInventory])
    console.log("replacedInventory", replacedInventory);
    const handleCancel = () => {
        setIsModalOpen(false);
        setReplace(false)
    };

    const params = useParams();
    const id = params.id;

    const getSum = (total, num) => {
        return total + Math.round(num);
    }
    const onToggle = (record, recInd) => {
        setActionChange(true)
        if (record?.is_active == true) {

            record.is_active = false
            let obj = record;
            const key = { ...obj };
            key.is_active = false

            setData(current =>
                current.map((obj, index) => {
                    if (index == recInd) {
                        return { ...obj, is_active: false };
                    }
                    return obj;
                }),
            );

        }
        else {

            record.is_active = true
            let obj = record;
            const key = { ...obj };
            key.is_active = true


            setData(current =>
                current.map((obj, index) => {
                    if (index == recInd) {
                        return { ...obj, is_active: true };
                    }
                    return obj;
                }),
            );
        }
    };
    useEffect(() => {
        if (replace && removeSku) {
            setSkuArr([])
        }
        else if (removeSku) {
            setData(prevArray => [...prevArray, sku])
            setData(data?.filter(avail => { return avail.ProductSku !== removeSku?.ProductSku }))
        } else if (replace) {
            if (skuArr.length > 0) {
                notification.error({
                    message: 'One SKU Product Already Added',
                    className: 'toast-error',
                });
            } else {
                setSkuArr([replaceSku])
            }
        }
        else if (sku) {
            setData(prevArray => [...prevArray, sku])
        }
    }, [sku, removeSku])

    useEffect(() => {
        if (replace) {
            if (skuArr.length > 0) {
                notification.error({
                    message: 'One SKU Product Already Added',
                    className: 'toast-error',
                });
            } else {
                setSkuArr([replaceSku])
            }
        }
    }, [replaceSku])
    useEffect(() => {
        dispatch(getInventory(pageLimit, currentPage + 1, "replaced", startDate,
            endDate,
            gender,
            category,
            subCategory, filterSKU, invSort)).then(async (event) => {
                if (event?.success) {
                    setSpin(false);
                    if (location?.state?.inventory === "shows") {
                        let orderData = event.payload?.message[0]?.orders;
                        let productsData = event.payload?.message[0]?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)

                        setSampleBanner(
                            event?.payload?.message[0]?.default_banner ? true : false
                        );
                        setDefaultBanner(
                            event?.payload?.message[0]?.default_banner ? true : false
                        );
                        setRecURL(event?.payload?.message?.[0]?.recording_url);
                        setPreviewImage(event?.payload?.message[0]?.banner);
                        setEventThankyou(event.payload.message[0]?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.title);
                        setEventNote(event.payload.message[0]?.notes);
                        setproductPromoCodePromo(event.payload.message[0]?.promo);
                        setProductPromoCodeDscs(event.payload.message[0]?.discount);
                        setOrientation(event.payload.message[0]?.orientation);

                        setRatio(event.payload.message[0]?.ratio);

                        setPlatform(
                            event.payload.message[0]?.is_facebook_live ? true : false
                        );
                        if (event.payload.message[0]?.is_facebook_live) {
                            setInfoTitle(event.payload.message[0]?.facebook?.title);
                            setInfoDesc(event.payload.message[0]?.facebook?.description);
                        }

                        setReferralPercent(
                            event.payload.message[0]?.referral_percent === ""
                                ? "0"
                                : event.payload.message[0]?.referral_percent
                        );
                        setPromo(
                            event.payload.message[0]?.promo === ""
                                ? "0"
                                : event.payload.message[0]?.promo
                        );
                        setInfluencer_percent(
                            event.payload.message[0]?.influencer_percent === ""
                                ? "0"
                                : event.payload.message[0]?.influencer_percent
                        );
                        setDiscount(
                            event.payload.message[0]?.discount === ""
                                ? "0"
                                : event.payload.message[0]?.discount
                        );
                        setMultipleHosts(
                            event.payload.message[0]?.multiple_hosts
                                ? event.payload.message[0]?.multiple_hosts
                                : false
                        );
                        //setStreamUrl(event.payload.message[0]?.stream_type);
                        setStreamUrl("local");

                        setExternalUrl(event.payload.message[0]?.streaming_url);
                        setDate(moment(moment(event.payload.message[0]?.start_date)));
                        setBanner(event?.payload?.message[0]?.banner);

                        setFormState("edit");
                        setLoad(false);
                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item?.category_id == event?.payload?.message?.[0]?.category_id
                        );
                        setParent(parent?.[0]?.category_name);
                    } else {
                        let orderData = event.payload?.message[0]?.event?.orders;
                        let productsData = event.payload?.message[0]?.event?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)
                        setSampleBanner(
                            event.payload?.message[0]?.event?.default_banner ? true : false
                        );

                        setPreviewImage(event.payload?.message[0]?.event?.banner);
                        setEventThankyou(event.payload.message[0]?.event?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.event?.title);
                        setEventNote(event.payload.message[0]?.event?.notes);
                        setDate(moment(moment(event.payload.message[0]?.event?.start_date)));
                        setBanner(event.payload?.message[0]?.event?.banner);

                        let primary_data =
                            (await event.payload?.message[0]?.hosts[1]?.primary) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let primary_host = await primary_data.filter(
                            (num) => num.primary === true
                        );

                        let host2_data =
                            (await event.payload?.message[0]?.hosts[2]?.host1) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_2 = await host2_data.filter((num) => num.host1 === true);

                        let host3_data =
                            (await event.payload?.message[0]?.hosts[3]?.host2) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_3 = await host3_data.filter((num) => num.host2 === true);

                        let host4_data =
                            (await event.payload?.message[0]?.hosts[4]?.host3) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_4 = await host4_data.filter((num) => num.host3 === true);

                        setHost(primary_host);
                        setHost2(host_2);
                        setHost3(host_3);
                        setHost4(host_4);
                        setCategoryId(event.payload?.message[0]?.event?.category_id);

                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item.category_id == event.payload?.message[0]?.event?.category_id
                        );
                        setParent(parent[0]?.category_name);
                    }




                }
            });
    }, [id]);



    const inventoryStatus = (record) => {
        let status = record?.variants?.reduce((acc, item) => {
            return (
                acc = acc + item.inventory_quantity
            )
        }, 0)
        if (status > 0) {
            return false
        } else {
            return true
        }
    }

    const getSku = (skuData, removedSku) => {

        setRemoveSku(removedSku)


        var matchedSku = null;
        skuData.forEach(element => {
            data.forEach(ele => {
                if (element.ProductSku === ele.ProductSku) {

                    matchedSku = ele.ProductSku;
                }
            })
        })

        setSkuError(false);
        setIsCheckedOpen(false)

        if (skuData.length > 0) {


            if (skuData.length > 0) {
                skuData.map((item) => {
                    if (!(item.ProductSku == matchedSku) && !replace) {
                        setSku(item)
                    } else if (!(item.ProductSku == matchedSku) && replace) {
                        setReplaceSku(item)
                    }

                })
            }

        };
    }
    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef(null);
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: (monitor) => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: (item) => {
                moveRow(item.index, index);
            },
        });
        const [, drag] = useDrag({
            type,
            item: {
                index,
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        drop(drag(ref));
        return (
            <tr
                ref={ref}
                className={`${className}${isOver ? dropClassName : ''}`}
                style={{
                    cursor: 'move',
                    ...style,
                }}
                {...restProps}
            />
        );
    };
    const columns = [

        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            width: 50,
            render: (text, record, index) => {
                return (
                    <p>
                        {index + 1 + currentPage * 10}
                    </p>
                );
            },
        },
        {
            title: 'SKU',
            dataIndex: 'ProductSku',
            key: 'ProductSku',
            width: 50,
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.gen_category?.name ? record?.gen_category?.name : "All"}
                    </div>
                );
            },
        },

        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.category?.category_name ? record?.category?.category_name : "All"}
                    </div>
                );
            },
        },
        {
            title: 'Sub Category',
            dataIndex: 'sub_category',
            key: 'subCategory',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.sub_category?.sub_category_name ? record?.sub_category?.sub_category_name : "All"}
                    </div>
                );
            },
        },
        {
            title: 'IMAGE',
            dataIndex: 'mediaUrl',
            key: 'mediaUrl',
            width: 70,
            render: (text, record) => {
                return (
                    <div className="listing-image">
                        <img src={`${record?.mediaUrl}`} />
                    </div>
                );
            },
        },
        {
            title: 'Description',
            dataIndex: 'productDesc',
            key: 'productDesc',
            width: 250,
            render: (text, record) => {
                return (
                    <div className="desc-limit">
                        {record?.productDesc}
                    </div>
                );
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 50,
        },
        {
            title: 'Inventory',
            dataIndex: 'event',
            key: 'event',
            width: 70,
            // sortOrder: sort,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                let aa = a?.variants?.reduce((acc, item) => {
                    return (
                        acc = acc + item.inventory_quantity
                    )
                }, 0);
                let bb = b?.variants?.reduce((acc, item) => {
                    return (
                        acc = acc + item.inventory_quantity
                    )
                }, 0)
                return aa - bb;
            },
            // onHeaderCell: () => ({
            //     onClick: () => setSort(sort === 'ascend' || sort === '' ? 'descend' :
            //         'ascend'),
            //         sortDirections: ['descend'],
            // }),
            render: (text, record, index) => {
                let status = record?.variants?.reduce((acc, item) => {
                    return (
                        acc = acc + item.inventory_quantity
                    )
                }, 0)
                return (
                    <>
                        <div className='balance-area'>
                            <div className='balance-count'>
                                {status}
                            </div>
                            <a className='balance-btn default-btn outline small-btn' onClick={() => {
                                showModal(record);
                                setViewIndex(index)
                            }}
                            >
                                View
                                {/* <FontAwesomeIcon icon={faEye} /> */}
                            </a>

                        </div>
                    </>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'event',
            key: 'event',
            width: 60,
            render: (text, record, index) => {
                let status = record?.variants?.reduce((acc, item) => {
                    return (
                        acc = acc + item.inventory_quantity
                    )
                }, 0)
                if (status > 70) {
                    return (
                        <div className='status-info'><div className='status-flag green'></div>
                            {/* <p>green</p> */}
                        </div>
                    )
                } else if (status >= 50 && status <= 70) {
                    return (
                        <div className='status-info'><div className='status-flag yellow'></div>
                            {/* <p>yellow</p> */}
                        </div>
                    )
                } else if (status < 50) {
                    return (
                        <div className='status-info'><div className='status-flag red'></div>
                            {/* <p>red</p> */}
                        </div>
                    )
                }

                else {
                    return null
                }
            }
        },

    ];


    // const columnDetails = [

    //     {
    //         title: 'Size',
    //         key: 'option1',
    //         dataIndex: ['option1'],
    //         width: 40,
    //         render: (text, record, index) => {
    //             return (
    //                 <div className=''> {record?.option1 ? record?.option1 : '-'} </div>
    //             );
    //         },
    //     },

    //     {
    //         title: 'Wash',
    //         key: 'option1',
    //         dataIndex: ['option1'],
    //         width: 100,
    //         render: (text, record, index) => {

    //             return (
    //                 <>


    //                     <div className=''> {record?.option2 ? record?.option2 : '-'} </div>


    //                 </>
    //             );

    //         },
    //     },

    //     {
    //         title: 'Inseam',
    //         key: 'option1',
    //         dataIndex: ['option1'],
    //         width: 70,
    //         render: (text, record, index) => {

    //             return (
    //                 <>




    //                     <div className=''>{record?.option3 ? record?.option3 : '-'} </div>

    //                 </>
    //             );

    //         },
    //     },

    //     {
    //         title: 'Stock',
    //         dataIndex: 'inventory_quantity',
    //         key: 'inventory_quantity',
    //         width: 60,
    //     },

    // ];
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            setActionChange(true)
            const dragRow = data[dragIndex];
            setIsCheckedOpen(false)
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );

        },
        [data],

    );
    const trigger = () => {
        setSpin(false);
        setIsCheckedOpen(true)

        let formData = new FormData();

        let newData = data.map(skuItem => {
            return ({
                ...skuItem,
                influencer_percent: data?.[0]?.influencer_percent ? data?.[0]?.influencer_percent : "0",
                referral_percent: data?.[0]?.referral_percent ? data?.[0]?.referral_percent : "0"
            })
        })
        if (location?.state?.inventory === "shows") {
            formData.append(
                "banner",
                typeof banner === "object" ? banner : undefined
            );
            formData.append("show", fields.image);
            formData.append("id", newData[0]?.id);
            formData.append("products", JSON.stringify(newData));
            formData.append("title", eventTitle);
            formData.append("notes", note);
            formData.append("video_ratio", videoRatio);
            // formData.append("promo", promo);
            // formData.append("discount", (discount += "%"));
            formData.append("discount", productPromoCodeDscs);
            formData.append("promo", productPromoCodePromo);
            formData.append(
                "referral_percent",
                referralPercent > 0 ? referralPercent : 0
            );
            formData.append(
                "influencer_percent",
                influencer_percent > 0 ? influencer_percent : 0
            );
            formData.append("thankyou_message", thankyou);

            formData.append("default_banner", sampleBanner);
            formData.append("orientation", orientation);
        } else {

            formData.append('image', banner);
            formData.append('id', newData[0]?.id);
            formData.append('primary_host', JSON.stringify(host));
            formData.append('host1', JSON.stringify(host2));
            formData.append('host2', JSON.stringify(host3));
            formData.append('host3', JSON.stringify(host4));
            formData.append('products', JSON.stringify(newData));
            formData.append('title', eventTitle);
            formData.append('notes', note);
            formData.append('thankyou_message', thankyou);
            formData.append('start_date', dateTime.toISOString());
            formData.append('category_id', catgId);
            formData.append('default_banner', sampleBanner);
        }
        // formData.append('social_platform', platform);
        dispatch((location?.state?.inventory === "shows" ? UpdateShows(formData, id) : UpdateEvents(formData, id))).then((res) => {
            if (res?.payload?.success) {
                notification.success({
                    message: 'Product Updated Successfully',
                    className: 'toast-success',
                });
                setActionChange(false)
                // showModal();
            } else {
                setSpin(false);
                notification.error({
                    message: 'Event Failed',
                    className: 'toast-error',
                });
            }
        });

    }

    const getProd = () => {
        dispatch(getRefreshProd(location?.state?.inventory === "shows" ? { "event": id } : { "event": id })).then(async (res) => {
            if (res.payload.success === true) {
                notification.success({
                    message: res.payload.message,
                    className: 'toast-success',
                });
                dispatch((location?.state?.inventory === "shows" ? getShowBy_Id(id) : getEventsBy_Id(id))).then(async (event) => {
                    if (event?.success) {
                        setSpin(false);
                        let orderData = event.payload?.message[0]?.event?.orders;
                        let productsData = event.payload?.message[0]?.event?.products;
                        let newProducts = productsData.map((prod) => {
                            return {
                                ...prod,
                                orderData
                            }
                        })
                        setData(newProducts)
                        setSampleBanner(
                            event.payload?.message[0]?.event?.default_banner ? true : false
                        );

                        setPreviewImage(event.payload?.message[0]?.event?.banner);
                        setEventThankyou(event.payload.message[0]?.event?.thankyou_message);
                        setEventTitle(event.payload.message[0]?.event?.title);
                        setEventNote(event.payload.message[0]?.event?.notes);
                        setDate(moment(moment(event.payload.message[0]?.event?.start_date)));
                        setBanner(event.payload?.message[0]?.event?.banner);

                        let primary_data =
                            (await event.payload?.message[0]?.hosts[1]?.primary) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let primary_host = await primary_data.filter(
                            (num) => num.primary === true
                        );

                        let host2_data =
                            (await event.payload?.message[0]?.hosts[2]?.host1) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_2 = await host2_data.filter((num) => num.host1 === true);

                        let host3_data =
                            (await event.payload?.message[0]?.hosts[3]?.host2) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_3 = await host3_data.filter((num) => num.host2 === true);

                        let host4_data =
                            (await event.payload?.message[0]?.hosts[4]?.host3) === true
                                ? event.payload?.message[0]?.hosts
                                : [];
                        let host_4 = await host4_data.filter((num) => num.host3 === true);

                        setHost(primary_host);
                        setHost2(host_2);
                        setHost3(host_3);
                        setHost4(host_4);
                        setCategoryId(event.payload?.message[0]?.event?.category_id);

                        let parent = await parentCategory?.payload?.message.filter(
                            (item) =>
                                item.category_id == event.payload?.message[0]?.event?.category_id
                        );
                        setParent(parent[0]?.category_name);

                    }
                });
            }
        })
    }

    const formList = [
        <>
            <div className="row d-flex mb-20">
                {/* <div className="col-12 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Sku Management</h5>
          <p>
            Create product list to live event by using SKU numbers as added on
            the ecommerce platform.
          </p>
        </div> */}
                <div className="col-12 col-xl-12  flex-stretch form-styling">

                    <DndProvider backend={HTML5Backend}>
                        <Table
                            sorter
                            rowKey="ProductSku"
                            rowClassName={(record, index) => (inventoryStatus(record) || !record?.is_active) && 'grayed'}
                            pagination={false}
                            columns={columns}
                            dataSource={newData?.length > 0 ? newData : ""}
                            loading={spinner}
                            // components={components}
                            // onRow={(_, index) => {
                            //     const attr = {
                            //         index,
                            //         moveRow,
                            //     };
                            //     return attr;
                            // }}
                            scroll={
                                data.length > 6
                                    ? { y: 550, x: 1000 }
                                    : { x: 1000 }
                            }
                        // scroll={{
                        //     x: 1000,
                        //     y: 150,
                        //   }}
                        />
                    </DndProvider>

                </div>
            </div>

        </>
    ];
    const handleFilterSKU = (e) => {
        console.log("e", e);
        setSkuLoad(true)
        dispatch(searchActiveSkus("replaced", startDate, endDate, gender, category, subCategory, e)).then((res) => {
            console.log(res);
            setSkuLoad(false)
            if (res?.success) {

                setSearchSKUs(res?.message)
            }

        })
    }
    const dateRangePickerChanger = (value, dataString) => {
        const startDate = dataString[0];
        const endDate = dataString[1];
        setStartDate(startDate);
        setEndDate(endDate);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setSpin(true);
        dispatch(
            getInventory(
                pageLimit,
                1,
                "replaced",
                startDate,
                endDate,
                gender,
                category,
                subCategory,
                filterSKU,
                invSort
            )
        ).then((res) => {
            setSpin(false);
            setCurrentPage(1);
        });
    };
    const handlePageClick = (e) => {
        const page = e.selected;
        setSpin(true);
        setCurrentPage(page);
        dispatch(getInventory(pageLimit, page + 1, "replaced", startDate,
            endDate,
            gender,
            category,
            subCategory, filterSKU, invSort)).then(async (event) => {
                console.log("event", event);
                if (event?.success) {
                    setSpin(false);
                } else {
                    setSpin(false);
                }
            })
    };
    const handleGender = (e) => {
        setCurrentPage(0);
        setGender(e);
        setCategory("All");
        setSubCategory("All");
        setCatLoad(true);
        dispatch(getSavedCategories(e)).then((res) => {
            if (res.success) {
                setCatLoad(false);
            } else {
                setCatLoad(false);
            }
        });
    };

    const handleSubCategory = (e) => {
        setCurrentPage(0);
        setSubCategory(e);
    };
    const handleCategory = (e) => {
        setCurrentPage(0);
        setCategory(e);
        setSubCategory("All");
        setSubCatLoad(true);
        dispatch(getSavedSubCategories(gender, e)).then((res) => {
            if (res.success) {
                setSubCatLoad(false);
            } else {
                setSubCatLoad(false);
            }
        });
    };

    const handleReset = (res) => {
        setGender("All");
        setCategory("All");
        setSubCategory("All");
        setFilterSKU();
        setInvSort("asc")
        setSpin(true)
        setCurrentPage(0);
        const min_date = moment().startOf("year").format("YYYY-MM-DD");
        const max_date = moment(new Date()).format("YYYY-MM-DD");
        setStartDate(min_date);
        setEndDate(max_date);
        dispatch(
            getInventory(
                pageLimit,
                1,
                "replaced",
                min_date,
                max_date,
                "",
                "",
                "",
                "",
                "asc"
            )
        ).then((res) => {
            setSpin(false);
        });
    };
    return (

        <>
            {/* <div className='row justify-content-center'>

                <div className='col-lg-5 col-xl-4'>
                    <div className="event-preview-box-main mb-20">


                    </div>
                </div>

            </div> */}
            {/* <div className="top-area-box">
          <Link to="/events?type=Upcoming" className="prev-next-link ff-nunito">
            <FontAwesomeIcon icon={faAngleLeft} />
            <span> Go back</span>
          </Link>
        </div> */}
            <div className="top-area-box ">
                <div className="title-area mb-3 pb-0">
                    <h1 className="mb-0 pb-0">Replaced</h1>
                </div>
            </div>
            <div className="brand_container_main aff-payment">
                <Form >
                    <div className="row w-20-desktop-main">
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Select Start Date / End Date</p>
                            <RangePicker
                                size="large"
                                className="w-100"
                                key={4}
                                value={
                                    startDate && endDate
                                        ? [moment(startDate), moment(endDate)]
                                        : []
                                }
                                allowClear={false}
                                ranges={{
                                    "Last Year": [
                                        moment().subtract(1, "year").startOf("year"),
                                        moment().subtract(1, "year").endOf("year"),
                                    ],
                                    "This Year": [moment().startOf("year"), moment()],
                                    "Last Month": [
                                        moment().subtract(1, "month").startOf("month"),
                                        moment().subtract(1, "month").endOf("month"),
                                    ],
                                    "This Month": [
                                        moment().startOf("month"),
                                        moment().endOf("month"),
                                    ],
                                    Today: [moment(), moment()],
                                }}
                                format={dateFormat}
                                onChange={dateRangePickerChanger}
                            />
                        </div>

                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Department</p>
                            <Select
                                size="large"
                                className="w-100"
                                key={4}
                                placeholder="Select Department"
                                focus={false}
                                onChange={handleGender}
                                value={gender}
                                onBlur={false}
                                loading={generalLoad}
                                disabled={!savedGeneralCategories || generalLoad}
                            >
                                <Option value={"All"}>
                                    All
                                </Option>
                                {savedGeneralCategories &&
                                    savedGeneralCategories?.message?.map((item, i) => {
                                        return (
                                            <Option value={item?.category_id}>
                                                {item?.name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Category</p>
                            <Select
                                size="large"
                                className="w-100"
                                key={4}
                                placeholder="Select Category"
                                focus={false}
                                onChange={handleCategory}
                                value={category}
                                onBlur={false}
                                loading={catLoad}
                                disabled={!savedCategory.success || catLoad || !gender || gender === "All"}
                            >
                                <Option value={"All"}>
                                    All
                                </Option>
                                {savedCategory &&
                                    savedCategory?.message?.map((item, i) => {
                                        return (
                                            <Option value={item?.category_id}>
                                                {item?.category_name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Sub Category</p>
                            <Select
                                size="large"
                                className="w-100"
                                key={4}
                                placeholder="Select Sub Category"
                                focus={false}
                                onChange={handleSubCategory}
                                value={subCategory}
                                onBlur={false}
                                loading={subCatLoad}
                                disabled={
                                    !savedSubCategory?.success ||
                                    subCatLoad ||
                                    !gender || gender === "All" ||
                                    !category || category === "All"
                                }
                            >
                                <Option value={"All"}>
                                    All
                                </Option>
                                {savedSubCategory &&
                                    savedSubCategory?.message?.map((item, i) => {
                                        return (
                                            <Option value={item?.sub_category_id}>
                                                {item?.sub_category_name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">SKU</p>
                            <Select
                                showSearch
                                size="large"
                                className="w-100"
                                key={4}
                                notFoundContent={skuLoad && <Spin size="small" />}
                                optionFilterProp="children"
                                loading={skuLoad}
                                placeholder="Enter SKU"
                                onChange={(e) => setFilterSKU(e)}
                                onSearch={handleFilterSKU}
                                filterOption={(input, options) =>
                                    options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                focus={false}
                                // onChange={handleFilterSKU}
                                value={filterSKU}
                                onBlur={false}
                            >
                                {searchSKUs && !skuLoad &&
                                    searchSKUs?.map((item, i) => {
                                        return (
                                            <Option value={item?.sku}>
                                                {item?.sku}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 mb-15">
                            <p className="mb-5rem">Inventory Sort</p>
                            <Select
                                className="w-100 select-style dark-field"
                                placeholder="Select Group By"
                                size="large"
                                focus={false}
                                loading={loading}
                                disabled={loading}
                                onBlur={false}
                                value={invSort}
                                onChange={(e) => setInvSort(e)}
                                defaultValue="asc"
                            >
                                <Option value="asc">Ascending</Option>
                                <Option value="desc">Descending</Option>

                            </Select>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                            <Button
                                onClick={handleSubmit}
                                className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                                type="primary"
                                htmlType="submit"
                            >
                                Search
                            </Button>
                            <Button
                                onClick={handleReset}
                                className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                                type="primary"
                                htmlType="submit"
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                </Form>
                <hr className="separator-line mt-0" />

            </div>


            {spinner ? <LoadingSpin /> :
                <>
                    <div className="inventory-box-main">

                        <Form >{formList}</Form>

                    </div>
                    {replacedInventory?.message?.[0]?.total > 10 && (
                        <ReactPaginate
                            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                            // marginPagesDisplayed={2}
                            pageCount={
                                replacedInventory?.message?.[0]?.total > 0 ? Math.ceil(replacedInventory?.message?.[0]?.total / 10) : 0
                            }
                            forcePage={currentPage}
                            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                            pageClassName="page-item d-none"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item d-none"
                            breakLinkClassName="page-link"
                            containerClassName={"pagination custom-paginate"}
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    )}
                    <Modal
                        maskClosable={false}
                        className="modal-generic modal-600"
                        centered
                        visible={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        closeIcon={<FontAwesomeIcon icon={faXmark} />}
                        footer={false}
                    // footer={[
                    //   // <Button key="back" onClick={handleCancel}>
                    //   //   Cancel
                    //   // </Button>,
                    //   <Button key="submit" type="primary"
                    //     className="d-table m-auto"
                    //     // onClick={handleOk}
                    //     // onClick={handleCancel}
                    //   >
                    //     Submit
                    //   </Button>,
                    // ]}

                    >
                        <h2 className="modal-hd1">Details</h2>
                        <div className="co">
                            <Table
                                pagination={false}
                                columns={skuNewArr?.[viewIndex]}
                                size="small"
                                className="product-listing-tabl inventory-box-main details-table"
                                //  rowSelection={{ ...rowSelection }}
                                dataSource={dataDetails}
                                // scroll={
                                //     dataDetails.length > 32
                                //         ? { y: 450, x: 500 }
                                //         : false
                                // }
                                scroll={
                                    dataDetails.length > 10
                                        ? { y: 400, x: 500 }
                                        : { x: 500 }
                                }
                            />
                        </div>
                    </Modal>
                    <Modal
                        className="modal-generic modal-1000"
                        centered
                        open={replace}
                        onOk={handleReplace}
                        onCancel={handleCancel}
                        closeIcon={<FontAwesomeIcon icon={faXmark} />}
                        footer={false}
                        maskClosable={false}

                    >
                        <h2 className="modal-hd1">Add Sku</h2>
                        <Form >
                            <Form.Item>
                                <Form.Item
                                    name="productsku"
                                    label="Product SKU"
                                    className="d-flex flex-column sku-field mb-10 pos-index"
                                >
                                    <AsyncProduct
                                        getSku={getSku}
                                        replaceSku={replaceSku}
                                        skuData={data}
                                        replace={replace}
                                        skuArr={skuArr}
                                    />
                                    {skuError && (
                                        <div role="alert" className="ant-form-item-explain-error">
                                            Please Select Product SKU
                                        </div>
                                    )}
                                </Form.Item>
                            </Form.Item>
                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    sorter
                                    rowClassName={(record, index) => (inventoryStatus(record) || !record?.is_active) && 'grayed'}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={skuArr?.length > 0 ? skuArr : ""}
                                    loading={spinner}
                                    components={components}
                                    className={'scrollbar-style-parent mb-15'}
                                    onRow={(_, index) => {
                                        const attr = {
                                            index,
                                            moveRow,
                                        };
                                        return attr;
                                    }}
                                    scroll={
                                        data.length > 6
                                            ? { y: 550, x: 900 }
                                            : { x: 900 }
                                    }

                                />
                            </DndProvider>
                        </Form>
                        <div className="d-flex justify-content-end">
                            <Button
                                // loading={loading}
                                onClick={handleReplace}
                                // className="default-btn padb-3 d-inline-flex align-items-center justify-content-center color-secondary"
                                className={`default-btn padb-3 d-inline-flex align-items-center justify-content-center 
            `}
                            >
                                <span className="nav-text">Replace</span>
                            </Button>
                            <Button
                                className="default-btn padb-3 outline d-inline-flex align-items-center justify-content-center"
                                key="back"
                                onClick={handleCancel}
                            >
                                Exit
                            </Button>
                        </div>

                    </Modal>
                </>
            }


        </>
    );
}
