import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "antd";
// import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import "../../css/public/public.scss";
import "../../css/public/brand.scss";
import "../../css/public/about_us.scss";
import PublicHeader from "../../components/public/header/header";
import PublicFooter from "../../components/public/footer/footer";
import logo from "../../images/ormelogo.svg";
import about1 from "../../images/public/about-sec1.jpg";
import about2 from "../../images/public/about-sec2.jpg";
import aboutbanner from "../../images/public/about-banner.jpg";

export default function PublicAbout() {
  return (
    <>
      <PublicHeader />
      <div className="brand-sec-banner about-sec-banner">
        <div className="brn-bnr-box-left">
          <div className="bnr-content">
            <h1>
              <span>About Us</span>
            </h1>
            <p>
            'ORME is a centralized video streaming platform to bridge the gap between in-store and online purchasing. We’ve built a platform that can meet the needs of any brand, shopper, or influencer. Our technology enables brands to tell their stories through authentic content, allowing them to connect with customers in a more meaningful and deeper way. 
            </p>

            <Link
              to="/register">Join Now</Link>

          </div>
        </div>
        <div className="brn-bnr-box-right">
          <img className="about-img" src={aboutbanner} alt="" />
        </div>
      </div>
      <div className="bioshop-sec-main get-paid">
        <div className="mid-width">
          <div className="bioshop-container no-reverse w-initial">
            <div className="bioshop-box">
              <img className="img-fluid" src={about1} />
            </div>
            <div className="bioshop-box flex-end">
              <div className="bioshop-box-inner">
                <h2>Our Values</h2>
                <p className="mb-0">
                  We at ORME believe in fair business practices, socially
                  responsible individuals, and a general concept of “doing the
                  right thing.” It is our goal to hire like-minded professionals
                  and grow a positive, socially altruistic workplace.
                  Additionally, we operate with a customer-first mentality and
                  are always prepared to go the extra mile for the brands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bioshop-sec-main our-goal">
        <div className="mid-width">
          <div className="bioshop-container w-initial">
            <div className="bioshop-box">
              <div className="bioshop-box-inner">
                <h2>Our Goal</h2>
                <p className="mb-0">
                  We aim to create transformational growth through the power of
                  technology: in brands, influencers, and shoppers. And we are
                  in business to help all of them through one platform.
                </p>
              </div>
            </div>
            <div className="bioshop-box">
              <img className="img-fluid" src={about2} />
            </div>
          </div>
        </div>
      </div>
      <div className="bnr-section-2 get-started  d-inline-block">
        <div className="mid-width">
          <div className="one-mark">
            <h2>Sign Up with ORME Today! </h2>
            <Link
              to="/register">Get Started</Link>
          </div>
        </div>
      </div>
      <PublicFooter />
    </>
  );
}
