import React from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
// import { toast } from "react-toastify";
import "../../css/asyncProduct.scss";
import { BASEURL } from "../../config";
import { notification } from "antd";

let arrInfluencer = [];
class AsyncInfluencer extends React.Component {
  state = {
    influencer: this.props.influencer,
    allInfluencer: "",
    // default_value: this.props.defaultValue,
  };

  loadOptions = async (input, callback) => {
    await this.smartSearchFilter(input);
    const result = arrInfluencer.map((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
        name: `${item.name}`,
        instagram_username: `${item?.instagram_username}`,
        insta_handle: `${item?.insta_handle}`,
        email: `${item?.email}`,
      };
    });
    callback(result);
  };

  smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(`reviews/getinfluencers?limit=1000&page=1`, {
          user: value.trim(),
        })
        .then((response) => {
          const loadInfluencer = [];
          const Influencer = response.data.message.data;
          // if (Influencer.length === 0) {
          //   // notification.error({
          //   //   message: "No Host Found",
          //   //   className: "toast-error",
          //   // });
          // }

          this.setState({ allInfluencer: Influencer });
          Influencer.map((item) => {
            return loadInfluencer.push({
              value: item?.user_id,
              label: item?.email,
              name: item?.name,
              instagram_username: item?.instagram_username,
              insta_handle: item?.insta_handle,
              email: item?.email,
            });
          });

          arrInfluencer = loadInfluencer;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handleMultiSelect = (e) => {
    if (e) {
      this.props.getInfluencer(
        e.value,
        e.instagram_username ? e.instagram_username : e.insta_handle
      );
      this.setState({
        influencer: e,
      });
    } else {
      this.props.getInfluencer("");
      this.setState({
        influencer: "",
      });
    }
  };

  handleOnPaste = async (e) => {
    const getData = e.clipboardData.getData("text");
    await this.smartSearchFilter(getData);
  };
  formatOptionLabel = ({ label, name, instagram_username, insta_handle, email }) => (
    <div style={{ display: "flex" }}>
      <div>
 
        {name+"("+email+")"}
      </div>
      {/* <div style={{ marginLeft: "10px" }}>( {instagram_username ? instagram_username : name} )</div> */}
    </div>
  );

  clear = () => {
    this.setState({ influencer: "" });
  };

  render() {
    return (
      // <React.Fragment>
      <>
        {this.props.uploadReview && (
          <span class="input-group-text at-styleupload">&#9993;</span>
        )}
        <div className="flex-grow-1" onPaste={(e) => this.handleOnPaste(e)}>
          <AsyncSelect
            className="select-influencer"
            // isMulti={this.props.isMulti === false ? false : true}
            // isMulti={false}
            // cacheOptions
            isClearable
            defaultOptions
            // delimiter=","
            loadOptions={this.loadOptions}
            placeholder={"Select Creator"}
            name={"influencer"}
            value={this.state.influencer}
            onChange={(e) => {
              this.handleMultiSelect(e);
            }}
            noOptionsMessage={({ inputValue }) =>
              !inputValue ? "Search Creator" : "No Creator Found"
            }
            formatOptionLabel={this.formatOptionLabel}
            // value={this.props.defaultValue}
          />
        </div>
      </>
      // </React.Fragment>
    );
  }
}

export default AsyncInfluencer;
