import React from "react";
import { Link, NavLink } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin } from "antd";
import "../../css/public/public.scss";
import "../../css/public/brand.scss";
import "../../css/public/about_us.scss";
import "../../css/public/contact_us.scss";
import PublicHeader from "../../components/public/header/header";
import PublicFooter from "../../components/public/footer/footer";
import { useSelector, useDispatch } from "react-redux";
import { postContact } from "../../../src/redux/actions/contact.action";
import Swal from "sweetalert2";
const { TextArea } = Input;

export default function PublicContactUs() {
  const { validate } = useSelector((state) => {
    return state;
  });

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { contact } = useSelector((state) => state);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const onFinish = async (values) => {
    dispatch(postContact(userInfo?.token, values.firstName, values.email, values.phone, values.subject, values.message)).then(() => {
      form.resetFields(["firstName"]);
      form.resetFields(["email"]);
      form.resetFields(["subject"]);
      form.resetFields(["phone"]);
      form.resetFields(["message"]);
      Swal.fire({
        icon: "success",
        title: "Message Sent Successfully!",
      });
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <>
      {!validate?.payload?.success && <PublicHeader />}

      <div className={`general-section ${validate?.payload?.success && "p-0"} min-vh-100 d-flex justify-content-center align-items-center`}>
        <div className="mid-width max-1140">

          <div class="contact_content">
            <div class="row">
              <div class="col-lg-2">
                <h3 class="side-hd">HELP TOPICS</h3>
                <ul class="side-list">
                  <li>
                    <a class="" href="https://www.ormelive.com/terms-service">Terms of Service</a>
                  </li>
                  <li>
                    <a class="https://www.ormelive.com/privacy-policy" href="">Privacy Policy</a>
                  </li>
                  <li><a href="https://www.ormelive.com/community-guidelines">Community Guidelines</a></li>
                  <li>
                    <a aria-current="page" class="active" href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-10">
                <div class="privacy_header">


                  <div className="contact_content">
                    <h4>Contact Us</h4>
                    <p>Have any questions? Fill out the form and wait for us to get back to you. That’s all!</p>
                    <Form layout={"vertical"} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                      <div className="form-group contact-us-form-kb">
                        <div className="row">
                          <div className="col-6 cus-input-height">
                            <Form.Item
                              name="firstName"
                              label="Name"
                              rules={[
                                {
                                  required: true,
                                  message: `Please enter your name`,
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                          </div>

                          <div className="col-6 cus-input-height">
                            <Form.Item
                              name="email"
                              label="Email"
                              rules={[
                                {
                                  required: true,
                                  message: `Please enter your email address`,
                                },
                                {
                                  type: "email",
                                  message: `Please enter valid email address`,
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                          </div>

                          <div className="col-6 cus-input-height">
                            <Form.Item
                              name="phone"
                              label="Phone"
                              rules={[
                                {
                                  required: true,
                                  message: `Please enter your phone number`,
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                          </div>

                          <div className="col-6 cus-input-height">
                            <Form.Item
                              name="subject"
                              label="Subject"
                              rules={[
                                {
                                  required: true,
                                  message: `Please enter subject`,
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                          </div>

                          <div className="col-12 cus-text-area">
                            <Form.Item name="message" label="Message">
                              <TextArea rows={6} size="large" />
                            </Form.Item>
                          </div>
                          <div className="col-12">
                            {contact.loading ? (
                              <Button type="primary" htmlType="submit">
                                <Spin indicator={antIcon} />
                              </Button>
                            ) : (
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>


                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      {!validate?.payload?.success && <PublicFooter />}
    </>
  );
}
