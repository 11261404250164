import React from 'react'
import { Button } from "antd";
import { useEffect, useState } from 'react';

export default function StartMeetingScreen({ setMeeting, startLoading }) {
  // const dispatch = useDispatch();

  // function startMeeting(){
  //   setMeeting("start");
  //   document.body.classList.add('stream-screen');
  // }

  return (
    <div className="start-meeting-main">
      <Button
        className="default-btn align-items-center justify-content-center mt-3"
        type="primary"
        size="large"
        loading={startLoading ? true : false}
        onClick={() => setMeeting("start")}>
        Start Event
      </Button>
    </div>
  )
}
