import React, { useMemo, useState } from "react";
import { notification, Select, Tabs } from "antd";
import { Link } from "react-router-dom";

import EventGrid from "../../components/eventsGrid/eventsGrid";
// import GridListing from "../../components/gridListingBar/gridListingBar"
import {
  faBars, faGrip, faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import ShowsGrid from "./showsGrid";

const { Option } = Select;
const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { TabPane } = Tabs;
export default function Events() {
 
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [eventTitle, setEventTitle] = useState(
    searchParams.get("type") === null ? "Recorded" : searchParams.get("type")
  );
  const [eventStatus, setEventStatus] = useState(123);
  const dispatch = useDispatch();
  const {  validate } = useSelector((state) => {
    return state;
  });

  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set("type", key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  const roleId = localStorage.getItem("Role");

  // useEffect(() => {
  //   dispatch(getSocialFollowers(validate?.payload?.message?._id)).then((res) => {
  //     if (res?.payload?.success) {
  //       localStorage.setItem("social", JSON.stringify(res?.payload?.message));
  //     } else {
  //       console.log(res?.payload?.statusText);
  //     }
  //   });
  // }, []);

  const [role, setRole] = useState(roleId);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleRole = (e) => {
    setRole(e.target.value);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const copyToClipboard = (value) => {
    let textField = document.createElement("textarea");
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    notification.success({
      message: "Copied to Clipboard!",
      className: "toast-success",
    });
  };

  // useEffect(() => {
  //   return () => {
  //     let url = new URL(window.location.href);
  //     console.log(url, 'url');
  //     url.searchParams.delete('type');
  //     setEventTitle('Upcoming');
  //   };
  // }, []);

  return (
    <>
      <div className="grid-listing-area flex-column  flex-sm-column align-items-sm-start flex-md-column align-items-md-start flex-lg-column align-items-lg-start flex-xl-row align-items-start  mb-30 position-relative">
        <div className="grid-listing-left flex-grow-1">
          {/* <h1 className="hd2">All Events</h1> */}

          {/* <Select
            className="select-style"
            defaultValue="Events"
            size="large"
            focus="false"
            onChange={handleChange}
            onBlur={false}
          >
            { <Option value="All">All</Option> }
            <Option value="Events">Events</Option>
            <Option value="Recorded Events">Recorded Events</Option>
          </Select> */}

          <Tabs
            // defaultActiveKey="Upcoming"
            defaultActiveKey={eventTitle}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            {/* <TabPane tab="Upcoming" key="Upcoming"></TabPane>
            <TabPane tab="Live" key="Live Events"></TabPane> */}
            <TabPane tab="Management" key="Recorded"></TabPane>
            {validate?.payload?.message?.account_type === "brand" && (
              <TabPane tab="Published" key="Published"></TabPane>
            )}
            {/* <TabPane tab="Expired" key="Expired">
            </TabPane> */}
          </Tabs>
        </div>

        <div className="grid-listing-right">
          <div className={`grid-icon ${gridActive ? "active" : ""}`}>
            <FontAwesomeIcon icon={faGrip} />
            <a
              className="block-link"
              href="#"
              onClick={() => {
                setGridActive(true);
              }}
            ></a>
          </div>
          <div className={`grid-icon ${!gridActive ? "active" : ""}`}>
            <FontAwesomeIcon icon={faBars} />
            <a
              className="block-link"
              href="#"
              onClick={() => {
                setGridActive(false);
              }}
            ></a>
          </div>
          {validate?.payload?.message?.account_type === "brand" &&
            <Link
              to="/schedule-show"
              className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="nav-text ">Upload Shows</span>
            </Link>
          }
          {/* <button
            onClick={() => {
              showModal()
            }}
            // to="/schedule-event"
            className="default-btn d-flex align-items-center justify-content-center m-0 create-event-btn"
          >
            <span className="nav-text ">test</span>
          </button> */}

          {/* <Button
            className="default-btn d-flex align-items-center justify-content-center m-0"
            type="primary"
            block
            size="large"
            icon={<PlusOutlined />}
          >
            Create Event
          </Button> */}
        </div>
      </div>

      <div className="title-area">
        <h1>{eventTitle === "Recorded" ? "Management" : eventTitle}</h1>
      </div>

      <ShowsGrid
        className={gridActive}
        title={eventTitle}
        roleKey={role}
        eventStatus={eventStatus}
      />
    </>
  );
}