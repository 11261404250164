import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link,useNavigate } from 'react-router-dom';
import { Select, Tabs, Radio, Button, Modal } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip, faBars, faPlus, faXmark, faUserTie, faUserPen, faUserGear, faVideo, faComments, faBox, faHeadset, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const { Option } = Select;
const handleChange = (value) => {
    console.log(`selected ${value}`);
};

const { TabPane } = Tabs;

export default function HomeRole() {
    const history = useNavigate();
    const roleId = localStorage.getItem("Role")
    const [role, setRole] = useState(roleId);
    const [isModalOpen, setIsModalOpen] = useState(true);
    useEffect(() => {
        if (!isModalOpen) {
            localStorage.setItem("Role", role);
           
            history('/events');
        }
    }, [isModalOpen])



    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleRole = (e) => {
        setRole(e.target.value)
        setIsModalOpen(false);
    }

    const handleOk = () => {
        setIsModalOpen(false);
      
    };



    return (
        <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center mb-30">
            <div className="grid-listing-left flex-grow-1">
                <Modal
                    className="modal-generic modal-700"
                    centered
                    visible={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    maskClosable={false}
                    closeIcon={<FontAwesomeIcon icon={faXmark} />}
                    footer={[
                        // <Button key="back" onClick={handleCancel}>
                        //   Cancel
                        // </Button>,
                        // <Button key="submit" type="primary"
                        //     className="d-table m-auto"
                        //     // onClick={handleOk}
                        //     onClick={handleOk}
                        // >
                        //     Submit
                        // </Button>,
                    ]}

                >
                    <h2 className="modal-hd1">Select Role</h2>
                    <div className='select-role-list'>
                        <Radio.Group value={role} size="large">
                            <Radio.Button
                                value="admin"
                                onClick={handleRole}
                            >
                                <FontAwesomeIcon
                                    className="select-role-icon"
                                    icon={faUserGear}
                                />
                                Admin
                                <FontAwesomeIcon
                                    className="select-check-icon"
                                    icon={faCircleCheck}
                                />
                            </Radio.Button>
                            <Radio.Button
                                value="producer"
                                onClick={handleRole}
                            >
                                <FontAwesomeIcon
                                    className="select-role-icon"
                                    icon={faVideo}
                                />
                                Producer
                                <FontAwesomeIcon
                                    className="select-check-icon"
                                    icon={faCircleCheck}
                                />
                            </Radio.Button>
                            <Radio.Button
                                onClick={handleRole}
                                value="brandHost">
                                <FontAwesomeIcon
                                    className="select-role-icon"
                                    icon={faHeadset}
                                />
                                Brand Host
                                
                                <FontAwesomeIcon
                                    className="select-check-icon"
                                    icon={faCircleCheck}
                                />
                            </Radio.Button>
                            <Radio.Button
                                 onClick={handleRole}
                                value="inventoryManager">
                                <FontAwesomeIcon
                                    className="select-role-icon"
                                    icon={faBox}
                                />
                                Inventory Manager
                                <FontAwesomeIcon
                                    className="select-check-icon"
                                    icon={faCircleCheck}
                                />
                            </Radio.Button>
                            <Radio.Button
                                onClick={handleRole}
                                value="chatModerator">
                                <FontAwesomeIcon
                                    className="select-role-icon"
                                    icon={faComments}
                                />
                                Chat Moderator
                                <FontAwesomeIcon
                                    className="select-check-icon"
                                    icon={faCircleCheck}
                                />
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                </Modal>
            </div>
        </div>
    );
}