import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
const { Panel } = Collapse;

export default function PublicFooter() {
  const { validate } = useSelector((state) => {
    return state;
  });
  return (
    <>
      {!validate?.payload?.success ? (


        <>
          <div className="brd_footer_main">
            <div className="midd-width-footer">
              <div className="footer-new-design">
                <div className="brd-footer-top">
                  <div className="box-apps brd-footer-box">
                    <h4>Download Our App</h4>
                    <ul>
                      <li>
                        <a href="https://apps.apple.com/us/app/orme/id6448742926" target="_">
                          <i className="fa fa-apple" aria-hidden="true"></i> Download for iOS
                        </a>
                      </li>
                      <li>
                        <a href="https://play.google.com/store/apps/details?id=com.orme" target="_">
                          <i className="fa fa-android" aria-hidden="true"></i> Download for Android
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="brd-footer-box">
                    <h4>About</h4>
                    <ul>
                      <li>
                        <a href="https://www.ormelive.com/terms-service"> Terms of Service</a>
                     </li>
                      <li><a href="https://www.ormelive.com/privacy-policy">Privacy Policy</a></li>
                      <li><a href="https://www.ormelive.com/community-guidelines">Community Guidelines</a></li>
                      <li>
                        <a href="https://www.ormelive.com/contact-us">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="brd-footer-box">
                    <h4>Popular Brands</h4>
                    <ul>
                      <li>
                        <a href="https://www.ormelive.com/brand-reviews/900000000">DL1961</a>
                      </li>

                      <li>
                        <a href="https://www.ormelive.com/brand-reviews/900000001">Warp + Weft</a>
                      </li>
                      <li>
                        <a href="https://www.ormelive.com/brand-reviews/900000006">HSN</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="brd_footer">
                  <div className="brd-copyright">© 2023 ORME LIVE Inc.</div>
                  <div className="brd-right">

                  </div>
                </div>
              </div>
            </div>


    


            {/* <div className='public-footer sec-padding-10'>
              <div className='mid-width'>
                <div className='footer-content w-100 d-flex flex-column flex-md-row align-items-center justify-content-between'>
                  <p class="copyright mb-0">&copy;  ORME Inc<span id="year"></span>. All rights reserved.</p>
                  <ul class="footer-inline-nav">
                    <li><Link to="/terms-use"> Terms of Use</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                  </ul>
                </div>
              </div>
            </div> */}


            </div>
            <div className="mobile_footer_ifti">
        <Collapse accordion expandIconPosition={"end"} defaultActiveKey={["1"]} expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}>
          <Panel header="About" key="1">
            <div className="brd-footer-box">
              <ul>
              <li>
                        <a href="https://www.ormelive.com/terms-service"> Terms of Service</a>
                     </li>
                      <li><a href="https://www.ormelive.com/privacy-policy">Privacy Policy</a></li>
                      <li><a href="https://www.ormelive.com/community-guidelines">Community Guidelines</a></li>
                      <li>
                        <a href="https://www.ormelive.com/contact-us">Contact Us</a>
                      </li>
              </ul>
            </div>
          </Panel>
          {/* <Panel header="Popular Brands" key="2">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <a href="https://www.ormelive.com/brand-reviews/900000000">DL1961</a>
                </li>
                <li>
                  <a href="https://www.ormelive.com/brand-reviews/900000001">Warp + Weft</a>
                </li>
                <li>
                  <a href="https://www.ormelive.com/brand-reviews/900000006">HSN</a>
                </li>
              </ul>
            </div>
          </Panel> */}
          <Panel header="Download Our App" key="4">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <a href="https://apps.apple.com/us/app/orme/id6448742926" target="_">
                    <i className="fa fa-apple" aria-hidden="true"></i> Download for iOS
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.orme" target="_">
                    <i className="fa fa-android" aria-hidden="true"></i> Download for Android
                  </a>
                </li>
              </ul>
            </div>
          </Panel>
        </Collapse>

        <div className="brd_footer">
          <div className="brd-right">
            <div className="brd-social">
            </div>
          </div>
          <div className="brd-copyright">© 2023 ORME LIVE Inc.</div>
        </div>
      </div>
          </>
      ) : (null)}
        </>
      );
};
