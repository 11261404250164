import axios from 'axios';
import {
    GET_INVENTORY_ACTIVE, INVENTORY_SELLOUT, INVENTORY_DISABLE, GET_INVENTORY_DISABLED, GET_INVENTORY_SOLDOUT, INVENTORY_REPLACE, INVENTORY_SEARCH_SKU, GET_INVENTORY_REPLACED
} from '../types/types';

export const getInventory = (limit, page, type, startData, endData, gender, category, subCategory, sku, order_by ) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/inventory`,
        params: {
            limit,
            page
        },
        data: {
            type,
            from_date: startData,
            to_date: endData,
            gen_category: gender !== "All" ? gender : "all",
            category: category !== "All" ? category : "all",
            sub_category: subCategory !== "All" ? subCategory : "all",
            sku: sku ? sku : "",
            order_by
        }
    })
        .then((response) => {
            console.log("type", type);
            switch (type) {
                case "active":
                    dispatch({
                        type: GET_INVENTORY_ACTIVE,
                        payload: response.data,
                    });
                    return response.data;
                case "disable":
                    dispatch({
                        type: GET_INVENTORY_DISABLED,
                        payload: response.data,
                    });
                    return response.data
                case "soldout":
                    dispatch({
                        type: GET_INVENTORY_SOLDOUT,
                        payload: response.data,
                    });
                    return response.data
                case "replaced":
                    dispatch({
                        type: GET_INVENTORY_REPLACED,
                        payload: response.data,
                    });
                    return response.data
                default:
                    return null;
            }

        })
        .catch((error) => {
            return error
        });
};

export const sellOutSku = (Id) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/marksoldout`,
        params: {
            Id
        },
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_SELLOUT,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const disableSku = (Id) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/markdisabled`,
        params: {
            Id
        },
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_DISABLE,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const replaceSkus = (Id, products) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/replaced`,
        params: {
            Id
        },
        data: {
            products
        }
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_REPLACE,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};

export const searchActiveSkus = (type, startData, endData, gender, category, subCategory, sku) => (dispatch) => {
    return axios({
        method: 'POST',
        url: `products/searchsku`,
        data: {
            type,
            from_date: startData,
            to_date: endData,
            gen_category: gender !== "All" ? gender : "all",
            category: category !== "All" ? category : "all",
            sub_category: subCategory !== "All" ? subCategory : "all",
            sku: sku ? sku : ""
        }
    })
        .then((response) => {
            dispatch({
                type: INVENTORY_SEARCH_SKU,
                payload: response.data,
            });
            return response.data
        })
        .catch((error) => {
            return error
        });
};
