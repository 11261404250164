import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import * as influencerReviewAction from "../../redux/actions/influencerReview";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight, faXmark
} from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import NoDataFound from "../../reUsable/NoDataFound";
import { Col, Row } from "react-bootstrap";
import { Spin, Modal } from "antd";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

function AllVideos({
  getInfluencerReview,
  getInfluencerVideo,
}) {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 8;

  useEffect(() => {
    getInfluencerReview("active", 1, limit).then(() => setLoading(false));
  }, []);

  function renderContent() {
    if (!loading) {
      return (
        <Row className="post-analytics-tab-boxes-ift">
          {getInfluencerVideo.data.length > 0 ? (
            getInfluencerVideo.data.map((item, i) => (
              <Col xs={12} xl={3} md={6}>
                <div
                  key={i}
                  className={`card any_bx analytic-box campaign-box pb-0`}
                >
                  <div className="camp-row row">
                    <div className="campaign-header col-12">
                      <h6>
                        {item.title.length > 20
                          ? item.title.slice(0, 20) + "..."
                          : item.title}
                      </h6>

                    </div>
                    <div
                      className="any-post-img-col col-12"
                      style={{ marginBottom: 15 }}
                    >
                      <div className="any-post-image media-post-new">
                        <div className="any-image-box">
                          <div className="any-image-box-iner review-video">
                            <Player
                              // ref={player => {
                              //   player = player;
                              // }}
                              playsInline
                              src={item?.media_url}
                            />
                            {/* <video
                              className="gallery-video media-image"
                              // controlsList="nodownload"
                              controls={true}
                              id={item.media_library_id}
                              alt={item.title}
                            >
                              <source
                                // src={item.media_url}
                                src={"https://media.w3.org/2010/05/sintel/trailer_hd.mp4"}
                                type="video/mp4"
                              ></source>
                            </video> */}

                            {/* </>
                            ) : (
                              <img
                                src={item.media_url}
                                className="img-fluid media-image"
                                alt={item.title}
                              />
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="col-md-12">
              <NoDataFound />
            </div>
          )}
        </Row>
      );
    } else {
      return (
        <div style={{ height: 300 }}>
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        </div>
      );
    }
  }

  const handlePageClick = (e) => {
    setLoading(true);
    const page = e.selected;
    setCurrentPage(page);
    getInfluencerReview("active", page + 1, limit).then(() => setLoading(false));
  };

  return (
    <div className="container-fluid">
      {/* <h4 className="page-title">{title}</h4> */}
      {renderContent()}
      {getInfluencerVideo?.data?.length > 0 && !loading && (
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          pageClassName="page-item "
          pageLinkClassName="page-link custom-paginate-link btn btn-primary"
          previousClassName="page-item"
          previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
          nextClassName="page-item"
          nextLinkClassName="page-link custom-paginate-next btn btn-primary"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
          pageCount={Math.ceil(getInfluencerVideo.total_count / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
          onPageChange={handlePageClick}
          containerClassName={
            "pagination justify-content-center mt-2 custom-paginate"
          }
          activeClassName={"active"}
        />
      )}

    </div>
  );
}
function mapStateProps({ getInfluencerVideo }) {
  return { getInfluencerVideo };
}
export default connect(mapStateProps, {
  ...influencerReviewAction,
})(AllVideos);
