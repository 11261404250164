import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  faAngleLeft,
  faBan,
  faBoxOpen,
  faCode,
  faHeart,
  faPenToSquare,
  faTag,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notification, Skeleton, Spin, Tabs } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../css/controlRoom.scss";
import { getShowBy_Id } from "../../redux/actions/event.action";
import ReactHlsPlayer from "react-hls-player";
import { getChat } from "../../redux/actions/chat";
import useMediaQuery from "../../reUsable/useMediaQuery";
import RecordedChat from "../recordEvent-room/recordedChat";
import ReactPlayer from "react-player";

const { TabPane } = Tabs;

function RecordedShow() {
  const mediaHeight = useMediaQuery("(max-width: 1280px)");

  const dispatch = useDispatch();
  const location = useLocation();
  const [spinner, setSpin] = useState(true);
  const [dateTime, setDate] = useState("");
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [recorded, setRecorded] = useState("");
  const [eventEnded, setEventEnded] = useState(false);
  const [chat, setChat] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [height, setHeight] = useState(440);

  const {
    getShowById,

    validate,
  } = useSelector((state) => {
    return state;
  });

  const params = useParams();
  const id = params.id;

  const chatRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(getShowBy_Id(id)).then((res) => {
        if (res.payload.success) {
          setSpin(false);
          setEventEnded(res.payload?.message);
          setRecorded(res.payload?.message[0]?.recording_url);
          setBanner(res.payload?.message[0]?.banner);
          setDate(res.payload?.message[0]?.start_date);
          setTitle(res.payload?.message[0]?.title);

          setTimeout(() => {
            const resizeObserver = new ResizeObserver((event) => {
              setHeight(event[0].contentBoxSize[0].blockSize);
            });

            if (chatRef && chatRef?.current) {
              resizeObserver.observe(chatRef.current);
            }
          }, 500);
        }
      });
    }
  }, [chatRef, id]);

  const onKeyChange = (key) => {
    if (key == "1" && id) {
      setChatLoading(true);
      dispatch(getChat(id)).then((res) => {
        if (res.payload.success) {
          setChatLoading(false);
          setChat(res.payload.message?.data);
        } else {
          setChatLoading(false);
          notification.error({
            message: res.payload.data.message,
            className: "toast-error",
          });
        }
      });
    }
  };

  const myPlayer = useMemo(() => {
    return (
      <ReactHlsPlayer
        src={recorded}
        autoPlay={true}
        controls={true}
        // height="370px"
        // hlsConfig={{
        //   autoStartLoad: true,
        //   startPosition: -1,
        //   debug: true,
        //   capLevelToPlayerSize: true
        // }}
      />
      // <ReactPlayer
      //   width={"100%"}
      //   // height={"250px"}
      //   url={recorded}
      //   controls
      //   // light={recorded}
      //   playsinline
      //   playing
      // />
    );
  }, [recorded]);

  if (!spinner) {
    return (
      <>
        {eventEnded.length === 0 ? (
          <div className="no-result-found event-ended">
            <div className="no-result-img">
              <img
                src={validate?.payload?.message?.profile_image_url}
                alt="searchEventImg"
              />
            </div>
            {/* <h3 className="no-event-hd"> Event has ended.</h3> */}
            <p className="event-para mb-20">Event has ended.</p>
            <Link to="/shows" className="default-btn d-inline-flex ff-nunito">
              <span> Go to Shows</span>
            </Link>
          </div>
        ) : (
          <>
            <div className="control-room-top-area mb-2">
              <div className="control-top-box">
                <Link
                  to={
                    validate?.payload?.message?.account_type === "brand"
                      ? `/shows?type=${location?.state?.pageName}`
                      : `/host-event?type=${location?.state?.pageName}`
                  }
                  className="prev-next-link ff-nunito"
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                  <span>Go back</span>
                </Link>
                {/* </a> */}
              </div>
            </div>

            <div className="event-preview-box-main mb-20">
              {!getShowById.loading ? (
                <div className="row">
                  <div className="col-md-2 col-lg-2 d-lg-flex align-items-center">
                    {/* <div className="event-banner" style={{ backgroundImage: `url(${getEventById?.payload?.message[0]?.banner})` }} > */}
                    <div className="event-banner w-md-100">
                      <div className="event-box w-100 h-100 overflow-hidden">
                        <div className="event-banner-img">
                          <img
                            src={getShowById?.payload?.message[0]?.banner}
                            alt="banner"
                          />
                        </div>
                        <div>
                          <div className="schedule-badge">Recorded</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-lg-2 d-lg-flex  preview-data-border border-left-0 align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Scheduled</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(getShowById?.payload?.message[0]?.created_at)
                          .utc()
                          .format("YYYY-MM-DD")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getShowById?.payload?.message[0]?.created_at
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Start Date</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(getShowById?.payload?.message[0].created_at)
                          .utc()
                          .format("YYYY-MM-DD")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getShowById?.payload?.message[0]?.created_at
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">End Date</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(getShowById?.payload?.message[0]?.created_at)
                          .utc()
                          .format("YYYY-MM-DD")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getShowById?.payload?.message[0]?.created_at
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Duration</h4>
                      <h5 className="preview-date">
                        {/* &nbsp; */}
                        {moment
                          .utc(
                            moment(
                              getShowById?.payload?.message[0]?.created_at,
                              "HH:mm:ss"
                            ).diff(
                              moment(
                                getShowById?.payload?.message[0]?.created_at,
                                "HH:mm:ss"
                              )
                            )
                          )
                          .format("HH:mm:ss")}
                      </h5>
                      <h6 className="preview-time">&nbsp;</h6>
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center pl-xl-0">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Event</h4>
                      <h5
                        className="preview-date"
                        title={getShowById?.payload?.message[0]?.title}
                      >
                        {getShowById?.payload?.message[0]?.title.length > 30
                          ? getShowById?.payload?.message[0]?.title.slice(
                              0,
                              30
                            ) + "..."
                          : getShowById?.payload?.message[0]?.title}
                      </h5>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="event-detail-skeleton">
                  <Skeleton
                    avatar={{
                      size: "large",
                      shape: "square",
                    }}
                    active
                    paragraph={{
                      rows: 2,
                    }}
                  />
                </div>
              )}
            </div>

            <div className="control-boxes-main">
              <div className="control-boxes-box" ref={chatRef}>
                <div className="recorded-main-grid">
                  <div className="recorded-left">
                    <div className="live-stats-main">
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-info "
                            icon={faUsers}
                          />
                        </div>
                        <div className="stats-value">-</div>
                        <div className="stats-hd">Viewers</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-danger "
                            icon={faHeart}
                          />
                        </div>
                        <div className="stats-value">-</div>
                        <div className="stats-hd">Likes</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon tag">
                          <FontAwesomeIcon className="" icon={faTag} />
                        </div>
                        <div className="stats-value">-</div>
                        <div className="stats-hd product-view-hd">
                          Product Views
                        </div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faBoxOpen}
                          />
                        </div>
                        <div className="stats-value">-</div>
                        <div className="stats-hd">Orders</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon usd-txt">USD</div>
                        <div className="stats-value">0</div>
                        <div className="stats-hd">Sales</div>
                      </div>
                    </div>
                    <div className="control-video-box-main recorded ">
                      {/* <div className="box-left">Data</div> */}
                      <div className="box-right">
                        <div className="video_preview " id="video_preview">
                          {getShowById?.payload?.message[0]?.recording_url.split(
                            "."
                          )[
                            getShowById?.payload?.message[0]?.recording_url.split(
                              "."
                            )?.length - 1
                          ] === "m3u8" ? (
                            myPlayer
                          ) : (
                            <video
                              id={`video`}
                              controlsList="nodownload"
                              preload="metadata"
                              webkit-playsInline
                              autoplay="true"
                              playsInline
                              controls
                              loop
                              width="100%"
                              height="100%"
                            >
                              <source
                                src={
                                  getShowById?.payload?.message[0]
                                    ?.recording_url
                                }
                                type="video/mp4"
                              ></source>
                            </video>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="recorded-right"></div>
                </div>
              </div>
              <div
                className={`control-boxes-box tabs-control-room-main 
                  ${mediaHeight ? "mediaheight" : ""}
                  `}
                style={{ height: height }}
              >
                <Tabs
                  className="tabs-control-room chat-tab-data"
                  defaultActiveKey="3"
                  onChange={onKeyChange}
                >
                  <TabPane tab="Chat" key="1">
                    <RecordedChat
                      chat={chat}
                      chatLoading={chatLoading}
                      height={height}
                    />
                  </TabPane>
                  <TabPane tab="Products" key="3">
                    <div
                      className="control-products-listing-main-wrapper scrollbar-style overflow-y-auto"
                      style={{ maxHeight: height - 90 }}
                    >
                      {getShowById?.payload?.message[0]?.products.length ? (
                        getShowById?.payload?.message[0]?.products.map(
                          (item, i) => {
                            return (
                              <>
                                <div className="control-products-listing-main ">
                                  <div className="control-products-listing">
                                    <div className="control-product-image">
                                      <img
                                        src={item.mediaUrl}
                                        alt={item.title}
                                        className=""
                                      />
                                    </div>

                                    <div className="control-product-content">
                                      <div className="product-left">
                                        <div
                                          className="control-product-title"
                                          title={item.title}
                                        >
                                          {item.title}
                                        </div>
                                        <div className="control-product-price">
                                          ${item.price}
                                        </div>
                                      </div>
                                      <div className="product-right">
                                        <div className="product-quantity"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )
                      ) : (
                        // "No Product"
                        <Skeleton
                          avatar={{
                            size: "large",
                            shape: "circle",
                          }}
                          active
                          paragraph={{
                            rows: 2,
                          }}
                        />
                      )}
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <span className="spinclass loader-center">
        <Spin size="large" />
      </span>
    );
  }
}
export default RecordedShow;
