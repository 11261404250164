import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  notification,
  Statistic,
  Select,
  Form,
} from 'antd';
import "../../css/ugc.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin, DatePicker, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getEvents,
  deleteEvent,
  deleteEventRecord,
  publishEvent,
} from '../../redux/actions/event.action';
import { ShareUrl } from '../../redux/actions/shareUrl.action';
import searchEventImg from '../../images/search-event.png';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from 'moment';

import {
  faFlask,
  faCalendarDays,
  faClock,
  faUser,
  faVideo,
  faXmark,
  faAngleLeft,
  faAngleRight,
  faPlay,
  faUserTie,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import defaultImage from '../../images/user.png';
import HashtagsList from "../../pages/ugc/monitorHastag/HashTagList"
import TopHashTag from "../../pages/ugc/monitorHastag/TopHashTag";

const { Option } = Select;

const { Countdown } = Statistic;


const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const { confirm } = Modal;
const isoStr = new Date().toISOString();

export default ({ className, title, eventStatus }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState('end');
  const [loading, setLoading] = useState(false);
  const fromDate = moment().startOf("month").format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = () => {
    console.log('finished!');
  };

  const dispatch = useDispatch();
  const history = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const { getEvent, shareUrls, validate } = useSelector((state) => {
    return state;
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 8;

  const controlRoom = (val) => {
    history({
      pathname: `/control-room/${val?.event?._id}`,
    });
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: 'Url Copied!',
      className: 'toast-success',
    });
    setIsModalOpen(false);
  };

  const shareKey = (id, title) => {
    let UrlKey = '';
    if (title === 'Live Events') {
      UrlKey = 'live-event';
    } else {
      UrlKey = 'upcoming-event';
    }
    let url = `https://www.ormelive.com/${UrlKey}/${id}/${validate?.payload?.message?.instagram_username ? validate?.payload?.message?.instagram_username : validate?.payload?.message?.pixel_id
      }`;
    let data = { url: url };
    dispatch(ShareUrl(data)).then((res) => {
      if (res.payload.success) {
        setIsModalOpen(true);
      } else {
        console.log('err');
      }
    });
  };
  const recordedEvents = (val) => {
    history({
      pathname: `/recorded-event/${val?.event?._id}`,
    });
  };

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const load = getEvent.loading;

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    dispatch(getEvents(limit, page + 1, title, sortBy));
  };

  const heights = [150, 30, 90, 70, 90, 100, 150, 30, 50, 80];

  const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    color: theme.palette.text.secondary,
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getEvents(limit, 1, title, sortBy));
  };

  const handleChange = (value) => {
    setSortBy(value);
  };

  return (
    <>
      {/* <div className="row">

<div className="col-xl-3 col-md-6 col-12">
          <Form.Item
            name="datetime"
            initialValue={'date'}
            labelCol={{ span: 24 }}
            label="Select Start Date / End Date"
          >
              <RangePicker
              size="large"
              className='w-100'
            key={4}
            defaultValue={
              startDate && endDate
                ? [moment(startDate), moment(endDate)]
                : []
            }
            allowClear={false}
            ranges={{
              Today: [moment(), moment()],
              Tomorrow: [
                moment().add(1, "days"),
                moment().add(1, "days"),
              ],
              Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days"),
              ],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            }}
            format={dateFormat}
            onChange={dateRangePickerChanger}
          />
          </Form.Item>



       
        </div> 
        <div className="col-xl-3 col-md-6 col-12">
            <Form.Item labelCol={{ span: 24 }} label="Sort By" name="sort">
              <Select
                className="select-style dark-field"
                placeholder="Sort By"
                size="large"
                focus={false}
                onBlur={false}
                onChange={handleChange}
                defaultValue="DATE"
              >
                <Option value="date">DATE</Option>
                <Option value="mostinfluential">MOST INFLUECTIAL</Option>
              </Select>
            </Form.Item>
          </div>
        <div className="col-xl-6 col-md-6 col-12 d-flex align-items-end">
        <Form.Item className="d-flex align-items-end">
        <Button onClick={handleSubmit}
        className='default-btn fltr-hpr  medium-btn'
        type="primary" htmlType="submit" loading={loading}>
          Search
        </Button>
      </Form.Item>
        <Form.Item className="d-flex align-items-end">
        <Button
         // disabled
         className="default-btn outline fltr-hpr  medium-btn  res-btn2"
         type="primary"
         size="large"
      
       >
       Reset
       </Button>
      </Form.Item>
          </div>
          </div> */}

      {/* <hr className='separator-line' /> */}



      <>
        <div
          className={`upcoming-events-wrapper mb-30`}
        >

          {title === "AddHashTag" && (
            <HashtagsList />
          )}
          {title === "Monitor" && (
            <TopHashTag />
          )}






        </div>
      </>


      <Modal
        className="modal-generic modal-500"
        centered
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
      // footer={[
      //   <Button key="back" onClick={handleCancel}>
      //     Cancel
      //   </Button>,
      //   <Button key="submit" type="primary"
      //     className=""
      //     // onClick={handleOk}
      //     onClick={{}}
      //     >
      //     Next
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Share</h2>
        <div class="your-copy-link">
          <div class="item-a">
            <a
              target="_blank"
              rel="noreferrer"
              href={shareUrls?.payload?.message}
            >
              {shareUrls?.payload?.message}
            </a>
          </div>
          <div class="item-b">
            {/* <div class="item-b copied"> */}
            <Button onClick={() => copyUrl(shareUrls?.payload?.message)}>
              Copy
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
