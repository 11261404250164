import { REFRESH_PRODUCT_REQUEST, REFRESH_PRODUCT_SUCCESS, REFRESH_PRODUCT_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getRefreshProd = (id) => (dispatch) => {
	dispatch({ type: REFRESH_PRODUCT_REQUEST });
	return axios({
		method: 'POST',
		url: `shopify/refreshproducts`,
		data: id,
	})
		.then((response) => {
			return dispatch({
				type: REFRESH_PRODUCT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: REFRESH_PRODUCT_ERROR,
				payload: error.response,
			});
		});
};