import React, { useEffect, useMemo, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import Login from "../pages/login/login";
import Menus from "./menus";
import { createBrowserHistory } from "history";
import ForgotPassword from "../pages/forgot-password/forgotPassword";
import ResetPassword from "../pages/reset-password/resetPassword";
import PublicHome from "../pages/public/public-home";
import PublicAbout from "../pages/public/public-about";
import BrandRetailers from "../pages/public/brand-retailers";
import InfluencersCreators from "../pages/public/influencers-creators";
import PublicPackage from "../pages/public/public-package";
import PublicPrivacyPolicy from "../pages/public/public-privacy-policy";
import PublicTermsUse from "../pages/public/public-terms-use";
import PublicContactUs from "../pages/public/public-contact-us";
import Register from "../pages/register/register";
import ScrollToTop from "../components/scrollTop/scrollTop";
import { validateUser } from "../redux/actions/login.action";
import { useDispatch, useSelector } from "react-redux";
import { BASEURL } from "../config";
import axios from "axios";
import SkeletonComponent from "../components/SkeletenLoader";
import { getSocialFollowers } from "../redux/actions/socialFollowers.action ";
import Payment from "../pages/public/payment";
import { getProducts } from "../redux/actions/shopify.action";

const history = createBrowserHistory();
const token = JSON.parse(localStorage.getItem("token"));

const Router = () => {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const { validate } = useSelector((state) => { return state })
  const [load, setLoad] = useState(true)
  const dispatch = useDispatch();
  useEffect(() => {

    if (window.location.href.includes("payment") && searchParams.get("status") === "success") {
      setLoad(false)
    } else if (token) {
      dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
        if (res.success) {
          setLoad(false)
        } else {
          setLoad(false)
        }
      })
    } else {
      setLoad(false)
    }
  }, [])

  const memoizedResult = useMemo(() => {
    // if (validate?.payload?.success && validate?.payload?.message?.package) {
    //   dispatch(getSocialFollowers(validate?.payload?.message?._id))
    // }

    if (validate?.payload?.message?.token) {
      dispatch(getProducts(validate?.payload?.message?.token))
    }
  }, [validate]);

  axios.defaults.baseURL = BASEURL;
  axios.defaults.headers.common['Authorization'] = `Bearer ${validate?.payload?.message?.token}`;
  axios.interceptors.request.use(request => {
    return request;
  }, error => {
    return Promise.reject(error);
  });


  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  return (
    <>
      <HistoryRouter history={history}>
        {load ? <SkeletonComponent /> : (!validate?.payload?.success ? (
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<BrandRetailers />} />
              <Route path="/about" element={<PublicAbout />} />
              {/* <Route path="/public-package" element={<PublicPackage />} /> */}
              <Route path="/payment" element={<Payment />} />
              <Route path="/privacy-policy" element={<PublicPrivacyPolicy />} />
              <Route path="/terms-use" element={<PublicTermsUse />} />
              <Route path="/contact-us" element={<PublicContactUs />} />
              <Route path="/brand-retailers" element={<BrandRetailers />} />
              <Route path="/influencers-creators" element={<InfluencersCreators />} />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/register" element={<Register />} />
              <Route
                path="/forgot-password"
                exact
                element={<ForgotPassword />}
              />
              <Route
                path="/password-reset/:id"
                exact
                element={<ResetPassword />}
              />
              <Route path="*" element={<Redirect to="/" />} />
            </Routes>
          </ScrollToTop>
        ) : (
          <Menus />
        ))
        }
      </HistoryRouter>
    </>
  );
};
export default Router;
