import { GET_REFERRAL_SUMMARY, REFERRAL_STATS } from "../types/types";
import axios from "axios";

export const referralSummary = (id, event) => (dispatch) => {
  return axios({
    method: "POST",
    url: `brand/reports/getreferralstatsdetail`,
    data: {
      id,
      event,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_REFERRAL_SUMMARY,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const referralStatsReport =
  (page, limit, startDate, endDate, gender, category, subCategory, source, order, purchasedBy, ref_user, group_by) => (dispatch) => {
    return axios({
      method: "POST",
      url: `brand/reports/getreferralstatssummary?page=${page}&limit=${limit}`,
      data: {
        from_date: startDate,
        to_date: endDate,
        gen_category: gender ? gender : "",
        category: category ? category : "",
        sub_category: subCategory ? subCategory : "",
        source: source ? source : "",
        order: order ? order : "",
        purchasedBy: purchasedBy ? purchasedBy : "",
        ref_user: ref_user ? ref_user: "",
        group_by: group_by ? group_by : ""
      },
    })
      .then((response) => {
        dispatch({
          type: REFERRAL_STATS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  };
