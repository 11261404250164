import React, { useEffect, useState } from "react";
import moment from "moment";
import ScrollToBottom from "react-scroll-to-bottom";
import "../../css/chat.scss";
import placeholder from "../../images/user.png";
import { useSelector } from "react-redux";

function RecordedChat({ chat, chatLoading }) {
  const [comments, setComments] = useState([]);
  const { validate } = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    if (chat) {
      setComments(chat);
    }
  }, [chat]);

  function dateToFromNowDaily(myDate) {
    var fromNow = moment(myDate).format("LLL");

    return moment(myDate).calendar(null, {
      lastWeek: "LLL",
      lastDay: "[Yesterday] h:mm A",
      sameDay: "[Today] h:mm A",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  }

  if (chatLoading) {
    return (
      <div className="not-found-center">
        <h3>...loading</h3>
      </div>
    );
  }

  return (
    <div className="live-chat-comp">
      <div id="frame">
        <div className="content">
          <ScrollToBottom className="messages pb-0 recoreded-scroll">
            <ul className="message-ul">
              {comments.length === 0 ? (
                <>
                  <div className="not-found-center">
                    <h5>No Chat Found</h5>
                  </div>
                </>
              ) : (
                comments.map((it) => {
                  return (
                    <li
                      className={
                        validate?.payload?.message?._id === it.user_id ? "replies" : "sent"
                      }
                    >
                      <img src={it.avatar ? it.avatar : placeholder} alt="" />
                      <div className="msg-c-box message-box-main">
                        {validate?.payload?.message?._id !== it.user_id ? (
                          <>
                            <span class="user-name">{it.author}</span>
                            <p className="msg-content">
                              <span class="user-message">{it.content}</span>
                            </p>
                          </>
                        ) : (
                          <p className="msg-content">
                            <span class="user-message">{it.content}</span>
                            {/* <span class="user-name">{it.author}</span> */}
                          </p>
                        )}
                        <div className="msg-date-main">
                          <small className="msg-date">
                            {/* {moment(it.datetime).format('LLL')} */}
                            {dateToFromNowDaily(it.datetime)}
                          </small>
                        </div>
                      </div>
                    </li>
                  );
                })
              )}
            </ul>
          </ScrollToBottom>
        </div>
      </div>
    </div>
  );
}

export default RecordedChat;
