import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../../images/ormelogo.svg';
import bars from "../../../images/bars.svg";
import {
  faAddressBook,
  faArrowRightFromBracket,
  faBars,
  faHandshakeSimple,
  faHome,
  faInfoCircle,
  faPeopleGroup,
  faPhone,
  faTags,
  faUserTie,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";

import { createBrowserHistory } from "history";
import { store } from '../../../redux/store';
import { AUTHENTICATE } from '../../../redux/types/types';
import { useSelector } from 'react-redux';


export const history = createBrowserHistory();
export default function PublicHeader() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const [page, setPage] = useState("brands");
  const token = JSON.parse(localStorage.getItem('token'));
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("Role");
    store.dispatch({ type: AUTHENTICATE, payload: {} })
  };

  const { validate } = useSelector((state) => { return state })
  console.log("page", page);


  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };


  return (
    <>
      <div className="public-header">
        <div className="middle-width1">
          <div className="public-header-content">
            <div className="header-left">


              <div className="mobile-menu-ifti">
                <nav className="menuBar">
                  <div className="menuCon">
                    <span className="barsBtn" onClick={() => showDrawer()}>
                      <img src={bars} alt="menu open icon"></img>
                    </span>
                    <Drawer placement="left" onClose={onClose} visible={visible} width={"100%"}>

                      <div className="sign-mobile-drawer">
                        <div className="sign-mb-btns">




                          <Link
                            to="/register"
                            className=""
                          >
                            Join Now
                          </Link>
                        </div>
                      </div>

                      <nav className="public-menu">
                        <ul>
                          <li>
                            <a href="https://www.ormelive.com/" end activeClassName="active">
                              <FontAwesomeIcon icon={faHome} className='menu-ico' /> Home</a>
                          </li>
                          <li>
                            <a href={"https://www.ormelive.com/review"}>
                              {/* <FontAwesomeIcon icon={faVideoCamera} className='menu-ico' /> */}
                              <i className="fa fa-video-camera menu-ico" aria-hidden="true"></i> Market Place</a>
                          </li>
                          <li>
                            <a href={"https://www.ormelive.com/brands"}>
                              {/* <FontAwesomeIcon icon={faTags} className='menu-ico' /> */}
                              <i className="fa fa-tags menu-ico" aria-hidden="true"></i> Brands</a>
                          </li>
                          <li>
                            <a href={"https://www.ormelive.com/influencers"}>
                              <FontAwesomeIcon icon={faPeopleGroup} className='menu-ico' /> UGR</a>
                          </li>
                          <li>
                            <a href={"javascript:void"}>
                              <FontAwesomeIcon icon={faHandshakeSimple} className='menu-ico' /> Partner with us</a>
                            <div className='top-submenu'>
                              <ul>
                                <li>
                                  <NavLink to="/">Brands &amp; Retailers</NavLink>
                                </li>
                                <li>
                                  <NavLink to="/influencers-creators">Influencers &amp; Creators</NavLink>
                                </li>
                              </ul>
                            </div>
                          </li>

                          {/* <li>
                      
                      <NavLink to="/about" activeClassName="active">
                      <FontAwesomeIcon icon={faInfoCircle} className='menu-ico' />
                      About Us</NavLink>
                    </li> */}
                          <li

                          >
                            <a href="https://www.ormelive.com/contact-us" activeClassName="active">
                              <FontAwesomeIcon icon={faAddressBook} className='menu-ico' /> Contact Us</a>
                          </li>
                          {/* <li
                      className={page === 'package' ? 'active' : ''}
                      onClick={() => setPage('package')}
                    >
                      <Link to="/package">Package</Link>
                    </li> */}
                        </ul>
                      </nav>


                    </Drawer>
                  </div>
                </nav>
              </div>

              <div className="public-logo">
                <a href="https://www.ormelive.com/">
                  <img src={logo} alt="logo" />
                </a>
              </div>


            </div>

            {(!validate?.payload?.message?.token && !token) ? (
              <div className="buttons-area">
                {/* <Link
                  to="/login"
                  className="btn default-btn medium-btn rounded fw-bold"
                >
                  Sign In
                </Link> */}

                {console.log('pathname', pathname)}


                {pathname == '/influencers-creators' ?

                  <a href="https://www.ormelive.com?join=partner" className="btn default-btn medium-btn rounded fw-bold mob-top-btn-set">Join Now</a>

                  : <Link to="/register" className="btn default-btn medium-btn rounded fw-bold mob-top-btn-set">Join Now</Link>}


                {/* <Link
                  to="/register"
                  className="btn default-btn medium-btn rounded fw-bold"
                >
                  Join Now
                </Link> */}
              </div>
            ) : (
              <>
                <Button
                  className="logout-btn align-items-center justify-content-center"
                  type="button"
                  size="medium"
                  onClick={() => logout()}
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  Logout
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='c-top-menu'>
        <div className='middle-width'>
          <div className='c-top-menu-inner'>
            {(!validate?.payload?.message?.token && !token) ? (
              <nav className="public-menu hide-mobile">
                <ul>
                  <li>
                    <a href="https://www.ormelive.com/" end activeClassName="active">
                      <FontAwesomeIcon icon={faHome} className='menu-ico' /> Home</a>
                  </li>
                  <li>
                    <a href={"https://www.ormelive.com/review"}>
                      {/* <FontAwesomeIcon icon={faVideoCamera} className='menu-ico' /> */}
                      <i className="fa fa-video-camera menu-ico" aria-hidden="true"></i> Market Place</a>
                  </li>
                  <li>
                    <a href={"https://www.ormelive.com/brands"}>
                      {/* <FontAwesomeIcon icon={faTags} className='menu-ico' /> */}
                      <i className="fa fa-tags menu-ico" aria-hidden="true"></i> Brands</a>
                  </li>
                  <li>
                    <a href={"https://www.ormelive.com/influencers"}>
                      <FontAwesomeIcon icon={faPeopleGroup} className='menu-ico' /> UGR</a>
                  </li>
                  <li>
                    <a href={"javascript:void"}>
                      <FontAwesomeIcon icon={faHandshakeSimple} className='menu-ico' /> Partner with us</a>
                    <div className='top-submenu'>
                      <ul>
                        <li>
                          <NavLink to="/">Brands &amp; Retailers</NavLink>
                        </li>
                        <li>
                          <NavLink to="/influencers-creators">Influencers &amp; Creators</NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>

                  {/* <li>
                      
                      <NavLink to="/about" activeClassName="active">
                      <FontAwesomeIcon icon={faInfoCircle} className='menu-ico' />
                      About Us</NavLink>
                    </li> */}
                  <li

                  >
                    <a href="https://www.ormelive.com/contact-us" activeClassName="active">
                      <FontAwesomeIcon icon={faAddressBook} className='menu-ico' /> Contact Us</a>
                  </li>
                  {/* <li
                      className={page === 'package' ? 'active' : ''}
                      onClick={() => setPage('package')}
                    >
                      <Link to="/package">Package</Link>
                    </li> */}
                </ul>
              </nav>
            ) : (null)}
          </div>
        </div>


      </div>
    </>
  );
}
