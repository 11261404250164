import React, { useRef, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
// import Swal from "sweetalert2";
import { connect, useSelector, useDispatch } from "react-redux";
// import Loader from "../../../components/Loader/Loader";
import * as reviewPostActions from "../../redux/actions/influencerReview";
import { toast } from "react-toastify";
// import Placeholder from "../../../images/placeholder.svg";
// import CreateDragDrop from "./CreateDragDrop";
import Dropzone from "react-dropzone-uploader";
import {
  DatePicker,
  Spin,
  notification,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
} from "antd";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faCloudArrowUp,
  faPlus,
  faUpload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import AsyncInfluencer from "./asyncInfluencer";
import { getCustomerRegister } from "../../redux/actions/login.action";
import { checkInstagramUser } from "../../redux/actions/instagram.action";
import numeral from "numeral";
import { UploadOutlined } from '@ant-design/icons';
import S3FileUpload from 'react-s3';
import { REACT_S3 } from "../../redux/types/types";
const { Option } = Select;

function UploadVideo({ createReview }) {
  const dispatch = useDispatch();
  const uploadRef = useRef(null);
  const [submit, setSubmit] = useState(false);


  const [fields, setFields] = useState({
    image: "",
    video_ratio: "9:16"
  });

  const [orientation, setOrientation] = useState("");
  const [influencer, setInfluencer] = useState("");
  const [influencerError, setInfluencerError] = useState(false);
  const [showAddInfModal, setShowAddInfModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [instaUsername, setInstaUsername] = useState("");
  const [error, setError] = useState(false);
  const [instaLoading, setInstaLoading] = useState(false);
  const [instaStatus, setInstaStatus] = useState(false);
  const [instaData, setinstaData] = useState("");
  const [instaUploadData, setInstaUploadData] = useState("");
  const [fileList, setFileList] = useState([]);
  const [contentType, setContentType] = useState("");
  const [ext, setExt] = useState({});
  const [drag, setDrag] = useState(false);
  // const [instaExistStatus, setInstaExistStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const childRef = useRef();
  const [form] = Form.useForm();

  function preview(url) {
    return URL.createObjectURL(url);
  }
  function bytesToSize(bytes) {
    var sizes = ["B", "KB", "MB", "GB", "TB", "PB"];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes <= 1024) {
        return bytes + " " + sizes[i];
      } else {
        bytes = parseFloat(bytes / 1024).toFixed(2);
      }
    }
    return bytes + " P";
  }

//  asdsa
  const config = {
    bucketName: process.env.REACT_APP_UPLOAD_VIDEO_BUCKET_NAME,
    region: process.env.REACT_APP_UPLOAD_VIDEO_REGION,
    accessKeyId: process.env.REACT_APP_SHOWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SHOWS_SECRET_ACCESS_KEY,
    // accessKeyId: process.env.REACT_APP_UPLOAD_VIDEO_ACCESS_KEY_ID,
    // secretAccessKey: process.env.REACT_APP_UPLOAD_VIDEO_SECRET_ACCESS_KEY,
  }
  console.log("config", config);

  const onSubmit = () => {
    if (!fields?.user_id) {
      setInfluencerError(true);
    } else {
      if (fileList.length == 0) {
        setSubmit(true)
      }
      else {
        setLoading(true);
        createReview(fields, orientation).then((res) => {
          if (res?.data?.success) {

            dispatch({
              type: REACT_S3,
              payload: [],
            });
            notification.success({
              message: "Video Uploaded Successfully",
              className: "toast-success",
            });

            const newFile = new File([fileList[0]], res?.data?.file_name + "." + ext, {
              type: contentType,
            });
            // hack to update the selected file hehe
            const dT = new DataTransfer();
            dT.items.add(newFile);
            // setFileList(dT.files);

            S3FileUpload
              .uploadFile(dT.files[0], config)
              .then((data) => {
                console.log("data", data);
                dispatch({
                  type: REACT_S3,
                  payload: data,
                });
              }).catch(err => console.error("err", err))
            setInstaUploadData("");
            setFileList([])
            setInstaUsername("");
            setInfluencer("");
            childRef.current?.clear();
            setSubmit(false);
            setLoading(false);
          }
          else {
            setLoading(false);
            console.log(res);
          }
        }).catch ((err => {
          console.log("err", err);
        }));
      }

      // }
    }
  };

  const getInfluencer = (influencerData, influencerUsername, insta_handle) => {
    setInfluencerError(false);
    setInfluencer(influencerData);
    setFields({
      ...fields,
      user_id: influencerData,
    });
    setInstaUploadData("");
    // if (influencerUsername) {
    //   dispatch(checkInstagramUser(influencerUsername)).then((res) => {
    //     if (res?.type === "CHECK_INSTAGRAM_USER_SUCCESS") {
    //       setInstaUploadData(res?.payload?.message);
    //       setFields({
    //         ...fields,
    //         followers: res?.payload?.message?.followers_count,
    //         username: res?.payload?.message?.username,
    //         user_id: res?.payload?.message?.user_id,
    //       });
    //     } else {
    //     }
    //   });
    // } else {
    //   setInstaUploadData("");
    //   setFields({
    //     ...fields,
    //     followers: "",
    //     username: "",
    //     user_id: "",
    //   });
    // }
  };

  const closeInfluencer = () => {
    setShowAddInfModal(false);
    setSaveLoading(false);
    setError(false);
    setInstaStatus(false);
    setInstaUploadData("");
    // setInstaExistStatus(false)
    setinstaData("");
    setInstaUsername("");
    form.resetFields();
    childRef.current?.clear();
  };

  const onFinish = (values) => {
    setSaveLoading(true);
    dispatch(getCustomerRegister(values, instaData?.username)).then((res) => {
      if (res?.payload?.success) {
        notification.success({
          message: "Influencer Added Successfully!",
          className: "toast-success",
        });
        setShowAddInfModal(false);
        setSaveLoading(false);
        setInstaStatus(false);
        setinstaData("");
        setInstaUsername("");
        form.resetFields();
        setInstaUploadData("");
        childRef.current?.clear();
      } else {
        setSaveLoading(false);
        notification.error({
          message: res?.payload?.data?.message,
          className: "toast-error",
        });
      }
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };

  function renderFormatError() {
    var format = /[@\s]/;
    if (format.test(instaUsername)) {
      return (
        <small class="help-block text-danger">
          you cannot write @ or space
        </small>
      );
    } else if (instaUsername.length === 0 && error) {
      return (
        <small class="help-block text-danger">
          Please Enter Instagram Username.
        </small>
      );
    }
    return null;
  }

  const searchUsername = () => {
    var format = /[@\s]/;
    if (instaUsername.length > 0) {
      setError(false);
      if (!format.test(instaUsername)) {
        setInstaLoading(true);
        // dispatch(checkInstagramUser(instaUsername)).then((res) => {
        //   if (res?.type === "CHECK_INSTAGRAM_USER_SUCCESS") {
        //     setInstaLoading(false);
        //     if (res?.payload?.message?.user_exist) {
        //       setInstaUsername("");
        //       setInstaStatus(false);
        //       // setInstaExistStatus(true)
        //       // setinstaData(res?.payload?.message)
        //       notification.error({
        //         message: "User Already Exist",
        //         className: "toast-error",
        //       });
        //     } else {
        //       setInstaStatus(true);
        //       setinstaData(res?.payload?.message);
        //       notification.success({
        //         message: "User Exist",
        //         className: "toast-success",
        //       });
        //     }
        //   } else {
        //     setInstaUsername("");
        //     setInstaLoading(false);
        //     notification.error({
        //       message: "User Not Found",
        //       className: "toast-error",
        //     });
        //   }
        // });
      }
    } else {
      setError(true);
    }
  };

  const videoProps = {
    accept: "video/mp4",
    id: "uploadVideoFile",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
     
      const isMP4 = file.type === 'video/mp4';
      if (!isMP4) {
        notification.error({
          message: `${file.name} is not a mp4 file`,
          className: "toast-error",
        });
      }
      else {
        const url = URL.createObjectURL(file);
        const $video = document.createElement("video");
        $video.src = url;
        $video.addEventListener("loadedmetadata", function () {
          
          if (this.videoWidth > this.videoHeight) {
            setOrientation("landscape")
          } else {
            setOrientation("portrait")
          }
        });

        setContentType(file.type)
        setExt(file.name.split(".")[file.name.split(".")?.length - 1])
        setFileList([file]);
        return false;
      }
    },
    fileList,

  };
  return (
    <React.Fragment>
      <div className="container-flui">
        {/* <div class="title-area"><h1>Upload Media</h1></div> */}
        <div className="brand_container_main container">
          <Row>
            <div className="profile_box_main col-md-8">
              <div className=" brand-section dash_block_profile dash_content_profile">
                <div className="upload_area">
                  <h4>Upload Review Video</h4>
                  <p className="text-muted text-center">
                    MP4 Files Are Allowed
                    <br />
                    The maximum file size allowed is 50MB
                  </p>
                </div>
                <div class="upload_area_3 form-group select-inf-plus-main">
                  <div className="select-inf-field d-flex flow-row">
                    <AsyncInfluencer
                      ref={childRef}
                      getInfluencer={getInfluencer}
                      influencer={influencer} 
                      uploadReview={true}
                    />
                  </div>
                  {/* <div>
                    <Button
                      className="default-btn w-initial d-flex align-items-center justify-content-center m-0"
                      type="primary"
                      size="large"
                      onClick={() => setShowAddInfModal(true)}
                      icon={
                        <FontAwesomeIcon
                          size="sm"
                          className="event-icon"
                          icon={faPlus}
                        />
                      }
                    ></Button>
                  </div> */}
                </div>
                {influencerError && (
                  <small className="creator-error">Please Select Creator</small>
                )}
                {instaUploadData && (
                  <div class="add-influencer-form w-90">
                    <Row className="insta-profile mb-15 ml-0 mr-0">
                      <Col className="influencer-modal-data-main">
                        <div className="influencer-pic">
                          <img
                            src={instaUploadData?.profile_picture_url}
                            alt="profile"
                          />
                        </div>
                        <div class="Influencer-listing-box">
                          <h4 class="count-title">Name</h4>
                          <h3 class="count"> {instaUploadData?.name}</h3>
                        </div>
                        <div class="Influencer-listing-box">
                          <h4 class="count-title">Username</h4>
                          <a
                            title={instaUploadData?.username}
                            className="inf-username"
                            target="_blank"
                            href={`https://www.instagram.com/${instaUploadData?.username}`}
                          >
                            {instaUploadData?.username}
                          </a>
                        </div>
                        <div class="Influencer-listing-box">
                          <h4 class="count-title">Followers</h4>
                          <h3 class="count">
                            {" "}
                            {numeral(instaUploadData?.followers_count).format(
                              "0a"
                            )}
                          </h3>
                        </div>
                        <div class="Influencer-listing-box">
                          <h4 class="count-title">Following</h4>
                          <h3 class="count">
                            {" "}
                            {numeral(instaUploadData?.follows_count).format(
                              "0a"
                            )}
                          </h3>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <div className="upload_area_2 m-auto mb-20 position-relative" onDragEnter={() => setDrag(true)} onDragLeave={() => setDrag(false)} onDrop={() => setDrag(false)}>
                  <FontAwesomeIcon icon={faCloudArrowUp} />
                  <h4>{drag ? "Drop Here" : "Drag & Drop Your Media Here"}</h4>
                  <h4>Or</h4>

                  <Upload {...videoProps} maxCount={1} className="text-center mb-10">
                    <Button
                      // icon={<UploadOutlined />} 
                      className="default-btn medium-btn txt-btn-pad padb-3">
                      <FontAwesomeIcon icon={faArrowUpFromBracket} />
                      Select File</Button>
                  </Upload>



                  {submit && fileList.length === 0 &&
                    <div role="alert" className="ant-form-item-explain-error select-file-error-txt">
                      Please Select Video File
                    </div>
                  }

                </div>



                {!submit && fileList.length !== 0 && <div className="d-flex justify-content-center">
                  <Button className="default-btn medium-btn padb-3" onClick={onSubmit} loading={loading}>{loading ? "Uploading" : "Upload"}</Button>
                </div>
                }
              </div>
            </div>
          </Row>
        </div>
      </div>

      <Modal
        className="modal-generic modal-500"
        centered
        visible={showAddInfModal}
        // onOk={handleOk}
        onCancel={closeInfluencer}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
        maskClosable={false}
        keyboard={false}
      >
        <h2 className="modal-hd1">Add Influencer</h2>
        {!instaStatus ? (
          <Row>
            <Col md={12}>
              <div className="mb-3">
                {/* <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label>Add Profile</label>
                    </div> */}
                <div className="d-flex flex-row hashtag-box">
                  <span className="input-group-text">@</span>
                  <input
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      width: "85%",
                    }}
                    onChange={(e) => setInstaUsername(e.target.value)}
                    type="text"
                    name="name"
                    placeholder="Enter Instagram Username"
                    className="form-control comment-field"
                    required=""
                    value={instaUsername}
                  />
                  <Button
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      width: "15%",
                    }}
                    variant="primary"
                    // type="submit"
                    className="btn-block default-btn ml-0 fw-normal   "
                    onClick={searchUsername}
                    loading={instaLoading}
                  >
                    Search
                  </Button>
                </div>
                {renderFormatError()}
              </div>
            </Col>
            <Col md={12}>
              <div className="d-flex justify-content-md-center">
                <Button
                  key="back"
                  className="default-btn outline d-flex align-items-center justify-content-center"
                  onClick={closeInfluencer}
                >
                  Exit
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          // instaExistStatus ?
          //   <div class="add-influencer-form">
          //     <Row className="mb-2 insta-profile no-gutters">
          //       < Col md={4}>
          //         <img
          //           src={instaData?.profile_picture_url}
          //           alt="profile"
          //           className="img-fluid"
          //         />
          //       </Col>
          //       <Col md={8} className="pr-3 pl-3">
          //         <div>Name: {instaData?.name}</div>
          //         <div>Username: <a
          //           title={instaData?.username}
          //           className="inf-username"
          //           target="_blank"
          //           href={`https://www.instagram.com/${instaData?.username}`}
          //         >
          //           {instaData?.username}
          //         </a></div>
          //         <div>Followers: <span className="count">{numeral(instaData?.followers_count).format("0a")}</span></div>
          //         <div>Following: <span className="count">{numeral(instaData?.follows_count).format("0a")}</span></div>
          //       </Col>
          //     </Row>
          //     <div className="d-flex justify-content-md-end">
          //       <Button
          //         className="default-btn d-flex align-items-center justify-content-center"
          //         type="primary"
          //         size="large"
          //         // htmlType="submit"
          //         onClick={AddExistingInfluencer}
          //         loading={saveLoading}
          //       >
          //         Add Influencer
          //       </Button>
          //       <Button
          //         key="back"
          //         className="default-btn outline d-flex align-items-center justify-content-center"
          //         onClick={closeInfluencer}
          //       >
          //         Exit
          //       </Button>

          //     </div>
          //   </div>
          //   :
          <Form
            name="addinfluencer"
            // layout="vertical"
            // form={formLink}
            // initialValues={{
            //   remember: true,
            // }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <div class="add-influencer-form">
              <Row className="insta-profile mb-20 ml-0 mr-0">
                {/* <Col md={4}>
                  <img
                    src={instaData?.profile_picture_url}
                    alt="profile"
                    className="img-fluid"
                  />
                </Col> */}
                <Col className="influencer-modal-data-main">
                  <div className="influencer-pic">
                    <img src={instaData?.profile_picture_url} alt="profile" />
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Name</h4>
                    <h3 class="count"> {instaData?.name}</h3>
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Username</h4>
                    <a
                      title={instaData?.username}
                      className="inf-username"
                      target="_blank"
                      href={`https://www.instagram.com/${instaData?.username}`}
                    >
                      {instaData?.username}
                    </a>
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Followers</h4>
                    <h3 class="count">
                      {" "}
                      {numeral(instaData?.followers_count).format("0a")}
                    </h3>
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Following</h4>
                    <h3 class="count">
                      {" "}
                      {numeral(instaData?.follows_count).format("0a")}
                    </h3>
                  </div>

                  {/* <div>Name: {instaData?.name}</div>
                  <div>Username: <a
                    title={instaData?.username}
                    className="inf-username"
                    target="_blank"
                    href={`https://www.instagram.com/${instaData?.username}`}
                  >
                    {instaData?.username}
                  </a></div>
                  <div>Followers: <span className="count">{numeral(instaData?.followers_count).format("0a")}</span></div>
                  <div>Following: <span className="count">{numeral(instaData?.follows_count).format("0a")}</span></div> */}
                </Col>
              </Row>

              <div className="form-styling">
                {/* <Form.Item
                name="nickName"
                labelCol={{ span: 24 }}
                // label="nickName"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Nick Name',
                  },
                ]}
              >
                <Input
                  placeholder="Nick Name"
                  size="large"
                />
              </Form.Item> */}
                <Form.Item
                  name="firstName"
                  labelCol={{ span: 24 }}
                  // label="nickName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter First Name",
                    },
                  ]}
                >
                  <Input placeholder="First Name" size="large" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  labelCol={{ span: 24 }}
                  // label="nickName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Last Name",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" size="large" />
                </Form.Item>
                <Form.Item
                  name="email"
                  labelCol={{ span: 24 }}
                  // label="nickName"
                  // rules={[
                  //   {
                  //     type: "email",
                  //     required: true,
                  //     message: 'Please Enter Email Address',
                  //   },
                  // ]}
                  rules={[
                    {
                      type: "email",
                      message: "Please Enter Valid Email Address",
                    },
                    {
                      required: true,
                      message: "Please Enter Email Address",
                    },
                  ]}
                >
                  <Input placeholder="Email Address" size="large" />
                </Form.Item>
                {/* <Form.Item
                  name="zipCode"
                  labelCol={{ span: 24 }}
                  rules={[
                    // {
                    //   pattern: /^(?:\d*)$/,
                    //   message: "Value should contain just number",
                    // },
                    {
                      pattern: /^[\d]{0,6}$/,
                      message: "ZipCode should be less than 6 character",
                    },
                  ]}
                  validateTrigger="onInput"
                >
                  <InputNumber
                    autoComplete="off"
                    placeholder={`Zip code`}
                    type="number"
                    size="large"
                    className="w-100"
                  />
                </Form.Item> */}
              </div>

              <div className="d-flex justify-content-md-end">
                <Button
                  className="default-btn d-flex align-items-center justify-content-center min-w-initial-res"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={saveLoading}
                >
                  Add Influencer
                </Button>
                <Button
                  key="back"
                  className="default-btn outline d-flex align-items-center justify-content-center min-w-initial-res"
                  onClick={onReset}
                >
                  Reset
                </Button>
                <Button
                  key="back"
                  className="default-btn outline d-flex align-items-center justify-content-center min-w-initial-res"
                  onClick={closeInfluencer}
                >
                  Exit
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>
    </React.Fragment>
  );
}
// function mapStateToProps({ hashtags }) {
//     return { hashtags };
// }
export default connect(null, reviewPostActions)(UploadVideo);
