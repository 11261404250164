import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Tabs } from 'antd';
// import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import '../../css/public/public.scss';
import '../../css/public/brand.scss';
import PublicHeader from '../../components/public/header/header';
import PublicFooter from '../../components/public/footer/footer';
import publicBannerHome from '../../images/public/public-banner-home.png';
import featureImage from '../../images/public/feature-img.jpg';

import PhoneSpliteLiveNew from '../../images/public/Phone-splite-live-new.png';
import publicBannerBrand from '../../images/public/public-banner-brand.png';
import publicBannerInf from '../../images/public/public-banner-inf.png';
import shop1 from '../../images/public/shopify.png';
import shop2 from '../../images/public/magento.png';
import shop3 from '../../images/public/sales-force.png';
import influencer1 from "../../images/public/michelle-addison.jpg";
import influencer2 from "../../images/public/natalie-suarez.jpg";
import influencer3 from "../../images/public/michele-percy.jpg";
import influencer4 from "../../images/public/lauren.jpg";
import commerce from "../../images/public/commerce.jpg";
import getPaid from "../../images/public/get-paid-2.png";
import CustomerVideo from '../../video/customer.mp4';

const { TabPane } = Tabs;

export default function InfluencersCreators() {
  return (
    <>
      <PublicHeader />
      <div class="brand-sec-banner inf-banner">
        <div class="brn-bnr-box-left">
          <div class="bnr-content">
            <div className="sub-hd white-hd">Become a creator and start earning with ORME</div>
            <h1>
              <span className='big-siz'>LEVEL UP YOUR <br />
                CONTENT</span>
            </h1>
            <p>
              Your followers want to know about the brands you love, while brands want to collaborate with creators like you to reach new audiences.
            </p>
            <div className='banner-sub-hd'>Join ORME today to build profitable partnerships and get paid for your content.</div>
            <a href="https://www.ormelive.com?join=partner">Join Now</a>
          </div>
        </div>
        <div class="brn-bnr-box-right">
          <img class="public-home-img" src={publicBannerInf} alt="" />
        </div>
      </div>

      <div class="bioshop-sec-main">
        <div class="mid-width">
          <div class="bioshop-container w-initial">
            <div class="bioshop-box full-width">
              <div class="bioshop-box-inner max-initial max-full">
                <h2 className='text-center mb-50'>The ORME Difference</h2>

                <div className="row">
                  <div className="col-lg-4 features-box text-center">
                    <h3>Authentic</h3>
                    <p className='feature-para w-300 m-auto d-table'>
                      With access to hundreds of brands in our marketplace, you're guaranteed to find ones you'll truly want to share
                    </p>
                  </div>
                  <div className="col-lg-4 features-box text-center">
                    <h3>Independent</h3>
                    <p className='feature-para w-300 m-auto d-table'>
                      You're in control to pick what brands you partner with and to create unique content that speaks to your audience
                    </p>
                  </div>

                  <div className="col-lg-4 features-box text-center">
                    <h3>Unlimited Earning Potential</h3>
                    <p className='feature-para w-300 m-auto d-table'>
                    Share with your followers and get paid on every sale. The more you share, the more you earn.
                    </p>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="bioshop-sec-main light-bg">
        <div className="mid-width">
          <div className="bioshop-container w-initial">
            <div className="bioshop-box">
              <img className="img-fluid" src={commerce} />
            </div>
            <div className="bioshop-box ml-5">
              <div className="bioshop-box-inner">
                <h2>Your creativity. Your voice. Your content.</h2>
                <p className="mb-0">
                  Earn your value through authentic and innovative collaborations with brands that genuinely excite you.
                </p>

                {/* <Link to="/register" className='roud-link mt-3'>Join Now</Link> */}
                <a href="https://www.ormelive.com?join=partner" className='roud-link mt-3'>Join Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* <div class="bioshop-sec-main">
        <div class="mid-width">
          <div class="bioshop-container w-initial">
            <div class="bioshop-box full-width">
              <div class="bioshop-box-inner max-initial max-full">
                <h2 className='text-center mb-20'>Meet our creators</h2>
                <p className='text-center mb-40'>

                Get inspired by the creators already getting paid for their brand partnerships through ORME.
                </p>

                <div className="row">
                  <div className="col-lg-3 text-center">
                    <a href="https://www.ormelive.com/900000095" target="_" className='creator-box'>
                      <div className='creator-img'>
                        <img className="img-fluid" src={influencer1} />
                      </div>
                      <h4>@michelleaddisonstyle</h4>
                    </a>

                  </div>
                  <div className="col-lg-3 text-center">
                    <a href="https://www.ormelive.com/900000004" target="_" className='creator-box'>
                      <div className='creator-img'>
                        <img className="img-fluid" src={influencer2} />
                      </div>
                      <h4>@natalieoffduty</h4>
                    </a>

                  </div>

                  <div className="col-lg-3 text-center">
                  <a href="https://www.ormelive.com/900000032" target="_" className='creator-box'>
                      <div className='creator-img'>
                        <img className="img-fluid" src={influencer3} />
                      </div>
                      <h4>@michelepercy</h4>
                    </a>

                  </div>

                  <div className="col-lg-3 text-center">
                  <a href="https://www.ormelive.com/900000029" target="_" className='creator-box'>
                      <div className='creator-img'>
                        <img className="img-fluid" src={influencer4} />
                      </div>
                      <h4>@laurennrowlandd</h4>
                    </a>

                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div> */}
      <div class="bioshop-sec-main space-30 color-bg dark white-bg">
        <div class="mid-width">
          <div class="bioshop-container no-reverse w-initial">
            <div class="bioshop-box  d-flex justify-content-center">
              <img class="public-why-home-img" src={getPaid} alt="" />
            </div>
            <div class="bioshop-box">
              <div class="bioshop-box-inner">
                <h2 className="mb-20">Get paid for creating the content you love</h2>
                <div className='bio-para'>
                  Download our app to create content wherever you are and start earning with your favorite brands today. Available on Google Play Store for Android and Apple App Store for iOS.
                </div>

                {/* <div class="bioshop-link">
                  <Link to="/register">Join Now</Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="bnr-section-2 bg-white d-inline-block">
        <div className='signup-border-content no-bg'>
          <h2 className="mb-4 text-center">
            Start your earning journey today

          </h2>
          <a
            href="https://www.ormelive.com?join=partner" className='roud-link ml-auto mr-auto mt-3 d-table'>Join Now</a>

        </div>

      </div>



      <PublicFooter />
    </>
  );
}
