import { ThemeProvider } from "styled-components";
import {
  MeetingProvider,
  lightTheme,
  UserActivityProvider,
  useRemoteVideoTileState,
  RemoteVideo,
  useLocalVideo,
  useMeetingStatus,
  useRosterState,
  LocalVideo,
  AudioVideoProvider,
} from "amazon-chime-sdk-component-library-react";
import {
  useMeetingManager,
  NotificationProvider,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { BackgroundBlurProvider } from "amazon-chime-sdk-component-library-react";
import Control from "./Control";
import DeviceSetup from "./deviceSetup/index";
import { NavigationProvider } from "./NotificationProvider";
import Notifications from "./Notification";
import { useEffect, useRef, useState } from "react";
import { AudioOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "antd";
import stream from "../../images/stream-icon.png";
import ReactRouterPrompt from "react-router-prompt";
import { ErrorBoundary } from "react-error-boundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const MyApp = ({
  meetingData,
  host,
  endMeeting,
  onGolive,
  live,
  checkEvent,
  endMeetingFlag,
}) => {
  const history = useNavigate();
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const [leavemet, setLeaveMet] = useState(false);
  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();
  const [ratio, setRatio] = useState({ w: 16, h: 9 });
  const { validate } = useSelector((state) => {
    return state;
  });

  const [currentTileId, setCurrentTileId] = useState("");
  const { isVideoEnabled } = useLocalVideo();

  const joinMeeting = async () => {
    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      meetingData[0]?.meeting_info?.Meeting,
      {
        Attendee: meetingData[0]?.meeting_info?.Attendees.filter(
          (it) => it.ExternalUserId ===  validate?.payload?.message?._id
        )[0],
      }
    );

    // Create a `MeetingSession` using `join()` function with the `MeetingSessionConfiguration`
    return meetingManager.join(meetingSessionConfiguration).then(() => {});

    // At this point you could let users setup their devices, or by default
    // the SDK will select the first device in the list for the kind indicated
    // by `deviceLabels` (the default value is DeviceLabels.AudioAndVideo)
    // ...

    // Start the `MeetingSession` to join the meeting
  };

  useEffect(() => {
    joinMeeting();
  }, [meetingData]);

  useEffect(() => {
    return () => {
      meetingManager.leave();
    };
  }, []);

  const leaveMeeting = () => {
    return meetingManager.leave();
  };

  useEffect(() => {
    if (meetingStatus == 3) {
      meetingManager.leave().then(() => {
        history({
          pathname: `/events`,
        });
      });
    }
  }, [meetingStatus]);

  function getName(tId) {
    const at_id = tileIdToAttendeeId[tId];
    const ex_id = meetingData[0]?.meeting_info?.Attendees?.filter(
      (it) => it.AttendeeId == at_id
    )[0];
    return meetingData[0]?.hosts?.filter(
      (it) => it.user_id == ex_id.ExternalUserId
    )[0]?.user_name;
  }

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  if (meetingStatus == 0) {
    return (
      <UserActivityProvider>
        <DeviceSetup
          joinMeeting={async (cb) => {
            await meetingManager.start();
            // cb()
          }}
          checkEvent={checkEvent}
          meetingData={meetingData}
          ratio={ratio}
          setRatio={setRatio}
          meetingChecker={
            meetingData ? null : "Waiting for producer to start event"
          }
          leaveMeeting={leaveMeeting}
          host={host}
          endMeeting={endMeeting}
        />
      </UserActivityProvider>
    );
  }

  return (
    <>
      <UserActivityProvider>
        <div className={`host-box-wrapper ${host && "no-gutters"}`}>
          <div
            className={`video-box localvideosingle ${
              ratio.w === 8 ? "ratio-4" : "ratio-16"
            } ${
              tiles?.length > 0 && isVideoEnabled ? "ratio-4-multiview" : ""
            } `}
          >
            {(meetingData[0]?.event_status == "live"
              ? meetingData[0]?.event_status
              : live) == "live" && (
              <div style={{ position: "absolute", zIndex: 10 }}>
                <div
                  style={{ backgroundColor: "red", color: "white", padding: 5 }}
                >
                  Live
                </div>
              </div>
            )}
            <div
              style={!host ? { height: "100%", boxSizing: "border-box" } : {}}
            >
              {!isVideoEnabled && tiles?.length < 1 ? (
                <div className="no-video-style" style={{}}>
                  <p style={{ color: "white" }}>{"No Video Found"}</p>
                </div>
              ) : (
                <>
                  {isVideoEnabled ? (
                    <div className="single-grid row no-gutters">
                      {/* <ContentShare
                                        style={{
                                          border: '1px solid white',
                                          // minHeight:300,
                                          backgroundColor: 'black',
                                          // gridArea: 'ft'
                                        }}
                                      /> */}
                      {isVideoEnabled && (
                        <LocalVideo
                          id="myVideo"
                          onClick={() => {
                            if (currentTileId == "me") {
                              setCurrentTileId("");
                            } else {
                              setCurrentTileId("me");
                            }
                          }}
                          style={
                            {
                              // border: '1px solid white',
                              // gridArea: currentTileId=="me"?'ft':""
                            }
                          }
                          nameplate="Me"
                          className={`col-${
                            tiles.length == 0 ? "12" : "6"
                          } videoMirror`}
                        />
                      )}
                      {tiles.map((tileId) => (
                        <>
                          <RemoteVideo
                            onClick={() => {
                              if (tileId == currentTileId) {
                                setCurrentTileId("");
                              } else {
                                setCurrentTileId(tileId);
                              }
                            }}
                            style={{
                              // border: '1px solid white',
                              // minHeight:300,
                              backgroundColor: "black",
                              // gridArea: tileId == currentTileId ? 'ft' : ''
                            }}
                            id={"myVideo1"}
                            name={getName(tileId)}
                            tileId={tileId}
                            className="col-6 videoMirror"
                          />
                        </>
                      ))}
                    </div>
                  ) : (
                    <div className="single-grid row no-gutters">
                      {tiles.map((tileId) => (
                        <>
                          <RemoteVideo
                            id={`remoteVideo${tileId}`}
                            onClick={() => {
                              if (tileId == currentTileId) {
                                setCurrentTileId("");
                              } else {
                                setCurrentTileId(tileId);
                              }
                            }}
                            style={{
                              // border: '1px solid white',
                              // minHeight:300,
                              backgroundColor: "black",
                              // gridArea: tileId == currentTileId ? 'ft' : ''
                            }}
                            className={`col-${
                              tiles.length == 1 ? "12" : "6"
                            } videoMirror`}
                            name={getName(tileId)}
                            tileId={tileId}
                          />
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <Control
          ratio={ratio}
          setRatio={setRatio}
          setLeaveMet={setLeaveMet}
          live={
            meetingData[0]?.event_status == "live"
              ? meetingData[0]?.event_status
              : live
          }
          onGolive={onGolive}
          host={host}
          leaveMeeting={leaveMeeting}
          endMeeting={endMeeting}
          meetingData={meetingData}
        />

        {!endMeetingFlag &&
          meetingStatus != 3 &&
          !leavemet &&
          (meetingData[0]?.event_status == "live"
            ? meetingData[0]?.event_status
            : live) == "live" && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReactRouterPrompt when={meetingData}>
                {({ isActive, onConfirm, onCancel }) =>
                  isActive && (
                    <Modal
                      maskClosable={false}
                      className="modal-generic modal-500"
                      centered
                      visible={isActive}
                      onOk={() => {
                        endMeeting();
                        onConfirm();
                      }}
                      onCancel={onCancel}
                      closeIcon={<FontAwesomeIcon icon={faXmark} />}
                      footer={[
                        <Button type="primary" onClick={onCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          // onClick={handleOk}
                          onClick={() => {
                            endMeeting ? endMeeting() : meetingManager.leave();
                            onConfirm();
                          }}
                        >
                          Ok
                        </Button>,
                      ]}
                    >
                      <h2 className="modal-hd1">Warning</h2>
                      <h5>
                        {host
                          ? "Are you sure you want to leave event?"
                          : "Are you sure you want to end event?"}
                      </h5>
                    </Modal>
                    // <div className="lightbox">
                    //   <div className="container">
                    //     <p>Do you really want to leave?</p>
                    //     <button onClick={onCancel}>Cancel</button>
                    //     <button onClick={onConfirm}>Ok</button>
                    //   </div>
                    // </div>
                  )
                }
              </ReactRouterPrompt>
            </ErrorBoundary>
          )}
        {(meetingData[0]?.event_status == "live"
          ? meetingData[0]?.event_status
          : live) != "live" &&
          meetingStatus != 3 && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReactRouterPrompt when={meetingData}>
                {({ isActive, onConfirm, onCancel }) =>
                  isActive && (
                    <Modal
                      className="modal-generic modal-500"
                      centered
                      visible={isActive}
                      onOk={() => {
                        endMeeting();
                        onConfirm();
                      }}
                      onCancel={onCancel}
                      closeIcon={<FontAwesomeIcon icon={faXmark} />}
                      footer={[
                        <Button type="primary" onClick={onCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          // onClick={handleOk}
                          onClick={() => {
                            endMeeting ? endMeeting() : meetingManager.leave();
                            // endMeeting()
                            onConfirm();
                          }}
                        >
                          Ok
                        </Button>,
                      ]}
                    >
                      <h2 className="modal-hd1">Warning</h2>
                      <h5>
                        {!host
                          ? "Do you want to exit the event"
                          : "Do you want to leave this event"}
                      </h5>
                    </Modal>
                    // <div className="lightbox">
                    //   <div className="container">
                    //     <p>Do you really want to leave?</p>
                    //     <button onClick={onCancel}>Cancel</button>
                    //     <button onClick={onConfirm}>Ok</button>
                    //   </div>
                    // </div>
                  )
                }
              </ReactRouterPrompt>
            </ErrorBoundary>
          )}
      </UserActivityProvider>

      {!host && (
        <div className="host-box">
          {attendees.map((itP, i) => {
            return (
              <div key={i} className="host-info">
                <div className="host-name">
                  {
                    meetingData[0]?.hosts?.find(
                      (it) => it?.user_id == itP?.externalUserId
                    )?.user_name
                  }
                </div>
                <div className="host-control">
                  <button className="host-button">
                    <AudioOutlined />
                  </button>
                  <button className="host-button">
                    <VideoCameraOutlined />
                  </button>
                  <button className="host-button go-live-btn">
                    <img src={stream} alt="stream" className="img-fluid" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const Root = ({
  meetingData,
  host,
  endMeeting,
  onGolive,
  live,
  checkEvent,
  endMeetingFlag,
}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <NotificationProvider>
        <Notifications />
        <BackgroundBlurProvider>
          <MeetingProvider>
            <NavigationProvider>
              <AudioVideoProvider>
                <MyApp
                  checkEvent={checkEvent}
                  meetingData={meetingData}
                  endMeetingFlag={endMeetingFlag}
                  onGolive={onGolive}
                  host={host}
                  endMeeting={endMeeting}
                  live={live}
                />
              </AudioVideoProvider>
            </NavigationProvider>
          </MeetingProvider>
        </BackgroundBlurProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default Root;
